
































import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import myFormInterface from "@/types/myFormInterface";
import PurchaseBuyer from "@/types/purchaseRequest/purchaseBuyer";
import Vue from "vue";
import { mapActions } from "vuex";
import FormPurchaseBuyer from "./ButtonCreatePurchaseBuyer.vue/FormPurchaseBuyer.vue";
export default (Vue as myFormInterface).extend({
  components: { FormPurchaseBuyer },
  props: {
    purchaseRequestProp: {
      type: Object,
    },
    purchaseBuyerProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
      ...mapActions({
      setPurchaseBuyer:'purchaseBuyer/getPurchaseBuyer'
    }),
    async save() {
      this.displayLoader(true);
      try {
        let dataPurchaseBuyer = this.$refs.formPurchaseBuyer.data;
        await PurchaseBuyerService.update(
          this.purchaseBuyerProp.id,
          dataPurchaseBuyer as PurchaseBuyer
        );
        this.displayToast({
          type: "success",
          message: "Registro modificado",
        });
        this.setPurchaseBuyer(this.purchaseRequestProp.id);
        this.dialog=false
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
