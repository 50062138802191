import PurchaseRequestDetail from '@/types/purchaseRequest/purchaseRequestDetail'
import { BaseService } from './../../base'
export class PurchaseRequestDetailService extends BaseService<PurchaseRequestDetail>() {
    static get entity (): string {
        return 'purchase_request_detail'
    }
    static get responseName (): string {
        return 'purchase_request_detail'
    }
    static async getByPurchaseId(id:number): Promise<PurchaseRequestDetail[]> {
        return this.submit<PurchaseRequestDetail[]>({
            method: 'get',
            path: `find_by_purchase_request/${id}`
        });
    }
}