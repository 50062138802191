

























import { CdpService } from "@/services/models/purchaseRequest/cdp";
import Cdp from "@/types/purchaseRequest/cdp";
import Vue from "vue";
import ButtonEditCdp from "./ButtonEditCdp.vue";
export default Vue.extend({
  components: { ButtonEditCdp },
  props: {
    purchaseBuyerProps: {
      type: Object,
    },
  },
  data() {
    return {
      getLoading: false,
      getItems: [] as Array<Cdp>,
      headers: [
        {
          text: "Id",
          sortable: false,
          value: "id",
        },
        {
          text: "Id Interno",
          sortable: false,
          value: "number_id",
        },
        {
            text: "Encabezado",
          sortable: false,
          value: "header.name",
        },
        {
          text: "N° Solicitud",
          sortable: false,
          value: "n_request",
        },
        {
          text: "Ver",
          sortable: false,
          value: "file",
        },
      ],
    };
  },
  methods: {
    async loadData() {
      this.getLoading = true;
      this.getItems=await CdpService.getPurchaseBuyerId(this.purchaseBuyerProps.id);
      this.getLoading = false;
    },
  },
  mounted() {
    this.loadData();
  },
});
