import User from '@/types/user/user'
import { BaseService } from '../../base'
import Axios, { AxiosResponse } from "axios";

export class UserService extends BaseService<User>() {
    static get entity(): string {
        return 'user'
    }

    static get responseName(): string {
        return 'user'
    }

    static async login(data: DataLogin): Promise<AxiosResponse<DataLoginReturn>> {
        return Axios.post(`${process.env.VUE_APP_API_URL}/api/auth`, data);
    }

    static async getByState(state: string): Promise<User[]> {
        return this.submit<User[]>({
            method: 'get',
            path: `find_by_state/${state}`
        });
    }
    static async getByRut(rut: string): Promise<User[]> {
        return this.submit<User[]>({
            method: 'get',
            path: `find_by_rut/${rut}`
        });
    }

    static async getByEmail(email: string): Promise<User[]> {
        return this.submit<User[]>({
            method: 'get',
            path: `find_by_email/${email}`
        });
    }
    static async getByStablishment(stablishment_id: number): Promise<User[]> {
        return this.submit<User[]>({
            method: 'get',
            path: `find_by_stablishment_id/${stablishment_id}`,
        });
    }
    static async getBystateAndStablishment(state: string, stablishment_id: number): Promise<User[]> {
        return this.submit<User[]>({
            method: 'get',
            path: `find_by_state_stablishment_id/${state}/${stablishment_id}`,
        });
    }
    static async getChangePasswd(id: number, data: any): Promise<void> {
        return this.submit({
            method: 'put',
            path: `change_passwd/${id}`,
            data: data
        });
    }
    static async resetPassword(id: number, data: any): Promise<void> {
        return this.submit({
            method: 'put',
            path: `new_passwd/${id}`,
            data: data
        });
    }
    static async updateAvatar(id: number, data: any): Promise<User> {
        return this.submit({
            method: 'post',
            path: `update_avatar/${id}`,
            data: data,
            config: { "Content-Type": "multipart/form-data", }
        });
    }
    static async getTokenKey(run: string): Promise<AxiosResponse<string>> {        
        return this.submit({
            method: 'get',
            path: `get_token_key_v2/${run}`,
            showFullResponse: true
        });
    }
}

export interface DataLogin {
    run: string;
    password: string;
    grant_type: string;
}
export interface DataLoginReturn {
    token: string;
    user: User;
}