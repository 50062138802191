import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import './thirdParty';
import mixins from "./mixins/index";

Vue.config.productionTip = false
Vue.mixin(mixins);

const appVersion = process.env.VUE_APP_GLOBAL_VERSION;
const browserVersion = window.localStorage.getItem("appVersion");

if (appVersion !== browserVersion) {
  window.localStorage.setItem("appVersion", appVersion as string);
  window.location.reload();
}

//Ingresar la locación actual del usuario
const user = JSON.parse(window.localStorage.getItem("user") || "{}");
if (user) {
  if (user.users_locations) {
    store.commit("userSession/setCurrentLocation", user.users_locations[0]);
  }
  store.commit("userSession/setUserModules");
  store.commit("userSession/setUserPermissions");
}

export const eventBus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
