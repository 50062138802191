


















































import Vue from "vue";
import { mapGetters } from "vuex";
import EditDocument from "../EditDocument.vue";
import ShowDocumentDetail from "../ShowDocument/ShowDocumentDetail.vue";
import ButtonChangeRead from "../ButtonChangeRead.vue";
export default Vue.extend({
  components: {
    ShowDocumentDetail,
    EditDocument,
    ButtonChangeRead,
  },
  data() {
    return {
      page: 1,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false,
        },
        { text: "Número", sortable: false, align: "start", value: "number" },
        {
          text: "Descripcion",
          sortable: false,
          align: "start",
          value: "description",
        },
        {
          text: "Tipo de Documento",

          sortable: false,
          align: "start",
          value: "document_type.name",
        },
        {
          text: "Depto.",
          sortable: false,
          align: "start",
          value: "location.name",
        },
        { text: "Fecha", sortable: false, align: "start", value: "created_at" },
        {
          text: "Última Actualización",
          sortable: false,
          align: "start",
          value: "updated_at",
        },
        { text: "Acciones", sortable: false, align: "start", value: "action" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getDocuments: "documents/getSearchDocument",
      getLoading: "documents/getLoading",
    }),
  },
  methods: {
    stateColor(state: string) {
      let classColor = "";
      switch (state) {
        case "recibido":
          classColor = "blue lighten-4";
          break;
        case "enviado":
          classColor = "yellow lighten-4";
          break;
        default:
          classColor = "";
          break;
      }
      return classColor;
    },
    changePage() {
      this.$store.commit("documents/setPageSearchDocument", this.page);
    },
  },
});
