import { DocumentDetailFileService } from "@/services/models/document/documentDetailFile";
import DocumentDetailFile from "@/types/documents/documentDetailFile";
import { Commit } from "vuex";

interface stateInterface {
    detailFile: DocumentDetailFile[],
    loading: boolean,
}
const moduleDocumentDetailFile = {
    namespaced: true,
    state: {
        detailFile: [],
        loading: false,
    } as stateInterface,
    getters: {
        getDetailFile(state: stateInterface): DocumentDetailFile[] {
            return state.detailFile;
        },
        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setDetailFile(state: stateInterface, detailFile: DocumentDetailFile[]): void {
            state.detailFile = detailFile;
        },
        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getDetailFile({commit}: {commit: Commit},id:number): Promise<void> {
            commit('setLoading', true);
            commit("setDetailFile", []);
            const detailFile = await DocumentDetailFileService.getByDocumentDetail(id);
            commit("setDetailFile", detailFile);
            commit('setLoading', false);
        }
    }
}

export default moduleDocumentDetailFile;