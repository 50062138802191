import PurchaseHistory from '@/types/purchaseRequest/purchaseHistory'
import { BaseService } from '../../base'
export class PurchaseHistoryService extends BaseService<PurchaseHistory>() {
    static get entity (): string {
        return 'purchase_request_history'
    }
    static get responseName (): string {
        return 'purchase_request_history'
    }
    static async getByPurchaseId(id:number): Promise<PurchaseHistory[]> {
        return this.submit<PurchaseHistory[]>({
            method: 'get',
            path: `find_by_purchase_request/${id}`
        });
    }
}