import { dataFilterTicket } from '@/store/ticket/moduleTicket';
import ResponsePaginated from '@/types/responsePaginated';
import Ticket from '@/types/tickets/tickets'
import { BaseService } from './../../base'
export class TicketService extends BaseService<Ticket>() {
    static get entity(): string {
        return 'ticket'
    }
    static get responseName(): string {
        return 'ticket'
    }
    static async getByUserId(user_id: number, page: number): Promise<ResponsePaginated<Ticket[]>> {
        return this.submit<ResponsePaginated<Ticket[]>>({
            method: 'get',
            path: `find_by_user_id/${user_id}?page=${page}`
        });
    }
    static async getByGroupId(group_id: number, page: number): Promise<ResponsePaginated<Ticket[]>> {
        return this.submit<ResponsePaginated<Ticket[]>>({
            method: 'get',
            path: `find_by_group_id/${group_id}?page=${page}`
        });
    }

    static async getByDateAndGroup(date_start: string, date_end: string, group_id: number): Promise<Ticket[]> {
        return this.submit<Ticket[]>({
            method: 'get',
            path: `find_by_date_and_group/${date_start}/${date_end}/${group_id}`
        });
    }

    static async getByDateAndUser(date_start: string, date_end: string, user_id: number): Promise<Ticket[]> {
        return this.submit<Ticket[]>({
            method: 'get',
            path: `find_by_date_and_user/${date_start}/${date_end}/${user_id}`
        });
    }

    static async getExcelReport(date_start:string, date_end:string, group_id: number): Promise<any> {                
        return this.submit<any>({
            method: 'get',
            path: `export/${date_start}/${date_end}/${group_id}`,
            config: {
                responseType: 'blob'
            },
            showFullResponse: true
        });
    }
    static async getByTicketFilter(payload: dataFilterTicket,page: number): Promise<ResponsePaginated<Ticket[]>> {
        return this.submit<ResponsePaginated<Ticket[]>>({
            method: 'post',
            path: `find_by_ticket_filter?page=${page}`,
            data:payload as any
        });
    }

}