




























import { DocumentDetailService } from "@/services/models/document/documentDetail";
import { DocumentDetailReadService } from "@/services/models/document/documentDetailRead";
import { DocumentStateService } from "@/services/models/document/documentState";
import { DocumentStateLocationService } from "@/services/models/document/documentStateLocation";
import DocumentState from "@/types/documents/documentState";
import DocumentStateLocation from "@/types/documents/documentStateLocation";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    documentProp: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      loadTable: "tabsDocument/getLoadTable",
      getRequestedDocumentsNotRead: "documents/getRequestedDocumentsNotRead",
    }),
    async checkDocumentDetailRead() {
      try {
        let documentDetails = await DocumentDetailService.getByDocumentId(
          this.documentProp.id
        );
        for (let i = 0; i < documentDetails.length; i++) {
          const document = documentDetails[i];
          let hasDocumentDetailRead = false;
          if (document.documents_details_reads) {
            hasDocumentDetailRead = !!document.documents_details_reads.find(
              (read) => read.user_id == this.getCurrentUserLoggedIn().id
            );
          }
          if (!hasDocumentDetailRead) {
            await DocumentDetailReadService.create({
              user_id: this.getCurrentUserLoggedIn().id,
              document_detail_id: document.id,
              location_id: this.getUserLocation().id,
              id: 0,
            });
          }
        }
      } catch (error) {
        throw new Error(
          `Error al revisar document_detail_read - message: ${error}`
        );
      }
    },
    async updateDocumentState() {
      try {
        if (this.getProfile() == "opartes") {
          const dataToUpdate: DocumentStateLocation = {
            ...this.documentProp.documents_states_locations[0],
            state_read:
              this.documentProp.documents_states_locations[0].state_read ==
              "no leido"
                ? "leido"
                : ("no leido" as "leido" | "no leido"),
          };
          await DocumentStateLocationService.update(
            dataToUpdate.id,
            dataToUpdate
          );
        } else {
          const dataToUpdate: DocumentState = {
            ...this.documentProp.documents_states[0],
            state_read:
              this.documentProp.documents_states[0].state_read == "no leido"
                ? "leido"
                : "no leido",
          };
          await DocumentStateService.update(dataToUpdate.id, dataToUpdate);
        }
      } catch (error) {
        throw new Error(`Error al actualizar el document_state - message: ${error}`);
      }
    },
    async changeStateRead() {
      //TODO (Rubén) que pasa si no tenía permiso para ver todos los documentos, va a quedar como leido documentos que no le han enviado
      try {
        this.isLoading = true;
        await this.updateDocumentState();
        await this.checkDocumentDetailRead();
        this.getRequestedDocumentsNotRead();
        let dataRefresh = {
          user_id: this.getCurrentUserLoggedIn().id,
          folder_id: this.$route.params.id,
        };
        this.displayToast({
          type: "success",
          message: "El documento se ha marcado correctamente como leído",
        });
        this.loadTable(dataRefresh);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message:
            "Lo sentimos, ha ocurrido un error al marcar como leído el documento",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
});
