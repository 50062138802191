








































import ButtonSendPurchaseBuyer from "@/components/purchaseRequest/ButtonSendPurchaseBuyer.vue";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonCreatePurchaseBuyer from "./ButtonCreatePurchaseBuyer.vue/ButtonCreatePurchaseBuyer.vue";
import ButtonEditPurchaseBuyer from "./ButtonEditPurchaseBuyer.vue";
import DetailListCdps from "./DetailListCdps.vue";
export default Vue.extend({
  components: { ButtonCreatePurchaseBuyer, DetailListCdps, ButtonSendPurchaseBuyer, ButtonEditPurchaseBuyer },
  props: {
    purchaseProp: {
      type: Object,
    },
    buyerProp:{
      type:Object
    }
  },
  data(){
    return{
      headers:[
        {
          text: "Id",
          sortable: false,
          value: "id",
        },
        {
          text: "Usuario",
          sortable: false,
          value: "user_id",
        },
        {
          text: "Observación",
          sortable: false,
          value: "observation",
        },
        {
          text: "N° Orden",
          sortable: false,
          value: "n_order",
        },
        {
          text: "N° Licitación",
          sortable: false,
          value: "n_licitation",
        },
        {
          text: "Estado",
          sortable: false,
          value: "state",
        },
        {
          text: "Acciones",
          sortable: false,
          value: "actions",
        },
      ]
    }
  },
  computed:{
    ...mapGetters({
      getPurchaseBuyer:'purchaseBuyer/getPurchaseBuyer',
      getLoading:'purchaseBuyer/getLoading'
    })
  },
  methods:{
    ...mapActions({
      setPurchaseBuyer:'purchaseBuyer/getPurchaseBuyer'
    })
  },
  mounted(){
    this.setPurchaseBuyer(this.purchaseProp.id);
  }
});
