




















































import Vue from "vue";
import myFormInterface from "@/types/myFormInterface";

import Ticket from "@/types/tickets/tickets";
import { TicketService } from "@/services/models/ticket/ticket";
import { TicketFileService } from "@/services/models/ticket/ticketFile";

import FormFiles from "@/components/general/FormFiles.vue";
import FormTicket from "./FormTicket.vue";
import { mapActions } from "vuex";
import TicketHistory from "@/types/tickets/tickets_histories";
import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";

export default (Vue as myFormInterface).extend({
  name: "createTicket",
  components: {
    FormTicket,
    FormFiles,
  },
  props:{
    selectUser:{
      type:Boolean
    }
  },
  data() {
    return {
      tab: null,
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setTickets: "ticket/getTicketsUser",
      setTicketFilter:"ticket/getTicketFilter"
    }),
    async saveTicket() {
      const formTicket = this.$refs.formTicket;
      const formFile = this.$refs.formFile;
      if (!formTicket.validateForm()) {
        this.displayToast({
          type: "warning",
          message: "Favor de llenar todos los campos requeridos",
        });
        return;
      }
      // if (formTicket.ticket.location_id) {

      // }
      try {
        this.displayLoader(true);
        let response = await TicketService.create(formTicket.ticket as Ticket);
        if (formFile) {
          if (formFile.files.length != 0) {
            for (let i = 0; i < formFile.files.length; i++) {
              let formData = new FormData();
              formData.append("ticket_id", `${response.id}`);
              formData.append("file", formFile.files[i]);
              formData.append("user_id", this.getCurrentUserLoggedIn().id.toString());
              await TicketFileService.create(formData, {
                "Content-Type": "multipart/form-data",
              });
            }
          }
        }
        let dataPost: TicketHistory = {
          user_id: this.getCurrentUserLoggedIn().id,
          ticket_id: response.id || 0,
          state: "Ticket Generado",
        };
        await TicketHistoryService.create(dataPost);
        this.setTickets(this.getCurrentUserLoggedIn().id);
        this.setTicketFilter();
        this.displayToast({
          type: "success",
          message: "Ticket ingresado sin problemas",
        });
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Hubo un error al guardar, favor de reintentar",
        });
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
