




























































































import Vue from "vue";
export default Vue.extend({
  props: {
    cdpProps: {
      type: Object,
      required: true,
    },
  },
});
