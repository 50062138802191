









import CreateTicket from "@/components/ticket/createTicket/CreateTicket.vue";
import TableTicket from "@/components/ticket/TableTicket.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: { TemplateAdministrator, TableTicket, CreateTicket },
});
