



































import Vue from "vue";
import FormDispatch from "./FormDispatch.vue";
import FormFiles from "../../general/FormFiles.vue";
export default Vue.extend({
  components: {
    FormDispatch,
    FormFiles
  },
  data() {
    return {
      tab: null,
    };
  },
});
