import TicketMessage from '@/types/tickets/ticket_message'
import { BaseService } from './../../base'
export class TicketMessageService extends BaseService<TicketMessage>() {
    static get entity (): string {
        return 'ticket_message'
    }
    static get responseName (): string {
        return 'ticket_message'
    }
    static async getByTicketId(id:number): Promise<TicketMessage> {
        return this.submit<TicketMessage>({
            method: 'get',
            path: `find_by_ticket_id/${id}`
        });
    }
}