
















































































import { TicketFileService } from "@/services/models/ticket/ticketFile";
import { TicketResponsableService } from "@/services/models/ticket/ticketResponsable";
import TicketFile from "@/types/tickets/tickets_files";
import TicketResponsable from "@/types/tickets/tickets_responsables";
import User from "@/types/user/user";
import Vue from "vue";
import ButtonAddFileToTicket from "./ButtonAddFileToTicket.vue";
export default Vue.extend({
  name: "DetailTableRequestTicket",
  components: { ButtonAddFileToTicket },
  props: {
    itemProp: {
      type: Object,
    },
  },
  data() {
    return {
      headersFiles: [
        {
          text: "Nombre",
          align: "start",
          value: "file_name",
          sortable: false,
        },
        {
          text: "Usuario",
          align: "start",
          value: "user",
          sortable: false,
        },
        {
          text: "Archivo",
          align: "start",
          value: "file",
          sortable: false,
        },
      ],
      headersResponsables: [
        {
          text: "Nombre",
          align: "start",
          value: "fullname",
          sortable: false,
        },
        {
          text: "Observación",
          align: "start",
          value: "observation",
          sortable: false,
        },
      ],
      isLoading: false,
      listFiles: [] as Array<TicketFile>,
      listResponsables: [] as Array<TicketResponsable>,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    showUserName(user: User) {
      let userName = "Sin información";
      if (user) {
        userName =`${user.name} ${user.pather_lastname} ${user.mother_lastname}`
      }
      return userName;
    },
    async loadData() {
      this.isLoading = true;
      await this.loadTickets();
      this.listResponsables = await TicketResponsableService.getByTicketId(
        this.itemProp.id
      );
      this.isLoading = false;
    },
    async loadTickets() {
      this.listFiles = await TicketFileService.getByTicketId(this.itemProp.id);
    }
  },
});
