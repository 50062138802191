import DocumentStateLocation from '@/types/documents/documentStateLocation';
import { formatRut } from './../thirdParty/utils';
import moment from "moment";
import store from "./../store"

import { Component, Vue } from "vue-property-decorator";
import User from "@/types/user/user";
import UserLocation from "@/types/user/userLocation";
import Document from '@/types/documents/document';
import DocumentState from '@/types/documents/documentState';
import UserGroup from '@/types/user/userGroup';
import UserModule from '@/types/user/userModule';
import UserModulePermission from '@/types/user/userModulePermission';

// eslint-disable-next-line
type CallBackFunction = (...args: any[]) => any;

@Component
export default class myMixin extends Vue {
	fieldRules = [(v: string): string | boolean => !!v || "Este campo es obligatorio"]
	selectRules = [(v: number): string | boolean => !!v || "Debe seleccionar una opción"]	
	numberWithCommas(x:number) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	}
	getUrlFile(file_name: string): string {
		const url = window.location.hostname;
		const url2 = url.split("");
		if (url2[url2.length - 1] == "w") {
			return `${process.env.VUE_APP_API_URL_LOCAL}/${file_name}`
		} else {
			return `${process.env.VUE_APP_API_URL}/${file_name}`
		}
	}
	getProfile(): string {
		return this.$store.getters["userSession/getProfile"];
	}
	getCurrentState(document: Document): DocumentState | DocumentStateLocation {
		let currentState: DocumentStateLocation | DocumentState = {
			document_id: 0,
			id: 0,
			state: "creado",
			state_read: 'leido',
			user_id: 0,
			location_id: 0
		};
		if (this.getProfile() == "opartes") {
			if (document.documents_states_locations) {
				currentState = document.documents_states_locations[0]
			}
		} else {
			if (document.documents_states) {
				currentState = document.documents_states[0]
			}
		}
		return currentState;
	}
	getUserLocation(): UserLocation {
		const userLoggedIn = this.getCurrentUserLoggedIn();
		let userLocation = {
			location_id: 0,
			user_id: 0,
			position_id: 0,
			id: 0
		};
		if (userLoggedIn.users_locations?.length) {
			userLocation = userLoggedIn.users_locations[0];
		}
		return userLocation;
	}
	getUserGroup(): UserGroup {
		const userLoggedIn = this.getCurrentUserLoggedIn();
		let userGroup = {
			group_id: 0,			
			user_id: 0,
			id:0
		};
		if (userLoggedIn.users_groups?.length) {
			userGroup = userLoggedIn.users_groups[0];
		}
		return userGroup;
	}
	getCurrentUserLoggedIn(): User {
		const userLoggedIn: User = {
			name: "",
			pather_lastname: "",
			mother_lastname: "",
			email: "",
			role_id: 0,
			run: "",
			stablishment_id: 0,
			avatar: "",
			avatar_name: "",
			users_locations: [],
			state: 'active',
			is_final_authorizer:null,
			id: 0
		}
		const userJson = JSON.parse(window.localStorage.getItem("user") || JSON.stringify(userLoggedIn));
		userLoggedIn.id = userJson.id;
		userLoggedIn.name = userJson.name;
		userLoggedIn.pather_lastname = userJson.pather_lastname;
		userLoggedIn.mother_lastname = userJson.mother_lastname;
		userLoggedIn.email = userJson.email;
		userLoggedIn.run = userJson.run;
		userLoggedIn.role_id = userJson.role_id;
		userLoggedIn.avatar = userJson.avatar;
		userLoggedIn.avatar_name = userJson.avatar_name;
		userLoggedIn.stablishment_id = userJson.stablishment_id;
		userLoggedIn.users_locations = userJson.users_locations;
		userLoggedIn.users_modules = userJson.users_modules;
		userLoggedIn.users_groups = userJson.users_groups;
		userLoggedIn.is_final_authorizer = userJson.is_final_authorizer;
		return userLoggedIn;
	}
	getRutWithoutFormat(data: string): string {
		const rut = data.replace(/[.-]/g, "");
		return rut;
	}
	formatRut(rut: string): string {
		let rutFormatted
		if (rut) {
			rutFormatted = formatRut(rut);
		} else {
			rutFormatted = "Sin información";
		}
		return rutFormatted;
	}
	formatDate(date: string): string {
		const result = date.split(' ');
		let newDate
		if (result.length == 2) {
			newDate = moment(date).format('DD-MM-YYYY HH:mm');
		} else {
			newDate = moment(date).format('DD-MM-YYYY');
		}
		return newDate
	}
	showDate(date: string): string {
		const dateDifferenceInDays = moment().diff(moment(date), "days", false);
		const formattedDate = moment(date).format("DD-MM-YYYY");
		const daysText = dateDifferenceInDays == 1 ? "día" : "días";
		const textDifference =
			dateDifferenceInDays == 0
				? "Hoy"
				: `Hace ${dateDifferenceInDays} ${daysText}`;
		return `${formattedDate} (${textDifference})`;
	}
	displayToast(data: {
		type: "success" | "warning" | "error" | "info"
		title?: string
		message?: string
		milliseconds?: number
	}): void {
		store.commit("toast/showToast", data);
	}
	displayLoader(data: true | false): void {
		store.commit('loader/setLoader', data);
	}
	displayConfirm(data: {
		type?: 'success' | 'error' | 'warning' | 'primary' | 'info'
		message?: string,
		title?: string,
		functionCall?: CallBackFunction,
		state: boolean
	}): void {
		store.commit("confirm/setConfirm", data)
	}
	cleanForm(context: Vue, formName: string): void {
		if (context.$refs[formName]) {
			// eslint-disable-next-line
			(context.$refs[formName] as any).reset();
		}
	}
	//Valida todos los refs del componente padre que tengan la función validateForm
	async validateMultipleForms(): Promise<boolean> {
		let isValid = true;
		for (const prop in this.$refs) {
			// eslint-disable-next-line
			if ((this.$refs[prop] as any).validateForm) {
				// eslint-disable-next-line
				const formValidation: {
					isValid: boolean,
					message?: string
				} =
					// eslint-disable-next-line
					await (this.$refs[prop] as any).validateForm();
				console.log(formValidation);
				if (!formValidation.isValid) {
					isValid = false;
					this.displayToast({
						type: "warning",
						message: formValidation.message || "Por favor complete los campos faltantes",
					});
					return isValid;
				}
			}
		}
		return isValid;
	}
	// eslint-disable-next-line
	isValidCharacter(evt: { key: any; preventDefault: () => void; }) {
		const keysAllowed = [
			"0",
			"1",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"K",
			"k",
		];
		const keyPressed = evt.key;
		if (!keysAllowed.includes(keyPressed)) {
			evt.preventDefault();
		}
	}

	// getUserModules(): UserModule[] {
	// 	let modules: UserModule[] = [];
	// 	const user = this.getCurrentUserLoggedIn();
	// 	const userModules = user.users_modules;
	// 	if (userModules) {
	// 		modules = userModules;
	// 	}
	// 	return modules;
	// }

	// getUserPermissions(): UserModulePermission[] {
	// 	let permissions: UserModulePermission[] = [];
	// 	const modules = this.getUserModules();
	// 	modules.forEach(module => {
	// 		let userModulePermissions: UserModulePermission[] = [];
	// 		if (module.users_modules_permissions) {
	// 			userModulePermissions = module.users_modules_permissions;
	// 		}
	// 		permissions = [...permissions, ...userModulePermissions];
	// 	});
	// 	return permissions;
	// }
}