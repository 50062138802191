






























































import Vue from "vue";
import GroupButtons from "@/components/purchaseRequest/tables/buttonsPurchaseRequest/GroupButtons.vue";
import DetailTablePurchaseRequest from '@/components/purchaseRequest/tables/DetailTablePurchaseRequest.vue';

import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    components:{
    GroupButtons,
    DetailTablePurchaseRequest

    },
  data() {
    return {
      page: 1,
      headers: [
        {
          text: "Id Solicitud",
          sortable: false,
          value: "purchase_request_id",
        },        
        {
          text: "Persona que lo asigno",
          sortable: false,
          value: "user_id",
        },
        {
          text: "Estado",
          sortable: false,
          value: "state",
        },
        {
          text: "Acciones",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getBuyers: "buyer/getBuyerInProcess",
      getLoading: "buyer/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setBuyer: "buyer/getBuyerInProcess",
    }),
    changePage() {
      this.$store.commit("buyer/setPageBuyerInProcess", this.page);
      this.setBuyer({
        userId: this.getCurrentUserLoggedIn().id,
        state: "en proceso",
      });
    },
  },
  mounted() {
    this.changePage();
  },
});
