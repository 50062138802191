


























import { UserGroupService } from "@/services/models/group/userGroup";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  props: {
    groupProp: {
      type: Object,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Nombre",
          sortable: false,
          value: "fullname",
        },
        {
          text: "Acciones",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUserGroup: "userGroup/getUserGroup",
      getLoading: "userGroup/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setUserGroup: "userGroup/getUserGroup",
    }),
    async removeUser(user_id:number){
      this.displayLoader(true)
      await UserGroupService.remove(user_id)
      this.setUserGroup(this.groupProp.id);
      this.displayLoader(false)
    }
  },
  mounted() {
    this.setUserGroup(this.groupProp.id);
  },
});
