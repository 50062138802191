import PurchaseRequestFile from '@/types/purchaseRequest/purchaseRequestFile'
import { BaseService } from './../../base'
export class PurchaseRequestFileService extends BaseService<PurchaseRequestFile>() {
    static get entity (): string {
        return 'purchase_request_file'
    }
    static get responseName (): string {
        return 'purchase_request_file'
    }
    static async getByPurchaseId(purchase_id:number): Promise<PurchaseRequestFile[]> {
        return this.submit<PurchaseRequestFile[]>({
            method: 'get',
            path: `find_by_purchase_request_id/${purchase_id}`
        });
    }
}