














































import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";
import TicketResponsable from "@/types/tickets/tickets_responsables";
import Vue from "vue";
export default Vue.extend({
  props: {
    itemProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      listHistory: [] as Array<TicketResponsable>,
      headers: [
        {
          text: "Usuario",
          sortable: false,
          align: "start",
          value: "user",
        },
        {
          text: "Estado",
          sortable: false,
          align: "start",
          value: "state",
        },
        {
          text: "Fecha",
          sortable: "false",
          align: "start",
          value: "created_at",
        },
      ],
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      }
    },
  },
  methods: {
    async loadData() {
      this.isLoading = true;      
      this.listHistory = await TicketHistoryService.getByTicketId(this.itemProp.id);
      this.isLoading = false;
    },
  },
});
