import DocumentDetailRead from '@/types/documents/documentDetailRead'
import { BaseService } from './../../base'
export class DocumentDetailReadService extends BaseService<DocumentDetailRead>() {
    static get entity (): string {
        return 'document_detail_read'
    }
    static get responseName (): string {
        return 'document_detail_read'
    }
    static async getDocumentDetailId(document_id:number): Promise<DocumentDetailRead[]> {
		return this.submit<DocumentDetailRead[]>({
            method: 'get',
            path: `find_by_document_id/${document_id}`
        });
	}
}