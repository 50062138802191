




























import Vue from "vue";
import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import { mapActions } from "vuex";
import myFormInterface from "@/types/myFormInterface";
import User from "@/types/user/user";
import PurchaseBuyerHistory from "@/types/purchaseRequest/purchaseBuyerHistory";
import { PurchaseBuyerHistoryService } from "@/services/models/purchaseRequest/purchaseBuyerHistory";
import { PurchaseBuyerStateService } from "@/services/models/purchaseRequest/purchaseBuyerState";
import PurchaseBuyerState from "@/types/purchaseRequest/purchaseBuyerState";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import PurchaseHistory from "@/types/purchaseRequest/purchaseHistory";
import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";
export default (Vue as myFormInterface).extend({
  components: {
    InputSelectUsers,
  },
  props: {
    purchaseBuyerProps: {
      type: Object,
    },
    dataSend:{
      type:Object
    },
    label:{
      type:String,
      default:'Enviar Registro'
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setPending: "purchaseBuyer/getPurchaseBuyerPending",
      setReady: "purchaseBuyer/getPurchaseBuyerReady",
    }),
    async loadData() {
      this.setPending({
        userId: this.getCurrentUserLoggedIn().id,
        state: "pendiente",
        step: this.dataSend.currentStep,
      });
      this.setReady({
        userId: this.getCurrentUserLoggedIn().id,
        state: "finalizado",
        step: this.dataSend.currentStep,
      });
    },
    async save() {
      try {
        this.displayLoader(true);
        let userSelected = this.$refs.selectUsers.usersSelects[0];
        await this.postPurchaseBuyerHistory(userSelected);
        await this.postPurchaseBuyerState(userSelected);
        await this.postPurchaseHistory(this.purchaseBuyerProps.purchase_request,userSelected)        
        
        this.displayToast({
          type: "success",
          message: "Registro enviado",
        });
        this.loadData();
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
    async postPurchaseBuyerHistory(user: User) {
      let dataBuyerHistory: PurchaseBuyerHistory = {
        id: 0,
        state: "registro enviado",
        user_origin_id: this.getCurrentUserLoggedIn().id,
        user_destination_id: user.id,
        purchase_buyer_id: this.purchaseBuyerProps.id,
      };
      await PurchaseBuyerHistoryService.create(dataBuyerHistory);
    },
    async postPurchaseBuyerState(user: User) {
      const currentState =
        await PurchaseBuyerStateService.getByPurchaseBuyerIdUserId(
          this.purchaseBuyerProps.id,
          this.getCurrentUserLoggedIn().id
        );
      let dataPurchaseBuyerStatePut: PurchaseBuyerState = {
        ...currentState[0],
        state: "enviado",
        step: this.dataSend.currentStep,
      };
      await PurchaseBuyerStateService.update(
        currentState[0].id,
        dataPurchaseBuyerStatePut
      );

      const sendState =
        await PurchaseBuyerStateService.getByPurchaseBuyerIdUserId(
          this.purchaseBuyerProps.id,
          user.id
        );
      if (sendState.length == 0) {
        let dataPurchaseBuyerStatePost: PurchaseBuyerState = {
          id: 0,
          purchase_buyer_id: this.purchaseBuyerProps.id,
          state: "pendiente",
          step: this.dataSend.toStep,
          user_id: user.id,
          user_location_id: user.users_locations?.[0].location_id || 0,
        };
        await PurchaseBuyerStateService.create(dataPurchaseBuyerStatePost);
      } else {
        let dataPurchaseBuyerStatePut: PurchaseBuyerState = {
          ...sendState[0],
          step: this.dataSend.toStep,
          state: "pendiente",
        };
        await PurchaseBuyerStateService.update(
          sendState[0].id,
          dataPurchaseBuyerStatePut
        );
      }
    },
    async postPurchaseHistory(purchaseRequest: PurchaseRequest,user:User) {
      let dataPurchaseHistory: PurchaseHistory = {
        id: 0,
        purchase_request_id: purchaseRequest.id,
        state: "Registro enviado a presupuesto",
        user_origin_id: this.getCurrentUserLoggedIn().id,
        user_destination_id: user.id,
      };
      await PurchaseHistoryService.create(dataPurchaseHistory);
    },
  },
});
