








































import { DocumentDetailFirmedService } from "@/services/models/document/documentDetailFirmed";
import { DocumentStateService } from "@/services/models/document/documentState";
import { HistoryService } from "@/services/models/document/history";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    documentId: {
      type: Number,
      required: true,
    },
    documentDetailId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loadingOverlay: false as boolean,
      observations: null as string | null,
    };
  },
  methods: {
    ...mapActions({
      getDocumentsDetail: "documentDetail/getByDocumentId",
    }),
    async rejectFirm() {
      this.loadingOverlay = true;
      try {
        //Actualizat documentState
        const documentStates = await DocumentStateService.getByDocumentIdUserId(
          this.documentId,
          this.getCurrentUserLoggedIn().id
        );
        if (documentStates.length) {
          await DocumentStateService.update(documentStates[0].id, {
            ...documentStates[0],
            state: "rechazado",
          });
        }
        //Actualizar documentDetailFirmed
        const documentDetailsFirmed =
          await DocumentDetailFirmedService.getByDocumentDetailId(
            this.documentDetailId
          );
        const documentDetailFirmed = documentDetailsFirmed.find(
          (value) => value.user_id == this.getCurrentUserLoggedIn().id
        );
        if (documentDetailFirmed) {
          await DocumentDetailFirmedService.update(documentDetailFirmed.id, {
            ...documentDetailFirmed,
            state: "rechazado",
          });
        }
        //Agregar history
        await HistoryService.create({
          user_origin_id: this.getCurrentUserLoggedIn().id,
          document_detail_id: this.documentDetailId,
          state: "rechazado",
          location_origin_id: this.getUserLocation().location_id,
          user_destination_id: this.getCurrentUserLoggedIn().id,
          location_destination_id: this.getUserLocation().location_id,
        });
        this.displayToast({
          type: "success",
          message: "La firma se ha rechazado con éxito",
        });
        //Esto va cerrar el modal por que se re-renderiza el componente ModalFirmDocument
        this.getDocumentsDetail(this.documentId);
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Lo sentimos ha ocurrido un error",
        });
      }
      this.loadingOverlay = false;
    },
  },
});
