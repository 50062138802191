interface stateInterface {
	showConfirm: boolean,
    type:string,
    title:string,
    message:string,
    functionCall:CallBackFunction
}
// eslint-disable-next-line
type CallBackFunction = (...args: any[]) => any;
const moduleConfirm = {
	namespaced: true,
	state: {
		showConfirm: false,
        type:'',
        title:'',
        message:'',
        functionCall:Function
	} as stateInterface,
	getters: {
		getShowConfirm(state: stateInterface): stateInterface {
			return state;
		}
	},
	mutations: {
		setConfirm(state: stateInterface, payload: {
			type?: 'success'|'error'|'warning'|'primary'|'info' 
			message?: string,
            title?:string,
            functionCall?:CallBackFunction,
            state:boolean
		}) : void {
			state.type = payload.type || '';
            state.title=payload.title  || '';
			state.message = payload.message || '';
            if(payload.functionCall){
                state.functionCall=payload.functionCall
            }
			state.showConfirm = payload.state;
		},
	}
}

export default moduleConfirm;