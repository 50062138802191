import Vue from 'vue'
import { ErrorWrapper } from "./util"
import { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios"

interface AxiosRequestConfigCustom extends AxiosRequestConfig {
	showResponseWrapper?: boolean;
	isFormData?: boolean;
	"Content-Type"?: string;
}

//BaseService es una función genérica que retorna una clase declarada localmente. 
//La clase retornada no es genérica, por lo tanto sus propiedades y métodos estáticos 
//tienen tipos concretos para T.
// eslint-disable-next-line
export function BaseService<T>() {
	abstract class BaseService {
		static get entity(): string | void {
			throw new Error('entity getter not defined')
		}
		static get responseName(): string | null {
			throw new Error('name getter not defined')
		}
		static get profile(): string | null {
			const profile = window.localStorage.getItem("perfil");
			return profile;
		}

		/**
		 * The method used to perform an AJAX-request.
		 *
		 * @param {string}      requestType The request type.
		 * @param {string}      url         The URL for the request.
		 * @param {string|null} name        The name of the object response.
		 * @param {Record<string, unknown> |null} data        The data to be send with the request.
		 * @param {Object|null} config      The config object to be sent with the request.
		 *
		 * @returns {Promise} The result in a promise.
		 */
		static async submit<Type>(submitData: submitInterface<Type>): Promise<Type> {
			try {
				const path = submitData.isFullPath ? submitData.path : `${this.profile}/${this.entity}/${submitData.path}`;
				const response: AxiosResponse = await Vue.$http[submitData.method](path, submitData.method == "get" ? submitData.config : submitData.data, submitData.config);
				const responseName = submitData.responseName ? submitData.responseName : this.responseName;
				let data;
				data = response;
				if (responseName && !submitData.showFullResponse) {
					data = responseName ? response.data[responseName] : response.data;
				}
				if (submitData.config == null) {
					return data;
				} else {
					if (submitData.config.showResponseWrapper) {
						return data;
						// resolve(new ResponseWrapper(response, data));
					} else {
						return data;
					}
				}
			} catch (error: any) {
				if (error) {
					// reject(new ErrorWrapper(error, ""));
					throw new ErrorWrapper(error, "")
					// reject(response);
					// throw new ErrorWrapper(response)
				} else {
					throw "Error";
				}
			}
			/*
			return new Promise<Type>((resolve, reject) => {
				const path = submitData.isFullPath ? submitData.path : `${this.profile}/${this.entity}/${submitData.path}`;
				Vue.$http[submitData.method](path, submitData.data, submitData.config)
					.then((response: AxiosResponse) => {
						const responseName = submitData.responseName ? submitData.responseName : this.responseName;
						let data;
						if (responseName) {
							data = responseName ? response.data[responseName] : response.data;
							console.log(data);
						}
						if (submitData.config == null) {
							resolve(data);
						} else {
							if (submitData.config.showResponseWrapper) {
								resolve(data);
								// resolve(new ResponseWrapper(response, data));
							} else {
								resolve(data);
							}
						}
					})
					.catch(({ response }: AxiosError) => {
						if (response) {
							reject(new ErrorWrapper(response, ""));
							// reject(response);
							// throw new ErrorWrapper(response)
						} else {
							reject();
						}
					});
			});
			*/
		}

		/**
		 * ------------------------------
		 * @HELPERS
		 * ------------------------------
		 */

		// static responseWrapper(...rest: any[]) {
		// 	return new ResponseWrapper(...rest)
		// }

		// static errorWrapper(...rest: any[]) {
		// 	return new ErrorWrapper(...rest)
		// }

		/**
		 * ----------------------------
		 * BASIC API CALLS
		 * ----------------------------
		 */

		static async getAll(): Promise<T[]> {
			return this.submit({
				method: 'get',
				path: `${this.profile}/${this.entity}`,
				isFullPath: true
			});
		}

		static async getById(id: number): Promise<T> {
			return this.submit({
				method: 'get',
				path: `${id}`,
			});
		}

		static async create(data: T | FormData, config: AxiosRequestConfigCustom = {}): Promise<T> {
			return this.submit({
				method: 'post',
				path: `${this.profile}/${this.entity}`,
				data: data,
				config: config,
				isFullPath: true
			});
		}

		static async update(id: number, data: T): Promise<T> {
			return this.submit({
				method: 'put',
				path: `${id}`,
				data: data
			});
		}

		static async remove(id: number): Promise<T> {
			return this.submit({
				method: 'delete',
				path: `${id}`,
			});
		}

		static async getTrash(): Promise<T[]> {
			return this.submit({
				method: 'get',
				path: `trash`,
			});
		}

		static async restore(id: number): Promise<T> {
			return this.submit({
				method: 'delete',
				path: `activate/${id}`
			});
		}
	}
	return BaseService;
}

interface submitInterface<T> {
	method: 'get' | 'post' | 'put' | 'delete',
	path: string,
	responseName?: string | null,
	//No se que es Record, pero el compilador de ts me lo dijo :)
	data?: T | Record<string, unknown> | FormData | null,
	config?: AxiosRequestConfigCustom | null,
	isFullPath?: boolean,
	showFullResponse?: boolean
}