












































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonChangeRead from "../ButtonChangeRead.vue";
import ShowDocumentDetail from "../ShowDocument/ShowDocumentDetail.vue";
export default Vue.extend({
  components: {
    ShowDocumentDetail,
    ButtonChangeRead,
  },
  data() {
    return {
      page: 1,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false,
        },
        { text: "Número", sortable: false, align: "start", value: "number" },
        {
          text: "Descripción",
          sortable: false,
          align: "start",
          value: "description",
        },
        {
          text: "Tipo de Documento",

          sortable: false,
          align: "start",
          value: "document_type.name",
        },
        {
          text: "Depto.",
          sortable: false,
          align: "start",
          value: "location.name",
        },
        { text: "Fecha", sortable: false, align: "start", value: "created_at" },
        {
          text: "Última Actualización",
          sortable: false,
          align: "start",
          value: "updated_at",
        },
        { text: "Acciones", sortable: false, align: "start", value: "action" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getDocuments: "documents/getByFirmedDocument",
      getLoading: "documents/getLoading",
    }),
  },
  mounted() {
    this.setDocument(this.getCurrentUserLoggedIn().id);
  },
  methods: {
    ...mapActions({
      setDocument: "documents/getByFirmedDocument",
    }),
    changePage() {
      this.$store.commit("documents/setPageByFirmedDocument", this.page);
      this.setDocument(this.getCurrentUserLoggedIn().id);
    },
  },
});
