





































import { DocumentStateService } from "@/services/models/document/documentState";
import { FolderService } from "@/services/models/document/folder";
import DocumentState from "@/types/documents/documentState";
import Folder from "@/types/documents/folder";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    documentProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      items: [] as Folder[],
      folderIdSelected: 0,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      }
    },
  },
  methods: {
    ...mapActions({
      setDocument: "tabsDocument/getLoadTable",
    }),
    async loadData() {
      this.displayLoader(true);
      this.items = await FolderService.getByUserId(this.getCurrentUserLoggedIn().id);
      this.displayLoader(false);
    },
    async save() {
      this.displayLoader(true);
      try {
        let responseState: DocumentState[] =
          await DocumentStateService.getByDocumentIdUserId(
            this.documentProp.id,
            this.getCurrentUserLoggedIn().id
          );
        let dataPut: DocumentState = {
          ...responseState[0],
          folder_id: this.folderIdSelected,
        };
        await DocumentStateService.update(responseState[0].id, dataPut);
        let dataRefresh = {
          user_id: this.getCurrentUserLoggedIn().id,
          folder_id: this.$route.params.id,
        };
        this.setDocument(dataRefresh);
        this.displayLoader(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
