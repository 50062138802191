import { DispatchService } from "@/services/models/dispatch/dispatch";
import Dispatch from "@/types/dispatch/dispatch";
import ResponsePaginated from "@/types/responsePaginated";
import { Commit } from "vuex";

interface stateInterface {
    dispatch: ResponsePaginated<Dispatch[]>,
    loading: boolean,
}
const moduleDispatch = {
    namespaced: true,
    state: {
        dispatch: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        loading: false,
    } as stateInterface,
    getters: {
        getDispatch(state: stateInterface): ResponsePaginated<Dispatch[]> {
            return state.dispatch;
        },
        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setDispatch(state: stateInterface, dispatch: ResponsePaginated<Dispatch[]>): void {
            state.dispatch = dispatch;
        },
        setPageDispatch(state: stateInterface, page: number): void {
            state.dispatch.current_page = page;
        },
        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getDispatch({ commit, state }: { commit: Commit, state: stateInterface }, stablishment_id:number): Promise<void> {
            commit('setLoading', true);
            const dispatch = await DispatchService.getStablishmentId(stablishment_id, state.dispatch.current_page);
            commit("setDispatch", dispatch);
            commit('setLoading', false);
        },
    }
}

export default moduleDispatch;