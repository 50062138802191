import { FolderService } from "@/services/models/document/folder";
import Folder from "@/types/documents/folder";
import { Commit } from "vuex";

interface stateInterface {
    folder: Folder[],
    loading: boolean,
}
const moduleFolder = {
    namespaced: true,
    state: {
        folder: [],
        loading: false,
    } as stateInterface,
    getters: {
        getFolder(state: stateInterface): Folder[] {
            return state.folder;
        },
        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setFolder(state: stateInterface, folder: Folder[]): void {
            state.folder = folder;
        },
        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getFolder({commit}: {commit: Commit},user_id:number): Promise<void> {
            commit('setLoading', true);            
            const folder = await FolderService.getByUserId(user_id);
            commit("setFolder", folder);
            commit('setLoading', false);
        }
    }
}

export default moduleFolder;