



























   import myFormInterface from "@/types/myFormInterface";
import Vue from "vue";
import FormCdp from "../cdpForm/FormCdp.vue";
   export default (Vue as myFormInterface).extend({
  components: { FormCdp },
    props:{
        purchaseProp:{
            type:Object
        },
        add:{
          type:Function
        }
    },
     data() {
       return {
         dialog: false,
       };
     },
     methods:{
       addCdp(){         
         this.add(this.$refs.formCdp.data)
         this.dialog=false;
       }
     }
   });
