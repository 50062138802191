import LocationInterface from '@/types/user/location';
import { BaseService } from '../../base'
export class LocationService extends BaseService<LocationInterface>() {
	static get entity(): string {
		return 'location'
	}
	static get responseName(): string {
		return 'location'
	}

	static async getByStablishmentId(		
		stablishment_id: string | number,
	): Promise<LocationInterface[]> {
		return this.submit<LocationInterface[]>({
			method: 'get',
			path: `find_by_stablishment/${stablishment_id}`
		});
	}

	static async getByLocationTypeStablishment(
		location_type_id: string | number,
		stablishment_id: string | number,
	): Promise<LocationInterface[]> {
		return this.submit<LocationInterface[]>({
			method: 'get',
			path: `find_by_location_stablishment/${location_type_id}/${stablishment_id}`
		});
	}

	static async getByParentLocationTypeStablishment(
		location_type_id: string | number,
		stablishment_id: string | number,
		parent_id?: string | number
	): Promise<LocationInterface[]> {
		return this.submit<LocationInterface[]>({
			method: 'get',
			isFullPath: true,
			path: `${this.profile}/${this.entity}/find_by_parent_location_stablishment/${parent_id}/${location_type_id}/${stablishment_id}`,
		});
	}
}