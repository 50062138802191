import DispatchLocation from '@/types/dispatch/dispatch_location'
import { BaseService } from './../../base'
export class DispatchLocationService extends BaseService<DispatchLocation>() {
    static get entity (): string {
        return 'dispatch_location'
    }
    static get responseName (): string {
        return 'dispatch_location'
    }
    static async getStablishmentId(stablishment_id:number): Promise<DispatchLocation[]> {
        return this.submit<DispatchLocation[]>({
            method: 'get',
            path: `find_by_stablishment_id/${stablishment_id}`,          
            responseName:'dispatch'  
        });
    }
}