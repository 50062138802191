
































import { DispatchService } from "@/services/models/dispatch/dispatch";
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  props: {
    dispatchProps: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setDocument: "dispatch/getDispatch",
    }),
    async removeDispatch() {
      this.displayLoader(true);
      await DispatchService.remove(this.dispatchProps.id);
      this.setDocument(this.getCurrentUserLoggedIn().stablishment_id);
      this.displayToast({
          type: 'success',
          message: 'Se eliminó el despacho con éxito'
      });      
      this.displayLoader(false);
    },
  },
});
