



























import DispatchLocation from "@/types/dispatch/dispatch_location";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import EditDispatchLocation from "./EditDispatchLocation.vue";
export default Vue.extend({
  components: { EditDispatchLocation },
  data() {
    return {
      listLocations: [] as Array<DispatchLocation>,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false,
        },
        { text: "Nombre", sortable: false, align: "start", value: "name" },
        {
          text: "Fecha",
          sortable: false,
          align: "start",
          value: "created_at",
        },
        { text: "Acciones", sortable: false, align: "start", value: "action" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getDispatchLocation: "dispatchLocation/getDispatchLocation",
      getLoading: "dispatchLocation/getLoading",
    }),
  },
  mounted() {
    this.setDispatchLocation(this.getCurrentUserLoggedIn().stablishment_id);
  },
  methods: {
    ...mapActions({
      setDispatchLocation: "dispatchLocation/getDispatchLocation",
    }),
  },
});
