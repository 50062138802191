
































































import Vue from "vue";

import { DocumentStateService } from "@/services/models/document/documentState";
import { HistoryService } from "@/services/models/document/history";
import { DocumentDetailFirmedService } from "@/services/models/document/documentDetailFirmed";
import { DocumentDetailCheckedService } from "@/services/models/document/documentDetailChecked";
import { DocumentStateLocationService } from "@/services/models/document/documentStateLocation";

import InputSelectUsers from "./InputSelectUsers.vue";
import History from "@/types/documents/history";
import DocumentState, {
  statesDocuments,
} from "@/types/documents/documentState";
import DocumentDetailFirmed from "@/types/documents/documentDetailFirmed";
import DocumentDetailChecked from "@/types/documents/documentDetailChecked";
import DocumentStateLocation from "@/types/documents/documentStateLocation";

import myFormInterface from "@/types/myFormInterface";
import User from "@/types/user/user";
import { mapActions } from "vuex";

export default (Vue as myFormInterface).extend({
  components: { InputSelectUsers },
  props: {
    detailProp: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      description: "" as string,
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      getHistories: "history/getDocumentDetailId",
      loadTable: "tabsDocument/getLoadTable",
    }),

    async saveSend() {
      this.isLoading = true;
      let functionaries = this.$refs.selectFunctionary.usersSelects;
      let checkeds = this.$refs.selectCheckeds.usersSelects;
      let firms = this.$refs.selectFirms.usersSelects;
      if (functionaries.length == 0 && checkeds.length == 0 && firms.length == 0) {
        this.displayToast({
          type: "error",
          message: "Favor de seleccionar a algún funcionario",
        });
        this.isLoading = false;
        return;
      }

      /**
       * Desde aqui inicia el proceso de envio
       */

      let promise = [];
      if (!(await this.comprobateBeforeSend(functionaries, false))) {
        this.isLoading = false;
        return;
      }
      for (let i = 0; i < functionaries.length; i++) {
        promise.push(
          new Promise((resolve, reject) => {
            try {
              this.postHistory(functionaries[i]).then((resp) => {
                let response = resp;
                this.postState(response, functionaries[i], "recibido").then(
                  () => {
                    this.postStateLocation(
                      response,
                      functionaries[i],
                      "recibido"
                    ).then((response) => {
                      resolve(response);
                    });
                  }
                );
              });
            } catch (error) {
              console.log(error);
              throw reject(error);
            }
          })
        );
      }
      if (!(await this.comprobateBeforeSend(checkeds, false))) {
        this.isLoading = false;
        return;
      }
      for (let i = 0; i < checkeds.length; i++) {
        promise.push(
          new Promise((resolve, reject) => {
            try {
              this.postHistory(checkeds[i]).then(async (response) => {
                await this.postState(response, checkeds[i], "por visar");
                await this.postChecked(
                  checkeds[i].id as number,
                  response?.id as number
                );
                resolve(response);
              });
            } catch (error) {
              console.log(error);
              throw reject(error);
            }
          })
        );
      }
      if (!(await this.comprobateBeforeSend(firms, true))) {
        this.isLoading = false;
        return;
      }
      for (let i = 0; i < firms.length; i++) {
        promise.push(
          new Promise((resolve, reject) => {
            try {
              this.postHistory(firms[i]).then(async (response) => {
                await this.postState(response, firms[i], "por firmar");
                await this.postFirmed(
                  firms[i].id as number,
                  response?.id as number
                );
                resolve(response);
              });
            } catch (error) {
              console.log(error);
              throw reject(error);
            }
          })
        );
      }

      /******************************************************************/
      //Estado del que envia el documento
      let resp = await DocumentStateService.getByDocumentIdUserId(
        this.detailProp.document_id,
        this.getCurrentUserLoggedIn().id
      );

      if (resp.length == 0) {
        let data: DocumentState = {
          id: 0,
          document_id: this.detailProp.document_id,
          state: "enviado",
          state_read: "leido",
          user_id: this.getCurrentUserLoggedIn().id,
        };
        await DocumentStateService.create(data);
      } else {
        let data: DocumentState = {
          ...resp[0],
          state: "enviado",
          state_read: "leido",
        };
        await DocumentStateService.update(resp[0].id || 0, data);
      }

      //Estado del grupo que envia el documento (Opartes)
      let respLocation =
        await DocumentStateLocationService.getByDocumentIdLocationId({
          document_id: this.detailProp.document_id,
          location_id: this.getUserLocation().location_id,
        });      
      
      if (respLocation.length == 0) {
        let dataLocation: DocumentStateLocation = {
          id: 0,
          document_id: this.detailProp.document_id,
          state: "enviado",
          state_read: "leido",
          location_id: this.getUserLocation().location_id,
        };        
        await DocumentStateLocationService.create(dataLocation);
      } else {        
        let dataLocation: DocumentStateLocation = {
          ...respLocation[0],
          state: "enviado",          
        };
        await DocumentStateLocationService.update(
          respLocation[0].id || 0,
          dataLocation
        );
      }
      //************************************************************/
      Promise.all(promise)
        .then(() => {
          this.getHistories(this.detailProp.id);
          let dataRefresh = {
            user_id: this.getCurrentUserLoggedIn().id,
            folder_id: this.$route.params.id,
          };
          this.loadTable(dataRefresh);
          this.isLoading = false;
          this.dialog = false;
        })
        .catch((reason) => {
          console.log(reason);
        });
    },

    async postHistory(user: User): Promise<History> {
      let data: History;
      try {
        data = {
          document_detail_id: this.detailProp.id,
          user_origin_id: this.getCurrentUserLoggedIn().id,
          user_destination_id: user.id || 0,
          location_origin_id: this.getUserLocation().location_id,
          location_destination_id: 1,
          description: this.description,
          state: "enviado",
        };
      } catch (error) {
        console.log(error);
        throw error;
      }
      return await HistoryService.create(data);
    },

    async postState(history: History, user: User, state: statesDocuments) {
      try {
        let response = await DocumentStateService.getByDocumentIdUserId(
          this.detailProp.document_id,
          user.id
        );
        if (response.length != 0) {
          await DocumentStateService.update(response[0].id || 0, {
            ...response[0],
            state: state,
          });
          return;
        }
        let data: DocumentState = {
          id: 0,
          user_id: user.id,
          history_id: history.id,
          document_id: this.detailProp.document_id,
          state: state,
          state_read: "no leido",
        };
        await DocumentStateService.create(data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async postFirmed(user_id: number, history_id: number) {
      try {
        let data: DocumentDetailFirmed = {
          document_detail_id: this.detailProp.id as number,
          state: "por firmar",
          date_firmed: null,
          user_id: user_id,
          history_id: history_id,
          id: 0,
        };
        await DocumentDetailFirmedService.create(data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async postChecked(user_id: number, history_id: number) {
      try {
        let data: DocumentDetailChecked = {
          document_detail_id: this.detailProp.id as number,
          state: "por visar",
          date_checked: null,
          user_id: user_id,
          history_id: history_id,
        };
        await DocumentDetailCheckedService.create(data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async comprobateBeforeSend(users: User[], toFirm: boolean) {
      let verifiySend = true;
      if (users.length != 0) {
        let usersFirms =
          await DocumentDetailFirmedService.getByDocumentDetailId(
            this.detailProp.id
          );
        let usersCheckeds =
          await DocumentDetailCheckedService.getByDocumentDetailId(
            this.detailProp.id
          );

        //Se valida que uno de los funcionarios no sea visador ni firmante
        let verifyRepeatUsers = true;
        usersFirms.forEach((signatory) => {
          users.forEach((user) => {
            if (signatory.user_id == user.id) {
              verifyRepeatUsers = false;
            }
          });
        });

        usersCheckeds.forEach((checked) => {
          users.forEach((user) => {
            if (checked.user_id == user.id) {
              verifyRepeatUsers = false;
            }
          });
        });

        if (verifyRepeatUsers) {
          //Verificar esta parte cuando se envia sin visa ni firmante, esto deberia gatillarse al enviar para firmar

          //Se verifica que esten todas las visaciónes listas antes de firmar
          let verifyCheckState = true;
          usersCheckeds.forEach((checked) => {
            users.forEach((user) => {
              if (checked.state == "por visar" && checked.user_id == user.id) {
                verifyCheckState = false;
              }
            });
          });
          if (!verifyCheckState) {
            //Faltan visaciones
            verifiySend = false;
            this.displayToast({
              type: "error",
              message: "No se puede enviar para firmar, faltan visaciones",
            });
          } else {
            if (toFirm) {
              let verifyAllCheckedReady = true;
              usersCheckeds.forEach((checked) => {
                if (checked.state == "por visar") {
                  verifyAllCheckedReady = false;
                }
              });
              if (!verifyAllCheckedReady) {
                //Faltan visaciones
                verifiySend = false;
                this.displayToast({
                  type: "error",
                  message: "No se puede enviar para firmar, faltan visaciones",
                });
              }
            }
          }
        } else {
          verifiySend = false;
          this.displayToast({
            type: "error",
            message: "Existen usuario que ya estan como firmantes/visadores",
          });
        }
      }
      return verifiySend;
    },

    async postStateLocation(
      history: History,
      user: User,
      state: statesDocuments
    ) {
      try {
        let response =
          await DocumentStateLocationService.getByDocumentIdLocationId({
            document_id: this.detailProp.document_id,
            location_id: user.users_locations?.[0].location_id || 0,
          });
        if (response.length != 0) {
          await DocumentStateLocationService.update(response[0].id || 0, {
            ...response[0],
            state: state,
          });
          return;
        }
        let data: DocumentStateLocation = {
          id: 0,
          history_id: history.id,
          document_id: this.detailProp.document_id,
          location_id: user.users_locations?.[0].location_id || 0,
          state: state,
          state_read: "no leido",
        };
        await DocumentStateLocationService.create(data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
});
