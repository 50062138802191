











import Vue from 'vue'
import {generatePDFPurchaseRequest} from '@/reports/reportPurchaseRequest'
export default Vue.extend({
    props:{
        purchaseProp:{
            type:Object
        }
    },
    methods:{
        async print(){
            this.displayLoader(true)
            await generatePDFPurchaseRequest(this.purchaseProp);
            this.displayLoader(false)
        }
    }
})
