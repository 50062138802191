





















































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import DetailTableCdp from "../DetailTableCdp.vue";

export default Vue.extend({
  components: {
    DetailTableCdp,
  },
  data() {
    return {
      page: 1,
      headers: [
        {
          text: "Id del CDP",
          sorteable: false,
          value: "id",
        },
        {
          text:'Id del Registro',
          sorteable:false,
          value:'purchase_buyer_id'
        },
        {
          text: "Comprador",
          sorteable: false,
          value: "user",
        },
        {
          text: "Firmante",
          sorteable: false,
          value: "firmUser",
        },
        {
          text: "Acciones",
          sorteable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getCdp: "cdp/getCdpStateUser",
      getLoading: "cdp/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setCdp: "cdp/getCdpStateUser",
    }),
    changePage() {
      this.$store.commit("cdp/setPageCdpStateUser", this.page);
      this.setCdp({
        state: "firmado",
        user: this.getCurrentUserLoggedIn().id,
      });
    },
  },
  mounted() {
    this.changePage();
  },
});
