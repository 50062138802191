









































import { UserService } from "@/services/models/user/user";
import myFormInterface from "@/types/myFormInterface";
import User from "@/types/user/user";
import Vue, { PropType } from "vue";
export default (Vue as myFormInterface).extend({
	props: {
		userProp: {
      required: true,
      type: Object as PropType<User>,
    },
	},
  data() {
    return {
      password: "" as string,
      passwordConfirmation: "" as string,
			loading: false as boolean,
    };
  },
	methods: {
		async resetPassword() {
			const isFormValid = this.$refs.myForm.validate();
			if (!isFormValid) {
				this.displayToast({
					type: 'warning',
					message: 'Por favor complete los campos necesarios'
				});
				return;
			}
			if (this.password !== this.passwordConfirmation) {
				this.displayToast({
					type: 'warning',
					message: 'Las contraseñas no coinciden'
				});
				return;
			}
			this.loading = true;
			try {
				await UserService.resetPassword(this.userProp.id, {
					password: this.password
				});
				this.displayToast({
					type: 'success',
					message: 'La contraseña se ha cambiado correctamente'
				});
				this.$emit('changeDialog', false);
			} catch (error) {
				this.displayToast({
					type: 'error',
					message: 'Lo sentimos, ha ocurrido un error'
				});
			}
			this.loading = false;
		}
	}
});
