

























import Vue from "vue";
import TableReaders from "./TableReaders.vue";
export default Vue.extend({
  components: { TableReaders },
  props: {
    detailProp: {
      type: Object,
    },
  },
  data() {
    return {
      menu: false as boolean,
    };
  },
});
