







































import Vue from "vue";
import CardDataDocument from "./CardDataDocument.vue";
import PanelDetail from "./panelDetail/PanelDetail.vue";

export default Vue.extend({
  props: {
    documentProp: {
      required: true,
      type: Object,
    },
  },
  components: {
    CardDataDocument,
    PanelDetail,
  },
  data() {
    return {
      dialog: false,
    };
  },
});
