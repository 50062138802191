import { Content, Margins, StyleDictionary } from "pdfmake/interfaces";

import logoRedondoSSM from "@/other/base64Logo";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import Cdp from "@/types/purchaseRequest/cdp";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
export const generatePdfCdp = async (cdp: Cdp, print: boolean) => {
    
    const pdfMake = await import("pdfmake/build/pdfmake.js");
    if (pdfMake.vfs == undefined) {
        const pdfFonts = await import("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    const dataCdp = await CdpService.getById(cdp.id);
    console.log(dataCdp);

    //Se Carga la tabla con los articulos asociados a la solicitud de compra
    const tableArticle = []
    if (dataCdp.cdps_articles) {
        for (let i = 0; i < dataCdp.cdps_articles.length; i++) {
            tableArticle.push([
                { text: `${dataCdp.cdps_articles[i].purchase_request_detail?.article?.name}` },
                { text: `${dataCdp.cdps_articles[i].purchase_request_detail?.cant}` },
                { text: `${numberWithCommas(dataCdp.cdps_articles[i].purchase_request_detail?.price as number)}` },
                { text: `${dataCdp.cdps_articles[i].purchase_request_detail?.observation}` }
            ])
        }
    }

    const docDefinition = {
        pageMargins: [40, 30, 40, 60] as Margins,
        footer(currentPage: number, pageCount: number):any {
            return {
                table: {
                    widths: [230],
                    body: [                        
                        [ {   
                            text: `CDP N°${dataCdp.id}, ${moment(dataCdp.updated_at).format('YYYY-MM-DD hh:mm')} Página ${currentPage} de ${pageCount}`,
                            style:  {
                                fontSize: 10,
                                bold: true,
                                alignment: 'left'
                            },
                            margin: [40, 10, 0, 0]
                        },]
                    ],
                    layouts: 'noBorders'
                },
            }
        },
        content: [
            {
                columns: [
                    {
                        image: logoRedondoSSM,
                        width: 70,
                    },
                ]
            },
            {
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['*'],
                    body: [
                        [
                            {
                                text: `Certificado de Disponibilidad Presupuestaria N°${dataCdp.id}`,
                                alignment: 'center',
                                style: 'title',
                                margin: [0, 10, 40, 0]
                            },
                        ],
                        [
                            { text: `${dataCdp.header?.description}`, alignment: 'center' },
                        ],
                    ]
                }
            },
            {
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['*', '*', '*', '*'],
                    body: [
                        [
                            { text: `Solicitud N°`, style: 'tableHeader' },
                            { text: `${dataCdp.n_request}` },
                            { text: `Plan de Compra`, style: 'tableHeader' },
                            { text: `${dataCdp.purchase_plan}` },
                        ],
                        [
                            { text: `De`, style: 'tableHeader' },
                            { text: `${dataCdp.user?.name} ${dataCdp.user?.pather_lastname} ${dataCdp.user?.mother_lastname}`, colSpan: 3 }, {}, {}
                        ],
                        [
                            { text: `Detalle de la Compra`, style: 'tableHeader' },
                            { text: `${dataCdp.detail}`, colSpan: 3 }, {}, {}
                        ],
                        [
                            { text: `Nombre Proveedor`, style: 'tableHeader' },
                            { text: `${dataCdp.provider?.name}` },
                            { text: `Rut Proveedor`, style: 'tableHeader' },
                            { text: `${dataCdp.provider?.rut}` },
                        ],
                        [
                            { text: `Monto Presupuesto`, style: 'tableHeader' },
                            { text: `${dataCdp.budget_amount}` },
                            { text: `Mes Devengado`, style: 'tableHeader' },
                            { text: `${dataCdp.accrued_month}` },
                        ],
                        [
                            { text: `Presupuesto`, style: 'tableHeader' },
                            { text: `${dataCdp.budget?.name}`, colSpan: 3 }, {}, {}
                        ],
                        [
                            { text: `Proyecto`, style: 'tableHeader' },
                            { text: `${dataCdp.project?.name}` },
                            { text: `Cta. Complementaria`, style: 'tableHeader' },
                            { text: `${dataCdp.suplementary_account?.name}` },
                        ],
                        [
                            { text: `Monto Autorizado`, style: 'tableHeader' },
                            { text: `${dataCdp.authorized_amount}` },
                            { text: `Mes de Pago`, style: 'tableHeader' },
                            { text: `${dataCdp.pay_month}` },
                        ],
                        [
                            { text: `Modalidad`, style: 'tableHeader' },
                            { text: `${dataCdp.purchase_method?.name}`, colSpan: 3 }, {}, {}
                        ],
                        [
                            { text: `Causal`, style: 'tableHeader' },
                            { text: `${dataCdp.causal?.name}`, colSpan: 3 }, {}, {}
                        ],
                        [
                            { text: `Id N°`, style: 'tableHeader' },
                            { text: `${dataCdp.number_id}`, colSpan: 3 }, {}, {}
                        ],
                    ]
                }
            },
            {
                margin: [0, 10, 0, 0],
                table: {
                    headerRows: 2,
                    widths: [150, 50, 70, '*'],
                    body: [
                        [
                            { text: 'Artículos/Servicios', style: 'title', colSpan: 4, alignment: 'center' }, {}, {}, {}
                        ],
                        [
                            { text: 'Nombre', style: 'tableHeader' },
                            { text: 'Cantidad', style: 'tableHeader' },
                            { text: 'Precio Apróximado', style: 'tableHeader' },
                            { text: 'Observación', style: 'tableHeader' },
                        ],
                        ...tableArticle
                    ]
                },
            }
        ] as Content,
        styles: {
            title: {
                bold: true,
                fontSize: 15,
                color: 'white',
                fillColor: '#EE3A3A'
            },
            tableHeader: {
                bold: true,
                fontSize: 11,
                color: 'black',
                fillColor: '#e4e4e4'
            },
            tableExample: {
                alignment: "justify",
                margin: [0, -150, 0, 15],
            },
            toTheRight: {
                alignment: "right",
            },
            header: {
                fontSize: 17,
                alignment: "left",
                bold: true,
            },
            titleHeader: {
                fontSize: 18,
                bold: true,
                alignment: 'center'
            },
            subtitleHeader: {
                fontSize: 9,
                alignment: 'center'
            }
        } as StyleDictionary,
    };
    if (print) {
        /**Para IMPRIMIR */
        pdfMake.createPdf(docDefinition).download(`CDP N°${dataCdp.id} ${moment().format('DD-MM-YYYY')}`)
    } else {
        /**GENERAR BASE64 */
        const asdf = pdfMake.createPdf(docDefinition);
        const promise = new Promise((resolve) => {
            asdf.getBase64((data) => {
                resolve(data)
            });
        });
        return promise;
    }

}
