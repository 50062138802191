import EmptyRouterView from '@/views/EmptyRouterView.vue';
import Home from '@/views/administrative/Home.vue';
import Document from '@/views/administrative/Document.vue';
import Folder from '@/views/administrative/Folder.vue';
import Contact from '@/views/administrative/Contact.vue';
import Ticket from '@/views/administrative/Ticket.vue';
import RequestTicket from '@/views/administrator/RequestTickets.vue';
import MantainerUsers from '@/views/administrator/MaintainerUsers.vue';
import Group from '@/views/administrator/Group.vue'
import TicketReports from '@/views/administrator/TicketReports.vue';
export const routes = [
  { path: '/administrativo',
    component: EmptyRouterView,
    children: [
      {
        path: 'inicio',
        name: 'administrativeHome', 
        component: Home,
      },    
      {
        path: 'documentos',
        name: 'documentAdministrative', 
        component: Document,
      },      
      {
        path: 'carpeta/:userID/:folderName/:id',
        name: 'folderAdministrative', 
        component: Folder,
        // Para verificar que la persona que entre a la ruta de la carpeta sea la correcta
        // eslint-disable-next-line
        beforeEnter(to:any, from:any, next:any) {
          console.log('to:',to,'from',from,'next',next);
          if(to.params.userID==JSON.parse(window.localStorage.getItem("user") || '{}').id){            
            next();
          }else{
            alert('No esta autorizado para ingresar a esta ruta');
            next('inicio')
          }
        }
      },
      {
        path: 'Contact',
        name: 'contactAdministrative', 
        component: Contact,
      },
      {
        path: 'Ticket',
        name: 'ticketAdministrative', 
        component: Ticket,
      },
      {
        path: 'RequestTicket',
        name: 'requestTicketAdministrative', 
        component: RequestTicket,
      },
      {
        path: 'Group',
        name: 'groupAdministrative', 
        component: Group,
      },
      {
        path: 'mantenedor-usuarios',
        name: 'maintainerUsersAdministrative', 
        component: MantainerUsers,
      },
      {
        path:'ticketReports',
        name:'ticketReportsAdministrative',
        component:TicketReports
      },
    ]
  },
]