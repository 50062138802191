






































import { StablishmentService } from "@/services/models/stablishment";
import Stablishment from "@/types/user/stablishment";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default Vue.extend({
  data() {
    return {
      search: "" as string,
      stablishment_id: 0 as number,
      stablishments: [] as Stablishment[],
      loadingStablishments: false,
      headers: [
        {
          text: "RUT",
          sortable: false,
          value: "run",
        },
        {
          text: "Nombre",
          sortable: false,
          value: "completeName",
        },
        {
          text: "Correo",
          sortable: false,
          value: "email",
        },
        {
          text: "Anexo",
          sortable: false,
          value: "phone",
        },
        {
          text: "Establecimiento",
          sortable: false,
          value: "stablishment.name",
        },
      ],
    };
  },
  mounted() {
    this.getUsers();
    this.loadStablishments();
  },
  computed: {
    ...mapGetters({
      users: "users/getUsers",
      loading: "users/getLoading",
    }),
  },
  methods: {
    ...mapMutations({
      setStablishmentId: "users/setStablishmentIdSelected",
    }),
    ...mapActions({
      getUsers: "users/getUsers",
    }),
    loadUsers() {
      this.setStablishmentId(this.stablishment_id);
      this.getUsers();
    },
    async loadStablishments() {
      this.loadingStablishments = true;
      try {
        this.stablishments.push({
          id: 0,
          name: "Todos",
        });
        let stablishmentsResponse = await StablishmentService.getAll();
        this.stablishments = [...this.stablishments, ...stablishmentsResponse];
      } catch (error) {
        console.log(error);
      }
      this.loadingStablishments = false;
    },
  },
});
