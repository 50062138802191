






import TemplateLogin from '@/templates/TemplateLogin.vue'
import Vue from 'vue'
export default Vue.extend({
  components:{    
    TemplateLogin
  }
})
