























import store from "@/store";
import Vue from "vue";
import { mapGetters } from "vuex";
export default Vue.extend({
  computed: {
    ...mapGetters({
      setConfirm: "confirm/getShowConfirm",
    }),
  },
  methods: {
    close() {
      store.commit("confirm/setConfirm", { state: false });
    },
  },
});
