





















import Vue from "vue";
import TableListPurchaseBuyer from "./TableListPurchaseBuyer.vue";
export default Vue.extend({
  components: { TableListPurchaseBuyer },
  props: {
    purchaseProp: {
      type: Object,
    },
    buyerProp:{
      type:Object
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
});
