






















import { DocumentDetailService } from "@/services/models/document/documentDetail";
import InputFile from "@/types/general/inputFile";
import myFormInterface from "@/types/myFormInterface";
import Vue from "vue";
import { mapActions } from "vuex";
export default (Vue as myFormInterface).extend({
  props: {
    documentProp: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      defaultButtonText: "Agregar Archivo" as string,
      isSelecting: false as boolean,
      selectedFile: {} as Blob,
    };
  },
  methods: {
    ...mapActions({
      getDocumentsDetail: "documentDetail/getByDocumentId",
    }),
    onButtonClick() {
      this.$refs.uploader.click();
    },
    async onFileChanged(e: InputFile) {
      //Aqui se sube el archivo del documento
      try {
        if (
          this.selectedFile.type !== "application/pdf" &&
          this.selectedFile.type !== "application/PDF"
        ) {
          this.isSelecting = true;
          this.selectedFile = e.target.files[0];
          let formData: FormData = new FormData();
          formData.append("document_id", this.documentProp.id);
          formData.append(
            "user_id",
            JSON.parse(localStorage.getItem("user") || "{}").id
          );
          formData.append("state", "disponible");
          formData.append("file", this.selectedFile);

          await DocumentDetailService.create(formData, {
            "Content-Type": "multipart/form-data",
          });
          this.selectedFile = {} as Blob;
          this.getDocumentsDetail(this.documentProp.id);
        } else {
          this.displayToast({
            type: "error",
            message: "Favor de seleccionar archivo PDF",
          });
        }
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        this.isSelecting = false;
      }
    },
  },
});
