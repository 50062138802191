




































import { DispatchLocationService } from "@/services/models/dispatch/dispatch_location";
import DispatchLocation from "@/types/dispatch/dispatch_location";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  data() {
    return {
      dialog: false,
      dispatch_location: {
        name: "" as string,
        stablishment_id: this.getCurrentUserLoggedIn()
          .stablishment_id as number,
      } as DispatchLocation,
    };
  },
  methods: {
    ...mapActions({
      setDispatchLocation: "dispatchLocation/getDispatchLocation",
    }),
    async save() {
      try {
        this.displayLoader(true);
        await DispatchLocationService.create(this.dispatch_location);
        this.setDispatchLocation(this.getCurrentUserLoggedIn().stablishment_id);
        this.dialog = false;
      } catch (error) {
        console.log(error);    
        this.displayToast({
            type: 'error',
            message: 'Error al guardar'
        });
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
