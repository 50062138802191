


















































































import myFormInterface from "@/types/myFormInterface";
import Position from "@/types/user/position";
import UserLocation from "@/types/user/userLocation";
import { PositionService } from "@/services/models/user/position";
import Vue, { PropType } from "vue";
import { LocationService } from "@/services/models/user/location";
import LocationInterface from "@/types/user/location";
import { mapActions, mapGetters } from "vuex";
import UserProfilesTableEdit from "./UserProfilesTableEdit.vue";
import { UserLocationService } from "@/services/models/user/userLocation";
import User from "@/types/user/user";
import { UserService } from "@/services/models/user/user";
const DIRECCION = 1;
const SUBDIRECCION = 2;
const DEPARTAMENTO = 3;
const UNIDAD = 4;
export default (Vue as myFormInterface).extend({
  props: {
    userProp: {
      required: true,
      type: Object as PropType<User>
    }
  },
  components: { UserProfilesTableEdit },
  data() {
    return {
      positions: [] as Position[],
      loadingPosition: false as boolean,
      position_id: 0 as number,
      locationSelectedId: 0 as number,
      direction_id: 0 as number,
      directions: [] as LocationInterface[],
      loadingDirection: false as boolean,
      subdirection_id: 0 as number,
      subdirections: [] as LocationInterface[],
      loadingSubdirection: false as boolean,
      department_id: 0 as number,
      departments: [] as LocationInterface[],
      loadingDepartment: false as boolean,
      unity_id: 0 as number,
      unities: [] as LocationInterface[],
      loadingUnity: false as boolean,
      usersLocations: [] as UserLocation[],
      loading: false as boolean,
    };
  },
  computed: {
    ...mapGetters({
      stablishmentSelected: "stablishments/getStablishmentSelected",
    }),
  },
  methods: {
    ...mapActions({
      getUsers: "users/getUsers",
    }),
    async deleteProfile(userLocation: UserLocation): Promise<void> {
      this.loading = true;
      try {
        await UserLocationService.remove(userLocation.id);
        this.loadUsersLocations();
        this.displayToast({
          type: 'success',
          message: 'El perfil se ha eliminado correctamente'
        });
        this.getUsers();
      } catch (error) {
        console.error(error);
        this.displayToast({
          type: 'error',
          message: 'Ha ocurrido un error al eliminar el perfil'
        });
      }
      this.loading = false;
    },
    getLocationSelected(): LocationInterface {
      let locationSelected: LocationInterface = {
        id: 0,
        name: "Sin locación",
        stablishment_id: 0,
        location_type_id: 0,
      };
      if (this.unity_id !== 0 && this.unity_id != -1) {
        let unitySelected = this.unities.find(
          (unity) => unity.id == this.unity_id
        );
        if (unitySelected) {
          locationSelected = unitySelected;
        }
        return locationSelected;
      }
      if (this.department_id !== 0 && this.department_id != -1) {
        let departmentSelected = this.departments.find(
          (department) => department.id == this.department_id
        );
        if (departmentSelected) {
          locationSelected = departmentSelected;
        }
        return locationSelected;
      }
      if (this.subdirection_id !== 0 && this.subdirection_id != -1) {
        let subdirectionSelected = this.subdirections.find(
          (subdirection) => subdirection.id == this.subdirection_id
        );
        if (subdirectionSelected) {
          locationSelected = subdirectionSelected;
        }
        return locationSelected;
      }
      if (this.direction_id !== 0 && this.direction_id != -1) {
        let directionSelected = this.directions.find(
          (direction) => direction.id == this.direction_id
        );
        if (directionSelected) {
          locationSelected = directionSelected;
        }
        return locationSelected;
      }
      return locationSelected;
    },
    async addUserLocation(): Promise<void> {
      try {
        const locationSelected: LocationInterface = this.getLocationSelected();
        const isLocationAdded = this.usersLocations.find(
          (userLocation) =>
            userLocation.position_id == this.position_id &&
            userLocation.location_id == locationSelected.id
        );
        if (locationSelected.id == 0) {
          this.displayToast({
            type: "warning",
            message: "Por favor seleccionar un tipo de locación",
          });
          return;
        }
        if (this.position_id == 0) {
          this.displayToast({
            type: "warning",
            message: "Por favor seleccionar cargo",
          });
          return;
        }
        if (isLocationAdded) {
          this.displayToast({
            type: "warning",
            message: "Este locación y puesto ya está asignada al usuario",
          });
          return;
        }
        this.loading = true;
        const positionSelected = this.positions.find(
          (position) => position.id == this.position_id
        );
        let userLocationResponse = await UserLocationService.create({
          id: 0,
          location_id: locationSelected.id,
          user_id: this.userProp.id,
          position_id: this.position_id,
        });
        this.displayToast({
          type: 'success',
          message: 'El perfil se ha agregado correctamente'
        });
        this.loadUsersLocations();
        this.getUsers();
        this.usersLocations.push({
          position_id: this.position_id,
          position: positionSelected,
          user_id: 0,
          location_id: locationSelected.id,
          location: locationSelected,
          id: userLocationResponse.id
        });
        this.cleanProfileData();
      } catch (error) {
        console.error(error);
        this.displayToast({
          type: 'error',
          message: 'Lo sentimos, ha ocurrido un error'
        });
      }
      this.loading = false;
    },
    cleanProfileData() {
      this.position_id = 0;
      this.direction_id = 0;
      this.subdirection_id = 0;
      this.subdirections = [];
      this.department_id = 0;
      this.departments = [];
      this.unity_id = 0;
      this.unities = [];
      this.$refs.myForm.resetValidation();
    },
    validateForm(): {
      isValid: boolean;
      message: string;
    } {
      const isValid: boolean = this.usersLocations.length != 0;
      this.$emit("loadUsersLocations", this.usersLocations);
      this.$emit("isValid", isValid);
      return {
        isValid,
        message: "Por favor asignar por lo menos un perfil",
      };
    },
    async loadPositions(): Promise<void> {
      this.loadingPosition = true;
      this.positions = await PositionService.getAll();
      this.loadingPosition = false;
    },
    async loadDirections(): Promise<void> {
      this.loadingDirection = true;
      this.directions = await LocationService.getByLocationTypeStablishment(
        DIRECCION,
        this.stablishmentSelected.id
      );
      this.subdirections = [];
      this.departments = [];
      this.unities = [];
      this.loadingDirection = false;
    },
    async loadSubdirections(): Promise<void> {
      this.loadingSubdirection = true;
      this.subdirections = [];
      let subdirectionsReponse =
        await LocationService.getByParentLocationTypeStablishment(
          SUBDIRECCION,
          this.stablishmentSelected.id,
          this.direction_id
        );
      if (subdirectionsReponse.length) {
        //En caso que no se quiera elegir subdirección
        this.subdirections = [
          {
            id: -1,
            name: "Sin subdirección",
            location_type_id: 0,
            stablishment_id: 0,
          },
          ...subdirectionsReponse,
        ];
      }
      this.subdirection_id = 0;
      this.departments = [];
      console.log(this.departments);

      this.unities = [];
      this.loadingSubdirection = false;
    },
    async loadDepartments(): Promise<void> {
      this.loadingDepartment = true;
      this.departments = [];
      let departmentsResponse =
        await LocationService.getByParentLocationTypeStablishment(
          DEPARTAMENTO,
          this.stablishmentSelected.id,
          this.subdirection_id
        );
      if (departmentsResponse.length) {
        //En caso que no se quiera elegir departament
        this.departments = [
          {
            id: -1,
            name: "Sin departamento",
            location_type_id: 0,
            stablishment_id: 0,
          },
          ...departmentsResponse,
        ];
      }
      this.department_id = 0;
      this.unities = [];
      this.loadingDepartment = false;
    },
    async loadUnities(): Promise<void> {
      this.loadingUnity = true;
      this.unities = [];
      let unitiesResponse =
        await LocationService.getByParentLocationTypeStablishment(
          UNIDAD,
          this.stablishmentSelected.id,
          this.department_id
        );
      if (unitiesResponse.length) {
        //En caso que no se quiera elegir unity
        this.unities = [
          {
            id: -1,
            name: "Sin unidad",
            location_type_id: 0,
            stablishment_id: 0,
          },
          ...unitiesResponse,
        ];
      }
      this.unity_id = 0;
      this.loadingUnity = false;
    },
    async loadUsersLocations() {
      let response = await UserService.getById(this.userProp.id);
      if (response.users_locations) {
        this.usersLocations = response.users_locations;
      }
    }
  },
  async mounted() {
    this.loadPositions();
    this.loadDirections();
    if (this.userProp.users_locations) {
      this.usersLocations = this.userProp.users_locations;
    }
  },
  watch: {
    stablishmentSelected() {
      this.loadDirections();
    },
  },
});
