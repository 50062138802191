


















import Vue from "vue";
import MyFormInterface from "@/types/myFormInterface";
import { TicketFileService } from "@/services/models/ticket/ticketFile";
export default (Vue as MyFormInterface).extend({
	name: "AddFileToTicket",
	props: {
		ticketId: {
			type: Number,
			required: true
		},
		loadTickets: {
			type: Function,
			required: true
		}
	},
  data() {
    return {
      loading: false,
      file: null as unknown as File,
    };
  },
  methods: {
    async storeFile(): Promise<void> {
			this.loading = true;
      let formData = new FormData();
      formData.append("ticket_id", this.ticketId.toString());
      formData.append("file", this.file);
      formData.append("user_id", this.getCurrentUserLoggedIn().id.toString());
      await TicketFileService.create(formData, {
        "Content-Type": "multipart/form-data",
      });
			this.loadTickets();
			this.$emit('changeDialog', false);
			this.loading = false;
    },
  },
});
