import { CdpService } from "@/services/models/purchaseRequest/cdp";
import Cdp from "@/types/purchaseRequest/cdp";
import ResponsePaginated from "@/types/responsePaginated";
import { Commit } from "vuex";

interface stateInterface {
    cdp: Cdp[],
    cdpStateUser:ResponsePaginated<Cdp[]>

    loading: boolean,
}
const moduleCdp = {
    namespaced: true,
    state: {
        cdp: [],
        cdpStateUser:{
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        loading: false,
    } as stateInterface,
    getters: {
        getCdp(state: stateInterface): Cdp[] {
            return state.cdp;
        },
        getCdpStateUser(state:stateInterface): ResponsePaginated<Cdp[]>{
            return state.cdpStateUser
        },
        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setCdp(state: stateInterface, cdp: Cdp[]): void {
            state.cdp = cdp;
        },

        setCdpStateUser(state:stateInterface,cdpStateUser:ResponsePaginated<Cdp[]>):void{
            state.cdpStateUser=cdpStateUser
        },
        setPageCdpStateUser(state: stateInterface, page: number): void {
            state.cdpStateUser.current_page = page;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getCdp({ commit }: { commit: Commit }, id: number): Promise<void> {
            commit('setLoading', true);
            const CDP = await CdpService.getPurchaseBuyerId(id);
            commit("setCdp", CDP);
            commit('setLoading', false);
        },
        async getCdpStateUser({ commit,state }: { commit: Commit,state:stateInterface }, payLoad:{state:string,user:number}): Promise<void>{
            commit('setLoading', true);
            const CDP = await CdpService.getByStateUser(payLoad,state.cdpStateUser.current_page);
            commit("setCdpStateUser", CDP);
            commit('setLoading', false);
        }
    }
}

export default moduleCdp;