





























































































import { UserService } from "@/services/models/user/user";
import myFormInterface from "@/types/myFormInterface";
import User from "@/types/user/user";
import Vue from "vue";
import { mapActions } from "vuex";
import Avatar from "./Avatar.vue";
export default (Vue as myFormInterface).extend({
  components: { Avatar },
  props: {
    allowEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      user: {} as User,
    };
  },
  methods: {
    ...mapActions({
      setUser: "users/getUser",
    }),
    async save() {
      if (this.$refs.myForm.validate()) {
        try {
          this.isLoading = true;
          let dataUser: User = await UserService.getById(
            this.getCurrentUserLoggedIn().id
          );
          let data: User = {
            ...dataUser,
            name: this.user.name,
            pather_lastname: this.user.pather_lastname,
            mother_lastname: this.user.mother_lastname,
            phone:this.user.phone,
          };
          await UserService.update(this.user.id, data);
          this.setUser(this.user.id);
          this.dialog = false;
        } catch (error) {
          console.log(error);
          throw error;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.cleanForm(this, "myForm");
        this.user = await UserService.getById(this.getCurrentUserLoggedIn().id);
      }
    },
  },
});
