



































import Vue from "vue";
import AddDocumentDetail from "./AddDocumentDetail.vue";
import CreateTemplateDocument from "./createTemplateDocument/CreateTemplateDocument.vue";
export default Vue.extend({
  components: { AddDocumentDetail, CreateTemplateDocument },
  props: {
    documentProp: {
      required: true,
    },
  },
  data() {
    return {
      menu: false as boolean,
    };
  },
});
