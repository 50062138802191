















































































import { DocumentTypeService } from "@/services/models/document/document_type";
import { StablishmentService } from "@/services/models/stablishment";
import { LocationService } from "@/services/models/user/location";
import DocumentType from "@/types/documents/documentType";
import Stablishment from "@/types/user/stablishment";
import LocationInterface from "@/types/user/location";
import Vue from "vue";
import InputSelectUsers from "../ShowDocument/panelDetail/InputSelectUsers.vue";
import myFormInterface from "@/types/myFormInterface";

export default (Vue as myFormInterface).extend({
  components: { InputSelectUsers },
  data() {
    return {
      menu2: false,
      stablishments: [] as Stablishment[],
      locations: [] as LocationInterface[],
      documentTypes: [] as DocumentType[],

      document: {
        id: null,
        number: "" as string,
        description: "" as string,
        document_type_id: null || (0 as number),
        location_id: null || (0 as number),
        user_id: 0,
        created_at: "",
      },
      stablishmentSelected: null || (0 as number),
      date: "",
    };
  },
  mounted() {
    this.loadData();
  },
  watch:{
    stablishmentSelected(){
      this.change()
    }
  },
  methods: {
    getUserSelected() {
      return this.$refs.selectFunctionary.usersSelects[0]?.id || null;
    },
    async loadData() {
      this.displayLoader(true);
      this.stablishments = await StablishmentService.getAll();
      this.stablishmentSelected = this.getCurrentUserLoggedIn().stablishment_id

      this.documentTypes.push({
        name: "Sin Seleccionar",
        id:0
      });
      this.documentTypes.push(
        ...await DocumentTypeService.getAll()
      );
      this.document.document_type_id = this.documentTypes[0].id || 0;

      this.locations.push({
        name: "Sin Seleccionar",
        id: 0,
        location_type_id: 1,
        stablishment_id: 1,
      });
      this.locations.push(
        ...(await LocationService.getByStablishmentId(
          this.stablishmentSelected
        ))
      );

      this.document.location_id = this.locations[0].id || 0;
      this.displayLoader(false);
    },
    async change(){
      this.locations=[];
      this.locations.push({
        name: "Sin Seleccionar",
        id: 0,
        location_type_id: 1,
        stablishment_id: 1,
      });
      this.locations.push(
        ...(await LocationService.getByStablishmentId(
          this.stablishmentSelected
        ))
      );
    }
  },
});
