


























import User from "@/types/user/user";
import Vue, { PropType } from "vue";
import ResetPasswordContent from "./ResetPasswordContent.vue";
export default Vue.extend({
  props: {
    user: {
      required: true,
      type: Object as PropType<User>,
    },
  },
  components: { ResetPasswordContent },
  data() {
    return {
      dialog: false,
    };
  },
});
