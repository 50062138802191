

























































import Module from "@/types/user/module";
import { ModuleService } from "@/services/models/user/module";
import Vue from "vue";
import UserModule from "@/types/user/userModule";
import Permission from "@/types/user/permission";
import myFormInterface from "@/types/myFormInterface";
import { UserModulePermissionService } from "@/services/models/user/userModulePermission";
import { UserModuleService } from "@/services/models/user/userModule";
import { PermissionService } from "@/services/models/user/permission";
import UserModulePermission from "@/types/user/userModulePermission";
export default (Vue as myFormInterface).extend({
  name: "UserModules",
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      modules: [] as Module[],
      loadingModule: false as boolean,
      usersModules: [] as any[],
      usersModulesOriginal: [] as any[],
      module_id: 0 as number,
      headers: [
        {
          text: "Módulo",
          sortable: false,
          value: "module.name",
        },
        {
          text: "Permisos",
          sortable: false,
          value: "permission",
        },
        {
          text: "Eliminar",
          sortable: false,
          value: "delete",
        },
      ],
      loading: false,
      userModulesLoaded: false,
      loadingModal: false
    };
  },
  async mounted() {
    this.loadingModule = true;
    this.modules = await ModuleService.getAll();
    this.getUserModules();
    this.loadingModule = false;
  },
  methods: {
    async changePermission(asd: any) {
      this.loadingModal = true;
      await this.addPermissions();
      await this.deletePermissions();
      await this.getUserModules();
      this.loadingModal = false;
    },
    async addPermissions() {
      for (let i = 0; i < this.usersModules.length; i++) {
        for (
          let j = 0;
          j < this.usersModules[i].permissions.length;
          j++
        ) {
          const userModulePermission =
            this.usersModules[i].permissions[j];
          const isPermissionAdded = this.usersModules[
            i
          ].users_modules_permissions
            .map((val: UserModulePermission) => val.permission_id)
            .includes(userModulePermission.id);
            console.log(isPermissionAdded);
            
          if (!isPermissionAdded) {
            await UserModulePermissionService.create({
              id: 0,
              user_module_id: this.usersModules[i].id,
              permission_id: userModulePermission.id
            });
          }
        }
      }
    },
    async deletePermissions() {
      for (let i = 0; i < this.usersModules.length; i++) {
        for (let j = 0; j < this.usersModules[i].users_modules_permissions.length; j++) {
          const userModulePermission = this.usersModules[i].users_modules_permissions[j];
          const isPermissionAdded = this.usersModules[
            i
          ].permissions
            .map((val: Permission) => val.id)
            .includes(userModulePermission.permission_id);
          if (!isPermissionAdded) {
            console.log(userModulePermission);
            await UserModulePermissionService.remove(userModulePermission.id);
          }
        }
      }
    },
    async getUserModules() {
      this.userModulesLoaded = false;
      this.usersModules = await UserModuleService.getByUserId(this.userId);
      for (let i = 0; i < this.usersModules.length; i++) {
        const userModule = this.usersModules[i];
        const permissions = await PermissionService.getByModuleId(
          userModule.module_id
        );
        if (this.usersModules[i].module) {
          this.usersModules[i].module.permissionsList = permissions;
          this.usersModules[i].permissions = this.usersModules[
            i
          ].module.permissionsList.filter((value: Permission) =>
            this.usersModules[i].users_modules_permissions
              .map((val: UserModulePermission) => val.permission_id)
              .includes(value.id)
          );
        }
      }
      this.usersModulesOriginal = this.usersModules;
      this.userModulesLoaded = true;
    },
    async deleteModule(userModule: UserModule) {
      this.loadingModal = true;
      if (userModule.users_modules_permissions) {
        for (let i = 0; i < userModule.users_modules_permissions.length; i++) {
          const userModulePermission = userModule.users_modules_permissions[i];
          await UserModulePermissionService.remove(userModulePermission.id);
        }
      }
      await UserModuleService.remove(userModule.id);
      this.getUserModules();
      this.loadingModal = false;
    },
    addModule() {
      if (!this.module_id) {
        this.displayToast({
          type: "warning",
          message: "Por favor seleccionar un módulo",
        });
        return;
      }
      const isModuleAdded = this.usersModules.find(
        (userModule) => userModule.module_id == this.module_id
      );
      if (isModuleAdded) {
        this.displayToast({
          type: "warning",
          message: "El usuario ya tiene asociado este módulo",
        });
        return;
      }
      this.storeModule();
    },
    async storeModule() {
      this.loadingModal = true;
      await UserModuleService.create({
        id: 0,
        name: "test",
        module_id: this.module_id,
        user_id: this.userId,
      });
      this.getUserModules();
      this.loadingModal = false;
    },
  },
});
