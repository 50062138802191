

















import Cdp from "@/types/purchaseRequest/cdp";
import Vue from "vue";
import ButtonAddCdp from "./ButtonAddCdp.vue";
export default Vue.extend({
  components: { ButtonAddCdp },
  props: {
    purchaseProp: {
      type: Object,
    },
  },
  data() {
    return {
      data: [] as Array<Cdp>,
      headers: [
        {
          text: "Numero de solicitud",
          sortable: false,
          value: "n_request",
        },
        {
          text: "Eliminar",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  methods: {
    addCdp(data: Cdp) {
      this.data.push(data);
    },
    remove(data: Cdp) {
      this.data = this.data.filter((item) => item != data);
    },
    validateForm() {
      if (this.data.length == 0) return false;
      return true;
    },
  },
});
