































































































































































































































































































import Vue from "vue";
import {
  DataLogin,
  DataLoginReturn,
  UserService,
} from "@/services/models/user/user";
import { AxiosResponse } from "axios";
import Loader from "@/components/customComponents/Loader.vue";
import Toast from "@/components/customComponents/Toast.vue";
import { formatRut } from "@/thirdParty/utils";
import { mapMutations } from "vuex";
import store from "@/store";

export default Vue.extend({
  components: { Loader, Toast },
  props: {
    bgColor: {
      type: String,
      default: "red",
    },
    fgColor: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      step: 1 as number,
      username: "" as string,
      email: "" as string,
      password: "" as string,
      rut: "" as string,
      login: "" as string,
      snackbar: false as boolean,
      items: ["DSSM", "HCM"],
      loading: false as boolean,
    };
  },
  beforeCreate: function () {
    document.body.className = "intro";
  },
  methods: {
    ...mapMutations({
      setProfile: "userSession/setProfile",
      setCurrentLocation: "userSession/setCurrentLocation",
    }),
    formatRut() {
      this.login = formatRut(this.login);
    },
    async connect() {      
      this.displayLoader(true)
      let data: DataLogin = {
        run: this.login.replaceAll(".",""),
        password: this.password,
        grant_type: "password",
      };
      try {
        store.commit('relogin/resetRelogin');
        let response: AxiosResponse<DataLoginReturn> = await UserService.login(
          data
        );
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("perfil", response.data.user.role?.name || "");
        this.setProfile(response.data.user.role?.name);
        if (response.data.user.users_locations) {
          this.setCurrentLocation(response.data.user.users_locations[0]);
        }
        store.commit("userSession/setUserModules");
        store.commit("userSession/setUserPermissions");
        switch (response.data.user.role_id) {
          case 1: //Administrator
            this.$router.push({ name: "administratorHome" });
            break;
          case 2: //Administrativo
            this.$router.push({ name: "administrativeHome" });
            break;
          case 3: //Opartes
            this.$router.push({ name: "opartesHome" });
            break;
          default:
            break;
        }        
      } catch (error: any) {   
        if (error.message == "Request failed with status code 401") {
          this.displayToast({
            type: 'warning',
            message: 'Las credenciales ingresadas son incorrectas'
          });
        } else {
          this.displayToast({
            type: 'error',
            message: 'Ha ocurrido un error al ingresar, favor volver a intentar'
          });
        }
      } finally {
        this.displayLoader(false)
      }
    },
  },
});
