import EmptyRouterView from '@/views/EmptyRouterView.vue';
import Home from '@/views/administrator/Home.vue';
import MantainerUsers from '@/views/administrator/MaintainerUsers.vue';
import Document from '@/views/administrator/Document.vue';
import Folder from '@/views/administrator/Folder.vue'
import Contact from '@/views/administrator/Contact.vue'
import Ticket from '@/views/administrator/Ticket.vue'
import RequestTicket from '@/views/administrator/RequestTickets.vue'
import Group from '@/views/administrator/Group.vue'
import PurchaseRequest from '@/views/administrator/purchaseRequest/PurchaseRequest.vue';
import Authorizing from '@/views/administrator/purchaseRequest/Authorizing.vue';
import SupplyManager from '@/views/administrator/purchaseRequest/SupplyManager.vue';
import UnityManager from '@/views/administrator/purchaseRequest/UnityManager.vue';
import Buyer from '@/views/administrator/purchaseRequest/Buyer.vue'
import BudgetManager from '@/views/administrator/purchaseRequest/BudgetManager.vue';
import BudgetStaff from '@/views/administrator/purchaseRequest/BudgetStaff.vue';
import FinancialManager from '@/views/administrator/purchaseRequest/FinancialManager.vue';
import TicketReports from '@/views/administrator/TicketReports.vue';

export const routes = [
  { path: '/administrador',
    component: EmptyRouterView,
    children: [
      {
        path: 'inicio',
        name: 'administratorHome', 
        component: Home,
      },
      {
        path: 'mantenedor-usuarios',
        name: 'maintainerUsersAdministrator', 
        component: MantainerUsers,
      },
      {
        path: 'documentos',
        name: 'documentAdministrator', 
        component: Document,
      },
      {
        path: 'carpeta/:userID/:folderName/:id',
        name: 'folderAdministrator', 
        component: Folder,
        // Para verificar que la persona que entre a la ruta de la carpeta sea la correcta
        /* eslint-disable */
        beforeEnter(to:any, from:any, next:any) {
          console.log('to:',to,'from',from,'next',next);
          if(to.params.userID==JSON.parse(window.localStorage.getItem("user") || '{}').id){            
            next();
          }else{
            alert('No esta autorizado para ingresar a esta ruta');
            next('inicio')
          }
        }
        /* eslint-enable */
      },
      {
        path: 'Contact',
        name: 'contactAdministrator', 
        component: Contact,
      },

      //RUTA TICKETS
      {
        path: 'Ticket',
        name: 'ticketAdministrator', 
        component: Ticket,
      },
      {
        path: 'RequestTicket',
        name: 'requestTicketAdministrator', 
        component: RequestTicket,
      },
      {
        path: 'Group',
        name: 'groupAdministrator', 
        component: Group,
      },

      //RUTA SOLICITUDES DE COMPRAS
      {
        path: 'purchaseRequest',
        name: 'purchaseRequestAdministrator', 
        component: PurchaseRequest,
      },
      {
        path: 'Authorizing',
        name: 'authorizingAdministrator', 
        component: Authorizing,
      },
      {
        path: 'SupplyManager',
        name: 'supplyManagerAdministrator', 
        component: SupplyManager,
      },
      {
        path: 'UnityManager',
        name: 'unityManagerAdministrator', 
        component: UnityManager,
      },
      {
        path:'Buyer',
        name:'buyerAdministrator',
        component:Buyer
      },
      {
        path:'BudgetManager',
        name:'budgetManagerAdministrator',
        component:BudgetManager
      },
      {
        path:'BudgetStaff',
        name:'budgetStaffAdministrator',
        component:BudgetStaff
      },
      {
        path:'FinancialManager',
        name:'financialManagerAdministrator',
        component:FinancialManager
      },
      {
        path:'ticketReports',
        name:'ticketReportsAdministrator',
        component:TicketReports
      },
    ]
  },
]