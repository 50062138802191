
















































import { generatePDFTickets } from "@/reports/reportTickets";
import { TicketService } from "@/services/models/ticket/ticket";
import Ticket from "@/types/tickets/tickets";
import moment from "moment";
import Vue from "vue";
export default Vue.extend({
	props: {
		isUser: {
			type: Boolean,
			default: false
		}
	},
  data() {
    return {
      dialog: false,
      isLoading: false,
      dateStart: null,
      dateEnd: null,
    };
  },
  methods: {
    async generateReport() {
      if (!this.dateStart && !this.dateEnd) {
        this.displayToast({
          type: "warning",
          message: "Por favor seleccionar fecha de inicio y fecha de término",
        });
        return;
      }
      let start = moment(this.dateStart).format("YYYY-MM-DD");
      let end = moment(this.dateEnd).format("YYYY-MM-DD");
      this.isLoading = true;
			let tickets: Ticket[];
			if (this.isUser) {
				tickets = await TicketService.getByDateAndUser(
					`${start} 00:00:00`,
					`${end} 23:59:59`,
					this.getCurrentUserLoggedIn().id
				);
			} else {
				tickets = await TicketService.getByDateAndGroup(
					`${start} 00:00:00`,
					`${end} 23:59:59`,
					1
				);
			}
      generatePDFTickets({
        tickets: tickets,
        dateStart: start,
        dateEnd: end,
        user: this.getCurrentUserLoggedIn(),
        ...(!this.isUser && {userGroup: this.getUserGroup()}),
      });
      this.isLoading = false;
    },
  },
});
