import { TicketService } from "@/services/models/ticket/ticket";
import ResponsePaginated from "@/types/responsePaginated";
import Ticket, { statesTickets } from "@/types/tickets/tickets";
import { Commit } from "vuex";

interface stateInterface {
    Ticket: Ticket[],
    ticketUser: ResponsePaginated<Ticket[]>,
    ticketGroup: ResponsePaginated<Ticket[]>,
    ticketFilter: ResponsePaginated<Ticket[]>,
    dataFilterTicket:dataFilterTicket,
    loading: boolean,
}
const moduleTickets = {
    namespaced: true,
    state: {
        Ticket: [],
        ticketUser: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        ticketGroup: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        ticketFilter: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        dataFilterTicket:{
            id:null,
            responsable_id:null,
            user_id:null,
            state:null
        },
        loading: false,
    } as stateInterface,
    getters: {
        getTickets(state: stateInterface): Ticket[] {
            return state.Ticket;
        },

        getTicketsUser(state: stateInterface): ResponsePaginated<Ticket[]> {
            return state.ticketUser
        },
        getTicketGroup(state: stateInterface): ResponsePaginated<Ticket[]> {
            return state.ticketGroup
        },
        getTicketFilter(state: stateInterface): ResponsePaginated<Ticket[]> {
            return state.ticketFilter
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        },
    },
    mutations: {
        setTickets(state: stateInterface, Ticket: Ticket[]): void {
            state.Ticket = Ticket;
        },

        setTicketUser(state: stateInterface, ticketUser: ResponsePaginated<Ticket[]>): void {
            state.ticketUser = ticketUser;
        },
        setPageTicketUser(state: stateInterface, page: number): void {
            state.ticketUser.current_page = page;
        },

        setTicketGroup(state: stateInterface, ticketGroup: ResponsePaginated<Ticket[]>): void {
            state.ticketGroup = ticketGroup;
        },
        setPageTicketGroup(state: stateInterface, page: number): void {
            state.ticketGroup.current_page = page;
        },

        setTickeFilter(state: stateInterface, ticketFilter: ResponsePaginated<Ticket[]>): void {
            state.ticketFilter = ticketFilter;
        },
        setPageTicketFilter(state: stateInterface, page: number): void {
            state.ticketFilter.current_page = page;
        },
        
        setDataFilterTicket(state: stateInterface, dataFilter: dataFilterTicket): void {
            state.dataFilterTicket = dataFilter;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        },
    },
    actions: {
        async getTickets({ commit }: { commit: Commit }): Promise<void> {
            commit('setLoading', true);
            const TICKET = await TicketService.getAll();
            commit("setTickets", TICKET);
            commit('setLoading', false);
        },
        async getTicketsUser({ commit, state }: { commit: Commit, state: stateInterface }, user_id: number): Promise<void> {
            commit('setLoading', true);
            const TICKET = await TicketService.getByUserId(user_id, state.ticketUser.current_page);
            commit("setTicketUser", TICKET);
            commit('setLoading', false);
        },
        async getTicketGroup({ commit, state }: { commit: Commit, state: stateInterface }, group_id: number): Promise<void> {
            commit('setLoading', true);
            const TICKET = await TicketService.getByGroupId(group_id, state.ticketGroup.current_page);
            commit("setTicketGroup", TICKET);
            commit('setLoading', false);
        },
        async getTicketFilter({ commit, state }: { commit: Commit, state: stateInterface }): Promise<void> {
            commit('setLoading', true);
            const TICKET = await TicketService.getByTicketFilter(state.dataFilterTicket, state.ticketFilter.current_page);
            commit("setTickeFilter", TICKET);
            commit('setLoading', false);
        }
    }
}

export interface dataFilterTicket {
    id?: number | null,
    responsable_id?: number | null,
    user_id?: number | null,
    state?: statesTickets | null
}
export default moduleTickets;
