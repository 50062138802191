


























































import GroupButtons from "@/components/purchaseRequest/tables/buttonsPurchaseRequest/GroupButtons.vue";
import DetailTablePurchaseRequest from '@/components/purchaseRequest/tables/DetailTablePurchaseRequest.vue';
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  components:{
    GroupButtons,
    DetailTablePurchaseRequest
  },
  data() {
    return {
      page: 1,
      headers:[
        {
          text:'Id Solicitud',
          sortable:false,
          value:'purchase_request_id'
        },
        {
          text:'Id del Registro',
          sortable:false,
          value:'id'
        },
        {
          text:'Persona que lo asigno',
          sortable:false,
          value:'user_id'
        },
        {
          text:'Estado',
          sortable:false,
          value:'state'
        },
        {
          text:'Acciones',
          sortable:false,
          value:'actions'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getBuyers: "buyer/getBuyerPending",
      getLoading: "buyer/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setBuyer: "buyer/getBuyerPending",
    }),
    changePage() {
      this.$store.commit("buyer/setPageBuyerPending", this.page);
      this.setBuyer({userId:this.getCurrentUserLoggedIn().id,state:'pendiente'});
    },
  },
  mounted() {
    this.changePage();
  },
});
