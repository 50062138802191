import UserModule from '@/types/user/userModule'
import { BaseService } from './../../base'
export class UserModuleService extends BaseService<UserModule>() {
    static get entity (): string {
        return 'user_module'
    }
    static get responseName (): string {
        return 'user_module'
    }

    static async getByUserId(userId: number): Promise<UserModule[]> {
        return this.submit<UserModule[]>({
            method: 'get',
            path: `find_by_user_id/${userId}`
        });
    }
}