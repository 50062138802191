
























































import FormFiles from "@/components/general/FormFiles.vue";
import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import myFormInterface from "@/types/myFormInterface";
import TableCdps from "./cdpForm/TableCdpsSaves.vue";
import FormPurchaseBuyer from "./FormPurchaseBuyer.vue";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
import { CdpArticleService } from "@/services/models/purchaseRequest/cdpArticle";

import CdpArticle from "@/types/purchaseRequest/cdpArticle";
import Vue from "vue";
import PurchaseBuyer from "@/types/purchaseRequest/purchaseBuyer";
import { PurchaseBuyerFileService } from "@/services/models/purchaseRequest/purchaseBuyerFile";
import PurchaseBuyerHistory from "@/types/purchaseRequest/purchaseBuyerHistory";
import { PurchaseBuyerHistoryService } from "@/services/models/purchaseRequest/purchaseBuyerHistory";
import PurchaseBuyerState from "@/types/purchaseRequest/purchaseBuyerState";
import { PurchaseBuyerStateService } from "@/services/models/purchaseRequest/purchaseBuyerState";
import Cdp from "@/types/purchaseRequest/cdp";
import { mapActions } from "vuex";
import { generatePdfCdp } from "@/reports/reportCdp";
import { BuyerService } from "@/services/models/purchaseRequest/buyer";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import PurchaseHistory from "@/types/purchaseRequest/purchaseHistory";
import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";
export default (Vue as myFormInterface).extend({
  components: { FormPurchaseBuyer, FormFiles, TableCdps },
  props: {
    purchaseProp: {
      type: Object,
    },
    buyerProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      step: 1,
      invalidStep: 0 as number,
    };
  },
  watch: {
    invalidStep() {
      if (this.invalidStep != 0) {
        this.step = this.invalidStep;
      }
    },
  },
  methods: {
    ...mapActions({
      setBuyerPending: "buyer/getBuyerPending",
      setBuyerInProcess: "buyer/getBuyerInProcess",
      setBuyerReady: "buyer/getBuyerReady",
      setPurchaseBuyer: "purchaseBuyer/getPurchaseBuyer",
    }),
    validateStep(isValid: boolean, step: number): void {
      if (!isValid) {
        this.invalidStep = step;
      }
    },
    async save() {
      this.displayLoader(true);
      const FORMPURCHASEBUYER = this.$refs.formPurchaseBuyer;
      const TABLECDP = this.$refs.tableCdps;

      // Se valida que los formularios este con todos los campos correspondientes
      if (!FORMPURCHASEBUYER.validateForm())
        return this.validateStep(false, 1), this.displayLoader(false);
      if (!TABLECDP.validateForm())
        return this.validateStep(false, 2), this.displayLoader(false);

      try {
        let response = await PurchaseBuyerService.create(
          FORMPURCHASEBUYER.data as PurchaseBuyer
        );
        let cdps = TABLECDP.data as Array<Cdp>;
        await this.postCdp(response, cdps);
        await this.postPurchaseBuyerFile(response);
        await this.postPurchaseBuyerHistory(response);
        await this.postPurchaseBuyerState(response);
        await this.updateBuyer();
        await this.postPurchaseHistory(this.purchaseProp,response)
        this.reload();
        this.displayToast({
          type: "success",
          message: "Registro ingresado",
        });
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
    async postPurchaseBuyerFile(purchaseBuyer: PurchaseBuyer) {
      const formFiles = this.$refs.formFiles;
      if (formFiles && formFiles.files.length != 0) {
        let promise = [];
        for (let i = 0; i < formFiles.files.length; i++) {
          promise.push(
            new Promise((resolve, reject) => {
              let formData = new FormData();
              formData.append("purchase_buyer_id", `${purchaseBuyer.id}`);
              formData.append("file", formFiles.files[i]);
              PurchaseBuyerFileService.create(formData, {
                "Content-Type": "multipart/form-data",
              })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            })
          );
        }
        Promise.all(promise)
          .then(() => {
            return;
          })
          .catch((reason) => {
            console.log(reason);
          });
      }
    },
    async postPurchaseBuyerHistory(purchaseBuyer: PurchaseBuyer) {
      let data: PurchaseBuyerHistory = {
        id: 0,
        state: "creado",
        user_origin_id: this.getCurrentUserLoggedIn().id,
        user_destination_id: this.getCurrentUserLoggedIn().id,
        purchase_buyer_id: purchaseBuyer.id,
      };
      await PurchaseBuyerHistoryService.create(data);
    },
    async postPurchaseBuyerState(purchaseBuyer: PurchaseBuyer) {
      let data: PurchaseBuyerState = {
        id: 0,
        purchase_buyer_id: purchaseBuyer.id,
        state: "creado",
        step: "comprador",
        user_id: this.getCurrentUserLoggedIn().id,
        user_location_id: this.getUserLocation().location_id,
      };
      await PurchaseBuyerStateService.create(data);
    },
    async postCdp(purchaseBuyer: PurchaseBuyer, cdps: Cdp[]) {
      for (let i = 0; i < cdps.length; i++) {
        let responseCdp = await CdpService.create({
          ...cdps[i],
          purchase_buyer_id: purchaseBuyer.id,
        });
        let purchase_request_detail = cdps[i].cdps_articles;
        console.log("asd", purchase_request_detail[0].article);

        for (let x = 0; x < purchase_request_detail.length; x++) {
          console.log(purchase_request_detail[x]);

          let data: CdpArticle = {
            id: 0,
            cdp_id: responseCdp.id,
            purchase_request_detail_id: purchase_request_detail[x].id,
            budget_item_id:
              purchase_request_detail[x].article?.budget_item_id || 0,
            obervation: "",
          };
          await CdpArticleService.create(data);
        }
        let base64 = await generatePdfCdp(responseCdp, false);
        await CdpService.uploadImageCdpFile({
          base64: base64 as string,
          id: responseCdp.id,
          cdp_name: responseCdp.id.toString(),
        });
      }
    },
    async updateBuyer() {
      if (this.buyerProp.state == "pendiente") {
        await BuyerService.update(this.buyerProp.id, {
          ...this.buyerProp,
          state: "en proceso",
        });
      }
    },
    async reload() {
      this.setBuyerPending({
        userId: this.getCurrentUserLoggedIn().id,
        state: "pendiente",
      });
      this.setBuyerInProcess({
        userId: this.getCurrentUserLoggedIn().id,
        state: "en proceso",
      });
      this.setBuyerReady({
        userId: this.getCurrentUserLoggedIn().id,
        state: "finalizado",
      });
      this.setPurchaseBuyer(this.purchaseProp.id);
    },
    async postPurchaseHistory(purchaseRequest: PurchaseRequest,purchaseBuyer:PurchaseBuyer) {
      let dataPurchaseHistory: PurchaseHistory = {
        id: 0,
        purchase_request_id: purchaseRequest.id,
        state: `registro de compra ID ${purchaseBuyer.id} creado`,
        user_origin_id: this.getCurrentUserLoggedIn().id,
        user_destination_id: this.getCurrentUserLoggedIn().id,
      };
      await PurchaseHistoryService.create(dataPurchaseHistory);
    },
  },
});
