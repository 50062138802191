import Document from '@/types/documents/document'
import ResponsePaginated from '@/types/responsePaginated';
import { BaseService } from '../../base'
export class DocumentService extends BaseService<Document>() {
    static get entity() :string{
        return 'document'
    }
    static get responseName():string {
        return 'document'
    }

    /**
     * Api que entrega los documentos creados por el usuario
     * 
     * @param user_id id usuario logeado
     * @param page n° de pagina
     * @returns 
     */
    static async getUserId(data: any, page: number): Promise<ResponsePaginated<Document[]>> {
        return this.submit<ResponsePaginated<Document[]>>({
            method: 'post',
            path: `find_by_user_id?page=${page}`,
            data: data
        });
    }

    /**
     * Api que entrega los documentos creados por la locación
     * 
     * @param location_id id usuario logeado
     * @param page n° de pagina
     * @returns 
     */
     static async getByUserLocationId(data: any, page: number): Promise<ResponsePaginated<Document[]>> {
        return this.submit<ResponsePaginated<Document[]>>({
            method: 'post',
            path: `find_by_location_id?page=${page}`,
            data: data
        });
    }

    /**
     * Api que entrega los documentos que ha recibido el usuario (que no hayan sido enviados ni para visar ni para firmar)
     * 
     * @param user_id id usuario logeado
     * @param page n° de pagina
     * @returns 
     */
    static async getRequestId(data: any, page: number): Promise<ResponsePaginated<Document[]>> {
        return this.submit<ResponsePaginated<Document[]>>({
            method: 'post',
            path: `find_by_request?page=${page}`,
            data: data
        });
    }

    /**
     * Api que entrega los documentos que ha recibido la locación (que no hayan sido enviados ni para visar ni para firmar)
     * 
     * @param user_id id usuario logeado
     * @param page n° de pagina
     * @returns 
     */
     static async getRequestedDocumentsByLocationId(data: any, page: number): Promise<ResponsePaginated<Document[]>> {
        return this.submit<ResponsePaginated<Document[]>>({
            method: 'post',
            path: `find_by_request_location?page=${page}`,
            data: data
        });
    }

    /**
     * 
     * @param user_id id usuario logeado
     * @param state estado del document_detail_checked del usuario
     * @param page n° de pagina
     * @returns 
     */
    static async getByCheckedState(user_id: number, state: string, page: number): Promise<ResponsePaginated<Document[]>> {
        return this.submit<ResponsePaginated<Document[]>>({
            method: 'get',
            path: `find_by_checked_state/${user_id}/${state}?page=${page}`
        });
    }

    /**
     * 
     * @param user_id id usuario logeado
     * @param state estado del document_detail_firmed del usuario
     * @param page n° de pagina
     * @returns 
     */
    static async getByFirmedState(user_id: number, state: string, page: number): Promise<ResponsePaginated<Document[]>> {
        return this.submit<ResponsePaginated<Document[]>>({
            method: 'get',
            path: `find_by_firmed_state/${user_id}/${state}?page=${page}`
        });
    }

    /**
     * 
     * @param user_id id usuario logeado
     * @param dataSearch data con los datos a buscar
     * @param page n° de pagina
     * @returns 
     */
    static async getByAllDocument(user_id: number, dataSearch: Record<string, unknown>, page: number): Promise<ResponsePaginated<Document[]>> {
        return this.submit<ResponsePaginated<Document[]>>({
            method: 'post',
            path: `find_by_document_all/${user_id}?page=${page}`,
            data: dataSearch
        });
    }

    /**
     * 
     * @param location_id id de la locación del usuario logeado
     * @param dataSearch data con los datos a buscar
     * @param page n° de pagina
     * @returns 
     */
     static async getByAllDocumentLocation(location_id: number, dataSearch: Record<string, unknown>, page: number): Promise<ResponsePaginated<Document[]>> {
        return this.submit<ResponsePaginated<Document[]>>({
            method: 'post',
            path: `find_by_document_all_location_id/${location_id}?page=${page}`,
            data: dataSearch
        });
    }


    static async getByFolderId(payLoad: {
        user_id: number,
        folder_id: number
    }, page: number): Promise<ResponsePaginated<Document[]>> {
        return this.submit<ResponsePaginated<Document[]>>({
            method: 'get',
            path: `find_by_folder_id/${payLoad.user_id}/${payLoad.folder_id}?page=${page}`,
        });
    }
}
// export interface dataSearch {
//     id: null | number,
//     number: null | string,
//     description: null | string,
//     document_type_id: null | number
//     location_id: null | number,
//     user_id: null | number,
//     created_at: null | string,
// }