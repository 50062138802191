






























import Vue from "vue";
import { PurchaseRequestTypeService } from "@/services/models/purchaseRequest/purchaseRequestType";
import PurchaseRequestType from "@/types/purchaseRequest/purchaseRequestType";
import myFormInterface from "@/types/myFormInterface";
export default (Vue as myFormInterface).extend({
  data() {
    return {
      purchaseRequestType: [] as Array<PurchaseRequestType>,
      data: {
        user_id: this.getCurrentUserLoggedIn().id,
        purchase_request_type_id: 0 as number,
        description: "" as string,
        estimated_use_date: "" as string,
        state: "creado" as string,
        user_location_id: this.getUserLocation().location_id,
      },
    };
  },
  methods: {
    async loadData() {
      this.displayLoader(true);
      this.purchaseRequestType = await PurchaseRequestTypeService.getAll();
      this.displayLoader(false);
    },
    validateForm() {
      if (!this.$refs.formPurchase.validate()) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.loadData();
  },
});
