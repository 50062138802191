

































































import ImgAvatar from "@/components/perfilUserCard/ImgAvatar.vue";
import User from "@/types/user/user";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  components: { ImgAvatar },
  props: {
    label: {
      default: false,
    },
    addCurrentUser:{
      type:Boolean,
      default:false
    },
    multipleSelect: {
      type: Boolean,
    },
    iconProp: {
      type: String,
    },
    functionChange: {
      type: Function,
      default: () => {
        console.log();
      },
    },
  },
  data() {
    return {
      usersSelects: [] as User[],
      menuProps: {
        disabled: false,
      },
    };
  },
  watch: {
    usersSelects() {
      if (this.usersSelects.length >= 1 && !this.multipleSelect) {
        this.menuProps.disabled = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      users: "users/getUsersFilterByState",
      loading: "users/getLoading",
    }),
  },
  mounted() {
    this.loadUsers("active");
  },
  methods: {
    ...mapActions({
      loadUsers: "users/getUsersFilterByState",
    }),
    filterWithoutCurrentUser(users:User[]){      
      let list
      if(this.addCurrentUser){
        list=users
      }else{
        list=users.filter((data:User) => data.id != this.getCurrentUserLoggedIn().id)
      }
      return list
    },
    remove(item: User) {
      this.usersSelects = this.usersSelects.filter(
        (data: User) => data.id != item.id
      );
      this.menuProps.disabled = false;
    },
  },
});
