































import Vue from "vue";
import CardCdp from "../cards/CardCdp.vue";
import CardPurchaseBuyer from "../cards/CardPurchaseBuyer.vue";
export default Vue.extend({
  props: {
    purchaseBuyerId: {
      type: Number,
      required: true,
    },
    cdpProps:{
        type:Object,
        required:true,
    }
  },
  components: {
    CardPurchaseBuyer,
    CardCdp
  },
});    
