





































import Vue from "vue";
import TableDetailFiles from "./TableDetailFiles.vue";
import BtnCreateDetailFile from "./BtnCreateDetailFile.vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  props: {
    detailProp: {
      required: true,
      type: Object,
    },
  },
  components: {
    TableDetailFiles,
    BtnCreateDetailFile,
  },
  computed: {
    ...mapGetters({
      detailFiles: "documentDetailFile/getDetailFile",
    }),
  },
  methods: {
    ...mapActions({
      getDetailFile: "documentDetailFile/getDetailFile",
    }),
  },
  mounted() {
    this.getDetailFile(this.detailProp.id);
  },
  data() {
    return {
      dialog: false,
    };
  },
});
