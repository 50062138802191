export const CREATE_TICKET_MODULE = 1;
export const TICKETS_MODULE = 2;
export const REPORT_TICKETS_MODULE = 3;
export const USER_MAINTAINER_MODULE = 4;
export const GROUP_MAINTAINER_MODULE = 5;

export const modulesAdministrative = {
    createTicketModule: CREATE_TICKET_MODULE,
    ticketsModule: TICKETS_MODULE,
    reportTicketsModule: REPORT_TICKETS_MODULE,
    userMaintainerModule: USER_MAINTAINER_MODULE,
    groupMaintainerModule: GROUP_MAINTAINER_MODULE,
}

export const modulesAdministrator = {
    createTicketModule: CREATE_TICKET_MODULE,
    ticketsModule: TICKETS_MODULE,
    reportTicketsModule: REPORT_TICKETS_MODULE,
    userMaintainerModule: USER_MAINTAINER_MODULE,
    groupMaintainerModule: GROUP_MAINTAINER_MODULE,
}