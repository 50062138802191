import { TicketTypeService } from "@/services/models/ticket/ticketType";
import TicketType from "@/types/tickets/tickets_type";
import { Commit } from "vuex";

interface stateInterface {
    ticketType: TicketType[],
    loading: boolean,
}
const moduleTicketType = {
    namespaced: true,
    state: {
        ticketType: [],
        loading: false,
    } as stateInterface,
    getters: {
        getTicketType(state: stateInterface): TicketType[] {
            return state.ticketType;
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setTicketType(state: stateInterface, ticketType: TicketType[]): void {
            state.ticketType = ticketType;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getTicketType({commit}: {commit: Commit},id:number): Promise<void> {
            commit('setLoading', true);
            const TICKETTYPE = await TicketTypeService.getByGroupId(id);                        
            commit("setTicketType", TICKETTYPE);
            commit('setLoading', false);
        },
    }
}

export default moduleTicketType;