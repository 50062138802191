






























import Vue from "vue";
export default Vue.extend({
  name: "FormFiles",
  data() {
    return {
      fileSelected: null as unknown as File,
      files: [] as Array<File>,
      headers: [
        {
          text: "name",
          sortable: false,
          align: "start",
          value: "name",
        },
        {
          text: "Acciones",
          sortable: false,
          align: "start",
          value: "action",
        },
      ],
    };
  },
  methods: {
    addFile() {
      if (this.fileSelected) {
        this.files.push(this.fileSelected);
        this.fileSelected = null as unknown as File;
      }
    },
    removeFile(item: File) {
      this.files = this.files.filter((data) => data != (item as File));
    },
  },
});
