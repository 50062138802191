


































import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import { PurchaseBuyerHistoryService } from "@/services/models/purchaseRequest/purchaseBuyerHistory";
import { PurchaseBuyerStateService } from "@/services/models/purchaseRequest/purchaseBuyerState";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import myFormInterface from "@/types/myFormInterface";
import PurchaseBuyerHistory from "@/types/purchaseRequest/purchaseBuyerHistory";
import PurchaseBuyerState from "@/types/purchaseRequest/purchaseBuyerState";
import PurchaseHistory from "@/types/purchaseRequest/purchaseHistory";
import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";
import User from "@/types/user/user";
import Vue from "vue";
import { mapActions } from "vuex";
import InputSelectUsers from "../document/ShowDocument/panelDetail/InputSelectUsers.vue";
export default (Vue as myFormInterface).extend({
  components: { InputSelectUsers },
  props: {
    purchaseBuyerProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setBuyerPurchaseRequest:'purchaseBuyer/getPurchaseBuyer',
      setBuyerPending: "buyer/getBuyerPending",
      setBuyerInProcess: "buyer/getBuyerInProcess",
      setBuyerReady: "buyer/getBuyerReady",
    }),
    async loadData() {
      this.setBuyerPurchaseRequest(this.purchaseBuyerProp.purchase_request_id)
      this.setBuyerPending({
        userId: this.getCurrentUserLoggedIn().id,
        state: "pendiente",
      });
      this.setBuyerInProcess({
        userId: this.getCurrentUserLoggedIn().id,
        state: "en proceso",
      });
      this.setBuyerReady({
        userId: this.getCurrentUserLoggedIn().id,
        state: "finalizado",
      });
    },
    async save() {
      try {
        this.displayLoader(true);
        let userSelected = this.$refs.selectUsers.usersSelects[0];
        await this.postPurchaseBuyerHistory(userSelected);
        await this.postPurchaseBuyerState(userSelected);
        await this.putPurchaseBuyer();
        console.log('asd',this.purchaseBuyerProp);
        
        await this.postPurchaseHistory(this.purchaseBuyerProp.purchase_request,userSelected)        
        this.displayToast({
          type: "success",
          message: "Registro enviado",
        });
        this.loadData()
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
    async postPurchaseBuyerHistory(user: User) {
      let dataBuyerHistory: PurchaseBuyerHistory = {
        id: 0,
        state: "registro enviado",
        user_origin_id: this.getCurrentUserLoggedIn().id,
        user_destination_id: user.id,
        purchase_buyer_id: this.purchaseBuyerProp.id,
      };
      await PurchaseBuyerHistoryService.create(dataBuyerHistory);
    },
    async postPurchaseBuyerState(user: User) {
      //Estado del comprador
      //Se busca el estado actual del creador del registro, para actualizarlo
      const currentState =
        await PurchaseBuyerStateService.getByPurchaseBuyerIdUserId(
          this.purchaseBuyerProp.id,
          this.getCurrentUserLoggedIn().id
        );
      let dataPurchaseBuyerStatePut: PurchaseBuyerState = {
        ...currentState[0],
        state: "enviado",
        step: "buyer",
      };
      await PurchaseBuyerStateService.update(
        currentState[0].id,
        dataPurchaseBuyerStatePut
      );

      //Se generá el estado de la persona a la que se le envia el registro
      const sendState =
        await PurchaseBuyerStateService.getByPurchaseBuyerIdUserId(
          this.purchaseBuyerProp.id,
          user.id
        );
      if (sendState.length == 0) {
        let dataPurchaseBuyerStatePost: PurchaseBuyerState = {
          id: 0,
          purchase_buyer_id: this.purchaseBuyerProp.id,
          state: "pendiente",
          step: "unityManager",
          user_id: user.id,
          user_location_id: user.users_locations?.[0].location_id || 0,
        };
        await PurchaseBuyerStateService.create(dataPurchaseBuyerStatePost);
      } else {
        let dataPurchaseBuyerStatePut: PurchaseBuyerState = {
          ...sendState[0],
          state: "pendiente",
        };
        await PurchaseBuyerStateService.update(
          sendState[0].id,
          dataPurchaseBuyerStatePut
        );
      }
    },
    async putPurchaseBuyer() {
      let dataPut = {
        ...this.purchaseBuyerProp,
        state: "enviado",
      };
      await PurchaseBuyerService.update(this.purchaseBuyerProp.id, dataPut);
    },
    async postPurchaseHistory(purchaseRequest: PurchaseRequest,user:User) {            
      let dataPurchaseHistory: PurchaseHistory = {
        id: 0,
        purchase_request_id: purchaseRequest.id,
        state: "Registro enviado a jefatura",
        user_origin_id: this.getCurrentUserLoggedIn().id,
        user_destination_id: user.id,
      };
      await PurchaseHistoryService.create(dataPurchaseHistory);
    },
  },
});
