





























































import Vue from "vue";
export default Vue.extend({
  mounted() {
    console.log("mounted");
  },
});
