

































































import Vue from "vue";
import ButtonRefreshTable from "../ButtonRefreshTable.vue";
import TableByChecked from "./TableByChecked.vue";
import TableChecked from "./TableChecked.vue";
import TableNotRelevant from "./TableNotRelevant.vue";

export default Vue.extend({
  components: {
   TableByChecked,
    TableChecked,
    TableNotRelevant,
    ButtonRefreshTable
  },
  data() {
    return {
      tab: null,
    };
  },
});
