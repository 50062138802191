







import Tabs from "@/components/document/Tabs.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: { TemplateAdministrator, Tabs },  
});
