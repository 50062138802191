








































import Vue from "vue";
// import Documentation from "@/components/sideMenu/Documentation.vue";
// import Folders from "@/components/sideMenu/Folders.vue";
import Maintainers from "@/components/sideMenu/Maintainers.vue";
// import Contact from "@/components/sideMenu/Contact.vue";
import Ticket from "@/components/sideMenu/Ticket.vue";
import RequestTicket from "@/components/sideMenu/RequestTicket.vue";
// import PurchaseRequest from "@/components/sideMenu/PurchaseRequest.vue";
import TicketReports from "@/components/sideMenu/TicketReports.vue";
import { modulesAdministrator } from "@/others/constants/modules";
import { mapGetters } from "vuex";

export default Vue.extend({
  components: {
    // Documentation,
//     Folders,
    Maintainers,
    // Contact,
    Ticket,
    RequestTicket,
    // PurchaseRequest,
    TicketReports,
  },
  data() {
    return {
      ...modulesAdministrator,
      starter: [
        {
          text: "Inicio",
          icon: "fas fa-home",
          to: { name: "administratorHome" },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      modules: "userSession/getModules",
    }),
  },
});
