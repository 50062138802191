import TicketFile from '@/types/tickets/tickets_files'
import { BaseService } from './../../base'
export class TicketFileService extends BaseService<TicketFile>() {
    static get entity (): string {
        return 'ticket_file'
    }
    static get responseName (): string {
        return 'ticket_file'
    }
    static async getByTicketId(ticket_id:number): Promise<TicketFile[]> {
        return this.submit<TicketFile[]>({
            method: 'get',
            path: `find_by_ticket_id/${ticket_id}`
        });
    }
}