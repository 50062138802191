


















import Vue from "vue";
import AddFileToTicket from "./AddFileToTicket.vue";
export default Vue.extend({
  name: "ButtonAddFileToTicket",
  components: { AddFileToTicket },
  props: {
		ticketId: {
			type: Number,
			required: true
		},
    loadTickets: {
      type: Function,
      required: true
    }
	},
  data() {
    return {
      dialog: false,
    };
  }
});
