







































































import TableUnityManagerBuyerPending from "@/components/purchaseRequest/tables/tablesUnityManager/TableUnityManagerBuyerPending.vue";
import TableUnityManagerBuyerReady from "@/components/purchaseRequest/tables/tablesUnityManager/TableUnityManagerBuyerReady.vue";
import TableUnityManagerPending from "@/components/purchaseRequest/tables/tablesUnityManager/TableUnityManagerPending.vue";
import TableUnityManagerReady from "@/components/purchaseRequest/tables/tablesUnityManager/TableUnityManagerReady.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: {
    TemplateAdministrator,
    TableUnityManagerPending,
    TableUnityManagerReady,
    TableUnityManagerBuyerReady,
    TableUnityManagerBuyerPending,


  },
  data(){
    return{
      tab:null
    }
  }
});
