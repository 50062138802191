import Folder from '@/types/documents/folder'
import { BaseService } from './../../base'
export class FolderService extends BaseService<Folder>() {
    static get entity (): string {
        return 'folder'
    }
    static get responseName (): string {
        return 'folder'
    }
    static async getByUserId(user_id:number): Promise<Folder[]> {
        return this.submit<Folder[]>({
            method: 'get',
            path: `find_by_user_id/${user_id}`
        });
    }
}