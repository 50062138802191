





























import { TicketService } from "@/services/models/ticket/ticket";
import { TicketCloseService } from "@/services/models/ticket/ticketClose";
import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";
import TicketClose from "@/types/tickets/ticketClose";
import Ticket from "@/types/tickets/tickets";
import TicketHistory from "@/types/tickets/tickets_histories";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    itemProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      ticketClose: {
        observation: "" as string,
      },
    };
  },
  methods: {
    ...mapActions({
      setTickets: "ticket/getTicketsUser",
      setTicketsFilter: "ticket/getTicketFilter",
    }),
    async save() {
      this.displayLoader(true);
      try {
          console.log(this.itemProp);
        let putTicket: Ticket = {
          ...this.itemProp,
          state: "cerrado permanentemente",
        };
        let postTicketClose: TicketClose = {
          ...this.ticketClose,
          ticket_id: this.itemProp.id,
          user_id:this.getCurrentUserLoggedIn().id,
          id: 0,
        };        
        
        let postTicketHistory:TicketHistory={
          ticket_id: this.itemProp.id,
          user_id: this.getCurrentUserLoggedIn().id,
          state: "Ticket cerrado permanentemente"
        };
        await TicketService.update(this.itemProp.id, putTicket);
        await TicketCloseService.create(postTicketClose);
        await TicketHistoryService.create(postTicketHistory);

        this.dialog=false
        this.displayToast({
            type: 'success',
            message: 'Ticket validado sin problemas'
        });
        this.setTickets(this.getCurrentUserLoggedIn().id);
        this.setTicketsFilter()
        
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "warning",
          message: "Hubo un error al cerrar",
        });
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
