

































import { TicketService } from "@/services/models/ticket/ticket";
import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";
import TicketHistory from "@/types/tickets/tickets_histories";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    itemProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      observation: "" as string,
    };
  },
  methods: {
    ...mapActions({
      setTickets: "ticket/getTicketFilter",
    }),
    async save() {
      this.displayLoader(true);
      try {
        await TicketService.update(this.itemProp.id, {
          ...this.itemProp,
          state: "cerrado",
        });
        let dataPost: TicketHistory = {
          user_id: this.getCurrentUserLoggedIn().id,
          ticket_id: this.itemProp.id,
          state: "Cerrado"
        };
        await TicketHistoryService.create(dataPost);
        this.setTickets();        
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
