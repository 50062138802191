







import TableGroups from "@/components/manteiners/manteinerGroups/TableGroups.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: { TemplateAdministrator, TableGroups },
});
