export const templateResolucion =
{
    title: "Resolución",
    description:
        "Plantilla predefinida de un Memo",
    content: `
    <p><img src="../Logo-SSM.jpg" width="100" height="100" /></p>
    <p class="western" style="line-height: 1" align="justify">
      <span style="font-family: Arial, sans-serif"
        ><span style="font-size: xx-small"
          ><span lang="es-ES-u-co-trad"
            >Servicio de Salud Magallanes</span
          ></span
        ></span
      >
    </p>
    \n
    <p class="western" style="line-height: 1" align="justify">
      <span style="font-family: Times New Roman, serif"
        ><span style="font-size: small"
          ><span style="font-family: Arial, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-ES-u-co-trad"
                >Subdirecci&oacute;n Administrativa</span
              ></span
            ></span
          ></span
        ></span
      >
    </p>
    \n
    <p lang="es-ES" style="line-height: 1" align="justify">
      <span style="font-family: Times New Roman, serif"
        ><span style="font-size: small"
          ><span style="font-family: Arial, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-ES-u-co-trad"
                >Departamento Tecnolog&iacute;as de Informaci&oacute;n y
                Comunicaciones
              </span></span
            ></span
          ></span
        ></span
      >
    </p>
    \n
    <p lang="es-ES" style="line-height: 1" align="justify">
      <span style="font-family: Times New Roman, serif"
        ><span style="font-size: small"
          ><span style="font-family: Verdana, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-CL">AVF/</span></span
            ></span
          ><span style="font-family: Verdana, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-CL">RTB/kam</span></span
            ></span
          ></span
        ></span
      >
    </p>
    \n
          <table
            style="border-collapse: collapse; width: 100%; border-style: hidden"
            border="1"
          >
            \n
            <tbody>
              \n
              <tr style="border-style: none">
                \n
                <td style="width: 48.2149%; border-style: hidden">&nbsp;</td>
                \n
                <td style="width: 48.2149%; border-style: hidden">
                  \n
                  <p>Resolucion Excenta N&deg;154</p>
                  \n
                  <p>Punta Arenas, 19 Mayo de 2021</p>
                  \n
                </td>
                \n
              </tr>
              \n
            </tbody>
            \n
          </table>
          \n
          <p>&nbsp;</p>
          \n
          <p style="text-align: left"><strong>VISTOS</strong>:&nbsp;</p>
          \n
          <p style="text-align: left"><strong>CONSIDERANDO</strong>:</p>
          \n
          <p style="text-align: center"><strong>RESOLUCI&Oacute;N</strong></p>
          \n
          <p><strong>1 REGULARICESE Y ESTABL&Eacute;ZCASE</strong>,</p>
          \n
          <p><strong>2 DEJESE ESTABLECIDO</strong></p>
          \n
          <p>ANOTESE, COMUNIQUESE Y ARCHIVASE</p>
          \n
          <p>
            <strong
              >N&deg;<span style="text-decoration: underline"> 2045 </span
              >//</strong
            >
          </p>
          \n
          <p>
            <span style="text-decoration: underline"
              ><strong>DISTRIBUCI&Oacute;N DIGITAL</strong></span
            >
          </p>
          \n
          <p>Subdirecci&oacute;n de Gestion Asistencial</p>
          \n
          <p>Subdirecci&oacute;n de Desarrollo de Personas</p>
          \n
          <p>&nbsp;</p>
    `,

}