






































import Vue from "vue";

import Inputs from "./Inputs.vue";
import myFormInterface from "@/types/myFormInterface";
import { mapActions } from "vuex";
import TableSearchDocument from "./TableSearchDocument..vue";

export default (Vue as myFormInterface).extend({
  components: {
    Inputs,
    TableSearchDocument,
  },
  data() {
    return {
      dialog2:false,
      dialog: false as boolean,
      isLoading: false as boolean,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        console.log("asdasd");
      }
    },
  },
  methods: {
    ...mapActions({
      documentSearch:'documents/getSearchDocument'
    }),
    search() {
      let data = {
        ...this.$refs.inputDataUser.document,
      };
      this.documentSearch({data:data})      
      this.dialog2=true;
    },
  },
});
