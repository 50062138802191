










































import { TicketTypeService } from "@/services/models/ticket/ticketType";
import myFormInterface from "@/types/myFormInterface";
import TicketType from "@/types/tickets/tickets_type";
import Vue from "vue";
import { mapActions } from "vuex";
import TableTicketType from "./TableTicketType.vue";
export default (Vue as myFormInterface).extend({
  components: { TableTicketType },
  props: {
    itemProp: {
      type: Object,
    },
  },
  data() {
    return {
      ticketType: {
        name: "" as string,
      },
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setTicketType: "ticketType/getTicketType",
    }),
    async save() {
      this.displayLoader(true);
      let dataPost: TicketType = {
        id: 0,
        name: this.ticketType.name,
        group_id: this.itemProp.id,
      };
      await TicketTypeService.create(dataPost);
      this.setTicketType(this.itemProp.id);
      this.displayLoader(false);
    },
  },
});
