





























import ModalFirmDocument from "@/components/purchaseRequest/firmDocument/ModalFirmDocument.vue"

import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  components:{
    ModalFirmDocument
  },
  props: {
    purchaseBuyerProps: {
      type: Object,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Id",
          sorteable: false,
          value: "id",
        },
        {
          text: "Comprador",
          sorteable: false,
          value: "user",
        },
        {
          text: "Acciones",
          sorteable: false,
          value: "actions",
        },
      ],
    };
  },
  computed:{
    ...mapGetters({
      getItems:'cdp/getCdp',
      getLoading:'cdp/getLoading'
    })
  },
  methods: {
    ...mapActions({
      setItems:'cdp/getCdp',      
    }),    
  },
  mounted() {
    this.setItems(this.purchaseBuyerProps.id);
  },
});
