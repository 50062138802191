import { UserGroupService } from "@/services/models/group/userGroup";
import UserGroup from "@/types/user/userGroup";
import { Commit } from "vuex";

interface stateInterface {
    userGroup: UserGroup[],
    loading: boolean,
}
const moduleUserGroup = {
    namespaced: true,
    state: {
        userGroup: [],
        loading: false,
    } as stateInterface,
    getters: {
        getUserGroup(state: stateInterface): UserGroup[] {
            return state.userGroup;
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setUserGroup(state: stateInterface, userGroup: UserGroup[]): void {
            state.userGroup = userGroup;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getUserGroup({commit}: {commit: Commit},group_id:number): Promise<void> {
            commit('setLoading', true);
            const UserGroup = await UserGroupService.getByGroupId(group_id);                        
            commit("setUserGroup", UserGroup);
            commit('setLoading', false);
        },
    }
}

export default moduleUserGroup;