

























import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ShowObservationHistory from "./ShowObservationHistory.vue";
export default Vue.extend({
  components: { ShowObservationHistory },
  props: {
    detailProp: {
      type: Object,
    },
  },
  data() {
    return {
      headers: [
        { text: "Origen", align: "start", value: "user_origin_id" },
        { text: "Destino", align: "start", value: "user_destination_id" },
        { text: "Estado", align: "start", value: "state" },
        { text: "Fecha", align: "start", value: "created_at" },
        { text: "Observación", align: "start", value: "description" },
      ],
    };
  },
  mounted() {
    this.getHistories(this.detailProp.id);
  },
  computed: {
    ...mapGetters({
      histories: "history/getDocumentDetailId",
      loading: "history/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      getHistories: "history/getDocumentDetailId",
    }),
  },
});
