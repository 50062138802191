

























































import { UserService } from "@/services/models/user/user";
import User from "@/types/user/user";
import Vue from "vue";
import { mapActions } from "vuex";
import MyFormInterface from "@/types/myFormInterface";
import UserInfo from "./UserInfo.vue";
import UserProfiles from "./UserProfiles.vue";
import UserLocation from "@/types/user/userLocation";
import { UserLocationService } from "@/services/models/user/userLocation";
import { UserModuleService } from "@/services/models/user/userModule";
import { UserModulePermissionService } from "@/services/models/user/userModulePermission";
import UserModules from "./UserModules.vue";
import UserModule from "@/types/user/userModule";
import compressImage from "@/others/compressFile";
export default (Vue as MyFormInterface).extend({
  components: { UserInfo, UserProfiles, UserModules },
  data() {
    return {
      loading: false,
      user: {} as User,
      usersLocations: [] as UserLocation[],
      usersModules: [] as UserModule[],
      step: 1,
      invalidStep: 0 as number,
    };
  },
  mounted() {
    this.getRoles();
    this.getStablishment();
  },
  methods: {
    ...mapActions({
      getRoles: "roles/getRoles",
      getStablishment: "stablishments/getStablishments",
      getUsers: "users/getUsers",
      getUsersFilter: "users/getUsersFilterByState",
    }),
    validateStep(isValid: boolean, step: number): void {
      if (!isValid) {
        this.invalidStep = step;
      }
    },
    async createUser() {
      this.loading = true;
      try {
        const areFormsValid = await this.validateMultipleForms();
        if (!areFormsValid) {
          this.loading = false;
          return;
        }
        const user = await UserService.create({
          ...this.user,
          state: 'active',
          run: this.user.run.split(".").join(""),
        });
        if (this.user.avatar) {
          // eslint-disable-next-line
          const avatarCompressed = await compressImage(this.user.avatar as any);
          const formData = new FormData();
          console.log(avatarCompressed);
          formData.append("file", avatarCompressed);
          await UserService.updateAvatar(user.id, formData);
        }
        for (let i = 0; i < this.usersLocations.length; i++) {
          const userLocation = this.usersLocations[i];
          await UserLocationService.create({
            ...userLocation,
            user_id: user.id,
          });
        }
        for (let i = 0; i < this.usersModules.length; i++) {
          console.log('Insert en module');
          
          const userModule: any = this.usersModules[i];
          const userModuleCreated = await UserModuleService.create({
            ...userModule,
            user_id: user.id,
          });
          if (userModule.module && userModule.permissions) {
            for (let j = 0; j < userModule.permissions.length; j++) {
              const permission = userModule.permissions[j];
              await UserModulePermissionService.create({
                id: 0,
                user_module_id: userModuleCreated.id,
                permission_id: permission.id,
              });
            }
          }
        }
        this.displayToast({
          type: "success",
          message: "El usuario se ha creado correctamente",
        });
        this.$emit("changeDialog", false);
        // this.dialog = false;
        this.getUsersFilter("active");
        this.getUsers();
      } catch (error) {
        this.displayToast({
          type: "error",
          message:
            "Lo sentimos, ha ocurrido un error. Favor intentar más tarde",
        });
        console.log(error);
      }
      this.loading = false;
    },
  },
  watch: {
    invalidStep() {
      if (this.invalidStep != 0) {
        this.step = this.invalidStep;
      }
    },
  },
});
