












import Vue from "vue";
export default Vue.extend({
  props: {
    profile: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      document: [
        {
          text: "Solicitudes",
          icon: "fas fa-headset",
          to: { name: `requestTicket${this.profile}` },
        },
      ],
    };
  },
});
