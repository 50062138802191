












































import ChipUserWithMenu from "@/components/general/ChipUserWithMenu.vue";
import { DocumentDetailFirmedService } from "@/services/models/document/documentDetailFirmed";
import DocumentDetailFirmed from "@/types/documents/documentDetailFirmed";
import Vue from "vue";
export default Vue.extend({
  components: { ChipUserWithMenu },
  props: {
    detailProp: {
      type: Object,
    },
  },
  data() {
    return {
       headers: [
        {
          text: "Usuario",
          sortable: false,
          align: "start",
          value: "user",
        },
        {
          text: "Enviado",
          sortable: false,
          align: "start",
          value: "created_at",
        },
        {
          text: "Actualización",
          sortable: false,
          align: "start",
          value: "updated_at",
        },
      ],
      menu: undefined,
      firmData: [] as DocumentDetailFirmed[],
      isLoading: false as boolean,
    };
  },
  watch: {
    menu() {
      if (this.menu == true) {
        this.loadData();
      }
    },
  },
  methods: {
    iconForUser(item: DocumentDetailFirmed) {
      let response = {
        icon: "",
        color: "",
      };
      if (item.state == "por firmar") {
        response.icon = "fa-clock";
        response.color = "light-blue darken-3";
      }
      if (item.state == "firmado") {
        response.icon = "fa-check-circle";
        response.color = "green darken-3";
      }
      if (item.state == "rechazado") {
        response.icon = "fa-times-circle";
        response.color = "red";
      }
      return response;
    },
    async loadData() {
      this.isLoading = true;
      this.firmData = await DocumentDetailFirmedService.getByDocumentDetailId(
        this.detailProp.id
      );
      this.isLoading = false;
    },
  },
});
