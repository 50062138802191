































































import Vue from "vue";
import {
  DataLogin,
  DataLoginReturn,
  UserService,
} from "@/services/models/user/user";
import { AxiosResponse } from "axios";
import { mapGetters, mapMutations } from "vuex";
import store from "@/store";
import { formatRut } from "@/thirdParty/utils";

export default Vue.extend({
  props: {
    bgColor: {
      type: String,
      default: "red",
    },
    fgColor: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      password: "" as string,
      login: "" as string,
      loading: false as boolean,
    };
  },
  beforeCreate: function () {
    document.body.className = "intro";
  },  
  computed: {
    ...mapGetters({
      show: "relogin/getRelogin",
    }),    
  },
  methods: {
    ...mapMutations({
      setProfile: "userSession/setProfile",
      setCurrentLocation: "userSession/setCurrentLocation",
    }),

    formatRut() {
      this.login = formatRut(this.login);
    },
    clickOutside() {
      localStorage.clear();
      this.$router.push({ name: "Login" });
    },
    async connect() {      
      this.displayLoader(true)
      let data: DataLogin = {
        run: this.login.replaceAll(".",""),
        password: this.password,
        grant_type: "password",
      };
      try {
        store.commit('relogin/resetRelogin');
        let response: AxiosResponse<DataLoginReturn> = await UserService.login(
          data
        );
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("perfil", response.data.user.role?.name || "");
        this.setProfile(response.data.user.role?.name);
        if (response.data.user.users_locations) {
          this.setCurrentLocation(response.data.user.users_locations[0]);
        }
        window.location.reload();
      } catch (error: any) {   
        if (error.message == "Request failed with status code 401") {
          this.displayToast({
            type: 'warning',
            message: 'Las credenciales ingresadas son incorrectas'
          });
        } else {
          this.displayToast({
            type: 'error',
            message: 'Ha ocurrido un error al ingresar, favor volver a intentar'
          });
        }
      } finally {
        this.displayLoader(false)
      }
    },
  },
});
