
























import { UserService } from "@/services/models/user/user";
import InputFile from "@/types/general/inputFile";
import myFormInterface from "@/types/myFormInterface";
import Vue from "vue";
import { mapActions } from "vuex";
export default (Vue as myFormInterface).extend({
  props: {
    allowChange: {
      default: false,
    },
    data: {
      type: String,
    },
    size: {
      type: Number,
    },
  },
  data() {
    return {
      newFile: {} as Blob,
    };
  },
  methods: {
    ...mapActions({
      setUser: "users/getUser",
    }),
    onButtonClick() {
      this.$refs.uploader.click();
    },
    async onFileChanged(e: InputFile) {
      try {
        // this.isSelecting = true;
        this.newFile = e.target.files[0];
        let formData: FormData = new FormData();
        formData.append("file", this.newFile);
        await UserService.updateAvatar(
          this.getCurrentUserLoggedIn().id,
          formData
        );
        this.setUser(this.getCurrentUserLoggedIn().id);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
});
