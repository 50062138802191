
































































































import Vue from "vue";
import ButtonSend from "./ButtonSend.vue";
import TableHistories from "./tableHistories/TableHistories.vue";

import { mapActions, mapGetters } from "vuex";
import DocumentDetail from "@/types/documents/documentDetail";
import GroupButtons from "./GroupButtons.vue";
import Editor from "@tinymce/tinymce-vue";

export default Vue.extend({
  props: {
    documentProp: {
      type: Object,
    },
  },
  components: {
    editor: Editor,
    TableHistories,
    ButtonSend,
    GroupButtons,
  },
  data() {
    return {
      open: [],
      configurationTiny: {
        menubar: false,
        toolbar: false,
        statusbar: false,
        height:'500',
        readonly: true,
        setup: function(ed:any)
        {
            ed.settings.readonly = true;
        }
      },
    };
  },
  mounted() {
    this.getDocumentsDetail(this.documentProp.id);
  },
  computed: {
    ...mapGetters({
      getDocuments: "documentDetail/getByDocumentId",
      getLoading: "documentDetail/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      getDocumentsDetail: "documentDetail/getByDocumentId",
    }),
    hasChipRead(documentDetail: DocumentDetail) {
      let isRead = false;
      if (documentDetail.documents_details_reads) {
        if (this.getProfile() == "opartes") {
          isRead = !!documentDetail.documents_details_reads.find(
            (detail) =>
              detail.location_id == this.getUserLocation().id
          );
        } else {
          isRead = !!documentDetail.documents_details_reads.find(
            (detail) =>
              detail.user_id == this.getCurrentUserLoggedIn().id
          );
        }
      }
      if (documentDetail.user_id == this.getCurrentUserLoggedIn().id) {
        isRead = true;
      }
      return isRead;
    },
    hasChipFirmed(documentDetail: DocumentDetail) {
      let mustFirm = false;
      if (documentDetail.documents_details_firmed?.length) {
        let hasFirm = documentDetail.documents_details_firmed.find(
          (value) => value.user_id == this.getCurrentUserLoggedIn().id
        );
        if (hasFirm && hasFirm?.state === "por firmar") {
          mustFirm = true;
        }
      }
      return mustFirm;
    },
    hasChipChecked(documentDetail: DocumentDetail) {
      let mustCheck = false;
      if (documentDetail.documents_details_checked?.length) {
        let hasCheck = documentDetail.documents_details_checked.find(
          (value) => value.user_id == this.getCurrentUserLoggedIn().id
        );
        if (hasCheck && hasCheck?.state === "por visar") {
          mustCheck = true;
        }
      }
      return mustCheck;
    },
    colorChip(item: DocumentDetail) {
      let colorChip = {
        date: "",
        fileName: "",
      };
      if (item.state == "disponible") {
        colorChip.date = "blue lighten-1";
        colorChip.fileName = "primary";
      } else if (item.state == "invalido") {
        colorChip.date = "red lighten-1";
        colorChip.fileName = "error";
      }
      return colorChip;
    },
  },
});
