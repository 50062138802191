import { DocumentDetailService } from "@/services/models/document/documentDetail";
import DocumentDetail from "@/types/documents/documentDetail";
import { Commit } from "vuex";

interface stateInterface {
    documentDetail: DocumentDetail[],
    documentDetailByDocumentId:DocumentDetail[],
    loading: boolean,
}
const moduleDocumentDetail = {
    namespaced: true,
    state: {
        documentDetail: [],
        documentDetailByDocumentId:[],
        loading: false,
    } as stateInterface,
    getters: {
        getDocuments(state: stateInterface): DocumentDetail[] {
            return state.documentDetail;
        },
        getByDocumentId(state: stateInterface): DocumentDetail[] {
            return state.documentDetailByDocumentId;
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setDocument(state: stateInterface, documentDetail: DocumentDetail[]): void {
            state.documentDetail = documentDetail;
        },
        setByDocumentId(state: stateInterface, documentDetailByDocumentId: DocumentDetail[]): void {
            state.documentDetailByDocumentId = documentDetailByDocumentId;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getDocumentDetails({commit}: {commit: Commit}): Promise<void> {
            commit('setLoading', true);
            const documentDetail = await DocumentDetailService.getAll();                        
            commit("setDocument", documentDetail);
            commit('setLoading', false);
        },
        async getByDocumentId({commit}: {commit: Commit},id:number): Promise<void> {
            commit('setLoading', true);
            const documentDetail = await DocumentDetailService.getByDocumentId(id);                        
            commit("setByDocumentId", documentDetail);
            commit('setLoading', false);
        }
    }
}

export default moduleDocumentDetail;