






































import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  props: {
    itemProp: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      getMessages: "ticketMessage/getTicketMessage",
      getLoading: "ticketMessage/getLoading"
    }),
  },
  methods: {
    ...mapActions({
      setMessage: "ticketMessage/getTicketMessage",
    }),
    currentDate() {
      return moment().format("DD-MM-YYYY hh:mm");
    },
  },
  mounted() {
    this.setMessage(this.itemProp.id);
  },
});
