import TicketClose from '@/types/tickets/ticketClose'
import { BaseService } from './../../base'
export class TicketCloseService extends BaseService<TicketClose>() {
    static get entity (): string {
        return 'ticket_closed'
    }
    static get responseName (): string {
        return 'ticket_closed'
    }
    static async getByTicketId(ticket_id:number): Promise<TicketClose[]> {
        return this.submit<TicketClose[]>({
            method: 'get',
            path: `find_by_ticket_id/${ticket_id}`
        });
    }
}