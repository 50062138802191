








































import myFormInterface from "@/types/myFormInterface";

import Vue, { PropType } from "vue";
import { mapActions } from "vuex";
import Document from "@/types/documents/document";
import { DocumentStateService } from "@/services/models/document/documentState";
import Folder from "@/types/documents/folder";
import { FolderService } from "@/services/models/document/folder";

export default (Vue as myFormInterface).extend({
  props: {
    currentFolderId: {
      required: true,
      type: Number
    },
    document: {
      required: true,
      type: Object as PropType<Document>,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      loadingFolder: false,
      listFolders: [] as Folder[],
      folderId: this.currentFolderId,
    };
  },
  methods: {
    ...mapActions({
      getFolderDocuments: "documents/getFolderDocument",
    }),
    async invalidDetail() {
      try {
        this.isLoading = true;
        let documentState = this.document.documents_states?.find(
          (state) => state.user_id == this.getCurrentUserLoggedIn().id
        );
        if (documentState) {
          await DocumentStateService.update(documentState.id, {
            ...documentState,
            folder_id: this.folderId,
          });
        }
        this.displayToast({
          type: "success",
          message: "La operación se ha realizado con éxito",
        });
        this.getFolderDocuments({
          user_id: this.getCurrentUserLoggedIn().id,
          folder_id: this.$route.params.id,
        });
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Lo sentimos, ha ocurrido un error al realizar la acción",
        });
      } finally {
        this.isLoading = false;
        this.dialog = false;
      }
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.cleanForm(this, "formInvalid");
        try {
          this.loadingFolder = true;
          let folderResponse = await FolderService.getByUserId(this.getCurrentUserLoggedIn().id);
          this.listFolders = folderResponse.filter(folder => folder.id != this.currentFolderId);
          if (this.listFolders.length) {
            this.folderId = this.listFolders[0].id;
          }
          this.loadingFolder = false;
        } catch (error) {
          this.displayToast({
            type: 'error',
            message: 'Lo sentimos, ha ocurrido un error al cargar las carpetas'
          });
        }
      }
    },
  },
});
