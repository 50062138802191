















import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonRemoveBuyer from "./ButtonRemoveBuyer.vue";
export default Vue.extend({
  components: { ButtonRemoveBuyer },
  props: {
    purchaseProp: {
      type: Object,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Funcionario",
          sortable: false,
          value: "name",
        },
        {
          text: "Acciones",
          sortable: false,
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getBuyer: "buyer/getBuyerAssign",
      getLoading: "buyer/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setBuyer: "buyer/getBuyerAssign",
    }),    
  },
  mounted() {
    this.setBuyer(this.purchaseProp.id);
  },
});
