
































import { CdpService } from "@/services/models/purchaseRequest/cdp";
import { CdpArticleService } from "@/services/models/purchaseRequest/cdpArticle";
import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import myFormInterface from "@/types/myFormInterface";
import Cdp from "@/types/purchaseRequest/cdp";
import CdpArticle from "@/types/purchaseRequest/cdpArticle";
import Vue from "vue";
import FormCdp from "./ButtonCreatePurchaseBuyer.vue/cdpForm/FormCdp.vue";
export default (Vue as myFormInterface).extend({
  components: { FormCdp },
  props: {
    cdpProps: {
      type: Object,
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {        
        const purchaseBuyer = await PurchaseBuyerService.getById(
          this.cdpProps.purchase_buyer_id
        );
        this.dataPurchaseBuyer = await PurchaseRequestService.getById(
          purchaseBuyer.purchase_request_id || 0
        );        
      }
    },
  },
  data() {
    return {
      dialog: false,
      dataPurchaseBuyer: {},
    };
  },
  methods: {
    async save() {
      this.displayLoader(true);
      try {
        let dataCdp: any = this.$refs.formCdp.data;
        let purchase_request_detail = dataCdp.cdps_articles        
        
        await CdpService.update(this.cdpProps.id,dataCdp as Cdp)
        for (let i = 0; i < this.cdpProps.cdps_articles.length; i++) {
            await CdpArticleService.remove(this.cdpProps.cdps_articles[i].id);            
        }
        for (let x = 0; x < purchase_request_detail.length; x++) {
          console.log('ahhh',purchase_request_detail[x]);

          let data: CdpArticle = {
            id: 0,
            cdp_id: this.cdpProps.id,
            purchase_request_detail_id: purchase_request_detail[x].purchase_request_detail_id,
            budget_item_id:
              purchase_request_detail[x].article.budget_item_id || 0,
            obervation: "",
          };
          await CdpArticleService.create(data);
        }
        this.displayToast({
            type: 'success',
            message: 'Cdp Modificado'
        });
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
