
































import { TicketService } from "@/services/models/ticket/ticket";
import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";
import { TicketResponsableService } from "@/services/models/ticket/ticketResponsable";
import TicketHistory from "@/types/tickets/tickets_histories";
import TicketResponsable from "@/types/tickets/tickets_responsables";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    itemProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setTicketFilter:"ticket/getTicketFilter"
    }),
    async save() {
      try {
        this.displayLoader(true);
        
        await TicketService.update(this.itemProp.id, {
          ...this.itemProp,
          state: "en proceso",
        });
        let dataPost: TicketResponsable = {
          user_id: this.getCurrentUserLoggedIn().id,
          ticket_id: this.itemProp.id,
          observation:'Solicitud tomada'
        };
        await TicketResponsableService.create(dataPost);
        let dataPostHistory: TicketHistory = {
          user_id: this.getCurrentUserLoggedIn().id,
          ticket_id: this.itemProp.id,
          state: "Solicitud en proceso",
        };
        await TicketHistoryService.create(dataPostHistory);
        this.setTicketFilter();
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
