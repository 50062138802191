










































































import Ticket from "@/types/tickets/tickets";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonHistory from "./ButtonHistory.vue";
import ButtonReOpen from "./ButtonReOpen.vue";
import ModalChatTicket from "./chat/ModalChatTicket.vue";
import ButtonClosedTicket from "./closedTicket/ButtonClosedTicket.vue";
import ButtonShowClosedTicket from "./closedTicket/ButtonShowClosedTicket.vue";
import DetailTableRequestTicket from "./DetailTableRequestTicket.vue";

export default Vue.extend({
  components: { DetailTableRequestTicket, ButtonHistory, ButtonReOpen, ModalChatTicket, ButtonClosedTicket, ButtonShowClosedTicket },
  data() {
    return {
      page: 1,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",          
          sortable: false,
          width:50,
        },
        { text: "Titulo", sortable: false, align: "start", value: "title" },
        {
          text: "Responsable",
          sortable: false,
          align: false,
          value: "responsable",
        },
        {
          text: "Generó el ticket",
          sortable: false,
          align: "start",
          value: "created_by",
        },
        {
          text: "Estado",
          sortable: false,
          align: "start",
          value: "state",
        },
        {
          text:'Acciones',
          sortable:false,
          align:"start",
          value:"actions"
        }
      ],
    };
  },
  computed: {
    ...mapGetters({
      getTickets: "ticket/getTicketsUser",
      getLoading: "ticket/getLoading",
    }),
  },
  mounted() {
    this.changePage();
  },
  methods: {
    ...mapActions({
      setTickets: "ticket/getTicketsUser",
    }),   
    getResponsable(item: Ticket) {
      let returnResponsable = "Sin responsable";
      if (item.tickets_responsables?.length != 0) {
        let responsableName = item.tickets_responsables
          ? item.tickets_responsables?.[0].user?.name
          : "";
        let responsablePatherLastname = item.tickets_responsables
          ? item.tickets_responsables?.[0].user?.pather_lastname
          : "";
        let responsableMotherLastname = item.tickets_responsables
          ? item.tickets_responsables?.[0].user?.mother_lastname
          : "";
        returnResponsable = `${responsableName} ${responsablePatherLastname} ${responsableMotherLastname}`;
      }
      return returnResponsable;
    },
    changePage() {
      this.$store.commit("ticket/setPageTicketUser", this.page);
      this.setTickets(this.getCurrentUserLoggedIn().id);
    },
    getColor(state: string) {
      if (state == "cerrado") return "success";
      else if (state == "en proceso") return "warning";
      else if (state == "cerrado permanentemente") return "error";
      else return "primary";
    },
  },
});
