



















































































































import myFormInterface from "@/types/myFormInterface";
import User from "@/types/user/user";
import Stablishment from "@/types/user/stablishment";
import Vue, { PropType } from "vue";
import { mapGetters, mapMutations } from "vuex";
import { UserService } from "@/services/models/user/user";
import { formatRut } from "@/thirdParty/utils";
export default (Vue as myFormInterface).extend({
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    avatarFile: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      userFile: "" as string | ArrayBuffer | null,
    };
  },
  computed: {
    ...mapGetters({
      loadingRole: "roles/getLoading",
      loadingStablishment: "stablishments/getLoading",
      roles: "roles/getRoles",
      stablishments: "stablishments/getStablishments",
    }),
  },
  methods: {
    // eslint-disable-next-line
    handleUpload(e: any) {
      console.log("handleUpoad");
      
      if (e) {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event !== null) {
            // eslint-disable-next-line
            this.userFile = event.target!.result;
          }
        };
        reader.readAsDataURL(e);
      } else {
        this.userFile = "";
      }
    },
    formatRut() {
      this.user.run = formatRut(this.user.run);
    },
    ...mapMutations({
      chargeStablishment: "stablishments/changeSatblishmentSelected",
    }),
    loadStablishment() {
      let stablishmentSelected = this.stablishments.find(
        (stablishment: Stablishment) =>
          stablishment.id == this.user.stablishment_id
      );
      this.chargeStablishment(stablishmentSelected);
    },
    validateRutEdit(usersFilteredByRut: User[]) {
      let response = {
        isValid: true,
        messageValidation: "",
      };
      console.log(
        usersFilteredByRut,
        this.user.run.split(".").join(""),
        this.user.id
      );
      if (
        usersFilteredByRut.find(
          (value) =>
            value.run == this.user.run.split(".").join("") &&
            value.id == this.user.id
        )
      ) {
        response.isValid = true;
      } else {
        response.isValid = false;
        response.messageValidation =
          "El rut ya se encuentra asociado a una cuenta de usuario";
      }
      console.log(response);

      return response;
    },
    validateEmailEdit(usersFilteredByEmail: User[]) {
      let response = {
        isValid: true,
        messageValidation: "",
      };
      if (
        usersFilteredByEmail.find(
          (value) => value.email == this.user.email && value.id == this.user.id
        )
      ) {
        response.isValid = true;
      } else {
        response.isValid = false;
        response.messageValidation =
          "El correo electrónico ya se encuentra asociado a una cuenta de usuario";
      }
      return response;
    },
    async validateForm(): Promise<{
      isValid: boolean;
      message: string;
    }> {
      let messageValidation = "";
      let isValid: boolean = this.$refs.myForm.validate();

      if (this.user.run) {
        let usersFilteredByRut = await UserService.getByRut(
          this.user.run.split(".").join("")
        );
        if (usersFilteredByRut.length != 0) {
          if (this.isEdit) {
            const validation = this.validateRutEdit(usersFilteredByRut);

            isValid = validation.isValid;
            messageValidation = validation.messageValidation;
          } else {
            isValid = false;
            messageValidation =
              "El rut ya se encuentra asociado a una cuenta de usuario";
          }
        }
      }

      if (this.user.email && isValid) {
        let usersFilteredByEmail = await UserService.getByEmail(
          this.user.email
        );
        if (usersFilteredByEmail.length != 0) {
          if (this.isEdit) {
            const validation = this.validateEmailEdit(usersFilteredByEmail);
            isValid = validation.isValid;
            messageValidation = validation.messageValidation;
          } else {
            isValid = false;
            messageValidation =
              "El correo electrónico ya se encuentra asociado a una cuenta de usuario";
          }
        }
      }
      this.$emit("isValid", isValid);
      return {
        isValid,
        message: messageValidation,
      };
    },
  },
  watch: {
    stablishments() {
      if (this.stablishments.length) {
        this.user.stablishment_id = this.stablishments[0].id;
        this.loadStablishment();
      }
    },
    "user.avatar": function () {
      console.log('change');
      console.log(this.avatarFile);
      if (this.avatarFile) {
        this.userFile = this.avatarFile;
        
      }
    }
  },
});
