import { AxiosResponse } from 'axios';
import DocumentDetail from '@/types/documents/documentDetail'
import { BaseService } from '../../base'
export class DocumentDetailService extends BaseService<DocumentDetail>() {
    static get entity () {
        return 'document_detail'
    }
    static get responseName () {
        return 'document_detail'
    } 
    static async getByDocumentId(document_id:number): Promise<DocumentDetail[]> {
		return this.submit<DocumentDetail[]>({
            method: 'get',
            path: `find_by_document_id/${document_id}`
        });
	}
    static async downloadFile(fileId: number): Promise<AxiosResponse<Blob>> {
        return this.submit<AxiosResponse<Blob>>({
            method: 'get',
            path: `download_image/${fileId}`,
            showFullResponse: true,
            config: {
                responseType: 'blob',    
                "Content-Type": "application/json"
            }
        })
    }
    static async getFileFirmed(dataToPost: dataToSignDigitalDocument): Promise<AxiosResponse<firmaDigitalResponse>> {
        return this.submit<AxiosResponse<firmaDigitalResponse>>({
            method: 'put',
            data: {
                ...dataToPost,
                api_token_key: "31602029-ffc8-4112-8bf7-16d028fe3635",
            },
            path: 'get_file_firmed',
            showFullResponse: true
        })
    }
    static async updateImage(dataToUpdate: dataToUpdateImage): Promise<DocumentDetail> {
        return this.submit<Promise<DocumentDetail>>({
            method: 'put',
            data: {
                base64: `data:application/pdf;base64,${dataToUpdate.base64}`,
            },
            path: 'update_image/' + dataToUpdate.documentDetailId,
            showFullResponse: true
        })
    }
    
}
interface GetFile{
    files:[{
        content:Blob,
        status:string,        
    }]
}


//FIRMA DIGITAL

interface dataToUpdateImage {
    base64: string,
    documentDetailId: number
}
interface dataToSignDigitalDocument {
    token: string,
    content: string,
    checksum: string,
    otp: string,
    api_token_key?: string
}
interface firmaDigitalResponse {
    files: fileFirmaDigital[],
    metadata: metadataInterface,
    error?: string
}

interface metadataInterface {
    signed_failed: number,
    OTP_expired: boolean,
    files_signed: number,
    files_received: number
}

interface fileFirmaDigital {
    checksum_original: string,
    status: string,
    content: string
}