const MAX_WIDTH = 320;
const MAX_HEIGHT = 180;
const MIME_TYPE = "image/jpeg";
const QUALITY = 0.7;

export default async function compressImage(file: Blob): Promise<Blob> {
	const blobURL = URL.createObjectURL(file);
	const img = new Image();
	img.src = blobURL;
	img.onerror = function () {
		// URL.revokeObjectURL(this.src);
		// Handle the failure properly
		console.log("Cannot load image");
	};
	console.log(file.size);
	const blobValue: Blob = await onLoadImage(img, file);
	console.log(blobValue.size);
	return blobValue;
}
async function onLoadImage(img: HTMLImageElement, file: Blob): Promise<Blob> {
	return new Promise((resolve2) => {
		img.onload = () => {
			// URL.revokeObjectURL(this.src);
			const [newWidth, newHeight] = calculateSize(
				img,
				MAX_WIDTH,
				MAX_HEIGHT
			);
			const canvas = document.createElement("canvas");
			canvas.width = newWidth;
			canvas.height = newHeight;
			const ctx = canvas.getContext("2d");
			if (ctx) {
				ctx.drawImage(img, 0, 0, newWidth, newHeight);
				const promises: Promise<Blob>[] = [];
				promises.push(
					new Promise((resolve) => {
						canvas.toBlob(
							(blob) => {
								// Handle the compressed image. es. upload or save in local state
								displayInfo("Original file", file);
								if (blob) {
									displayInfo("Compressed file", blob);
									resolve(blob);
								}
							},
							MIME_TYPE,
							QUALITY
						);
					})
				);
				Promise.all(promises).then((value) => {
					resolve2(value[0]);
				})
			}
		};
	});
}
function calculateSize(img: HTMLImageElement, maxWidth: number, maxHeight: number) {
	let width = img.width;
	let height = img.height;

	// calculate the width and height, constraining the proportions
	if (width > height) {
		if (width > maxWidth) {
			height = Math.round((height * maxWidth) / width);
			width = maxWidth;
		}
	} else {
		if (height > maxHeight) {
			width = Math.round((width * maxHeight) / height);
			height = maxHeight;
		}
	}
	return [width, height];
}

// Utility functions for demo purpose

async function displayInfo(label: string, file: Blob) {
	const p = document.createElement("p");
	p.innerText = `${label} - ${readableBytes(file.size)}`;
	console.log(p);
}

async function readableBytes(bytes: number) {
	const i = Math.floor(Math.log(bytes) / Math.log(1024)),
		sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
}