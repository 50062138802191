























































import myFormInterface from "@/types/myFormInterface";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonChangeRead from "../ButtonChangeRead.vue";
import EditDocument from "../EditDocument.vue";
import ShowDocumentDetail from "../ShowDocument/ShowDocumentDetail.vue";
import ButtonChangeFolder from "./ButtonChangeFolder.vue";
import ButtonRemoveDocument from "./ButtonRemoveDocument.vue";

export default (Vue as myFormInterface).extend({
  components: {
    EditDocument,
    ButtonChangeRead,
    ShowDocumentDetail,
    ButtonRemoveDocument,
    ButtonChangeFolder,
  },
  data() {
    return {
      page: 1,
      folderName: "",
      folderId: 0 as number,
      folderDescription: "Sin descripción",
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false,
        },
        { text: "Número", sortable: false, align: "start", value: "number" },
        {
          text: "Descripción",
          sortable: false,
          align: "start",
          value: "description",
        },
        {
          text: "Tipo de Documento",

          sortable: false,
          align: "start",
          value: "document_type.name",
        },
        {
          text: "Depto.",
          sortable: false,
          align: "start",
          value: "location.name",
        },
        { text: "Fecha", sortable: false, align: "start", value: "created_at" },
        {
          text: "Última Actualización",
          sortable: false,
          align: "start",
          value: "updated_at",
        },
        { text: "Acciones", sortable: false, align: "start", value: "action" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getDocuments: "documents/getFolderDocument",
      getLoading: "documents/getLoading",
    }),
  },
  watch: {
    '$route.params.id': function () {
      this.page=1;      
      this.$store.commit("documents/setPageFolderDocument", 1);
      this.setDocument({user_id:this.getCurrentUserLoggedIn().id,folder_id:this.$route.params.id});
      this.folderId = Number(this.$route.params.id);
    },
    '$route.params.folderName': function () {
      this.folderName = this.$route.params.folderName;
    },
    '$route.params.folderDescription': function () {
      this.folderDescription = this.$route.params.folderDescription;
    }
  },
  mounted() {
    //TODO (Ruben) Cuando se refresca la ventana o se limpia el cache, se reinicia el store por ende se pierde el moduleTab
    this.setDocument({user_id:this.getCurrentUserLoggedIn().id,folder_id:this.$route.params.id});
    this.folderName = this.$route.params.folderName;
    this.folderDescription = this.$route.params.folderDescription;
    this.folderId = Number(this.$route.params.id);
  },
  methods: {
    stateColor(state: string) {
      let classColor = "";
      switch (state) {
        case "recibido":
          classColor = "blue lighten-4";
          break;
        case "enviado":
          classColor = "yellow lighten-4";
          break;         
        default:
          classColor=""
          break;
      }
      return classColor
    },
    changePage() {
      this.$store.commit("documents/setPageFolderDocument", this.page);
      this.setDocument({user_id:this.getCurrentUserLoggedIn().id,folder_id:this.$route.params.id});
    },
    ...mapActions({
      setDocument: "documents/getFolderDocument",
    }),
  },
});
