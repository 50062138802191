








































import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import DataSendPurchase from "./DataSendPurchase.vue";

import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import { PurchaseRequestStateService } from "@/services/models/purchaseRequest/purchaseRequestState";

import myFormInterface from "@/types/myFormInterface";
import PurchaseHistory from "@/types/purchaseRequest/purchaseHistory";
import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";
import PurchaseRequestState from "@/types/purchaseRequest/purchaseState";
import User from "@/types/user/user";

import Vue from "vue";
export default (Vue as myFormInterface).extend({
  components: { InputSelectUsers, DataSendPurchase },
  props: {
    purchaseProp: {
      type: Object,
    },
    dataSend: {
      type: Object,
    },
    recharge:{
      type:Function
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    checkState(state: string) {
      console.log(state);
      if (state != "creado" && state != "pendiente") return true;
    },
    async save() {
      this.displayLoader(true);
      const inputUsers = this.$refs.selectUser.usersSelects[0];
      try {
        let data = {
          state: this.dataSend.state,
          step: this.dataSend.step,
          user: inputUsers,
        };
        const states = await PurchaseRequestStateService.getByPurchaseRequestId(
          this.purchaseProp.id
        );
        let newState = states.filter((state: { user_id: number }) => {
          return state.user_id == inputUsers.id;
        });

        if (newState.length == 0) await this.postPurchaseRequestState(data);
        if (newState.length != 0)
          await this.putPurchaseRequestState({
            ...data,
            id: newState[newState.length - 1].id,
          });

        let currentUserState = states.filter((state: { user_id: number }) => {
          return state.user_id == this.getCurrentUserLoggedIn().id;
        });
        console.log(currentUserState);

        await PurchaseRequestStateService.update(
          currentUserState[currentUserState.length - 1].id,
          {
            ...currentUserState[currentUserState.length - 1],
            state: "enviado",
          }
        );
        await this.putPurchaseRequest();
        await this.postPurchaseHistory({ user: inputUsers });

        this.recharge();
        this.displayToast({
          type: "success",
          message: "Se envio la solicitud sin problemas",
        });
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Hubo un error al guardar, favor de reintentar",
        });
      } finally {
        this.displayLoader(false);
      }
    },

    async putPurchaseRequest() {
      let dataPurcaseRequest: PurchaseRequest = {
        ...this.purchaseProp,
        state: "enviado",
      };
      await PurchaseRequestService.update(
        this.purchaseProp.id,
        dataPurcaseRequest
      );
      return;
    },

    async postPurchaseRequestState(payLoad: {
      state: string;
      step: string;
      user: User;
    }) {
      let dataPurchaseState: PurchaseRequestState = {
        id: 0,
        purchase_request_id: this.purchaseProp.id,
        state: payLoad.state,
        step: payLoad.step,
        user_id: payLoad.user.id,
        user_location_id: payLoad.user.users_locations?.[0].location_id || 0,
      };
      await PurchaseRequestStateService.create(dataPurchaseState);
    },

    async putPurchaseRequestState(payLoad: {
      id: number;
      state: string;
      step: string;
      user: User;
    }) {
      let dataPurchaseState: PurchaseRequestState = {
        id: 0,
        purchase_request_id: this.purchaseProp.id,
        state: payLoad.state,
        step: payLoad.step,
        user_id: payLoad.user.id,
        user_location_id: payLoad.user.users_locations?.[0].location_id || 0,
      };

      await PurchaseRequestStateService.update(payLoad.id, dataPurchaseState);
    },

    async postPurchaseHistory(payLoad: { user: User }) {
      let dataPurchaseHistory: PurchaseHistory = {
        id: 0,
        purchase_request_id: this.purchaseProp.id,
        state: "solicitud enviada",
        user_origin_id: this.getCurrentUserLoggedIn().id,
        user_destination_id: payLoad.user.id,
      };
      await PurchaseHistoryService.create(dataPurchaseHistory);
    },
  },
});
