import { DispatchLocationService } from "@/services/models/dispatch/dispatch_location";
import Dispatch from "@/types/dispatch/dispatch";
import { Commit } from "vuex";

interface stateInterface {
    dispatch: Dispatch[],
    loading: boolean,
}
const moduleDispatchLocation = {
    namespaced: true,
    state: {
        dispatch: [],
        loading: false,
    } as stateInterface,
    getters: {
        getDispatchLocation(state: stateInterface): Dispatch[] {
            return state.dispatch;
        },
        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setDispatch(state: stateInterface, dispatch: Dispatch[]): void {
            state.dispatch = dispatch;
        },
        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getDispatchLocation({ commit }: { commit: Commit},id:number): Promise<void> {
            commit('setLoading', true);
            const dispatch = await DispatchLocationService.getStablishmentId(id);
            commit("setDispatch", dispatch);
            commit('setLoading', false);
        },
    }
}

export default moduleDispatchLocation;