


























import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    purchaseProp: {
      type: Object,
    },
  },
  data(){
      return{
          dialog:false
      }
  },
  methods: {
    ...mapActions({      
      recharge: "rechargeTablesPurchaseRequest/getRechargeTables",
    }),
    async deletePurchase() {
      this.displayLoader(true);
      await PurchaseRequestService.remove(this.purchaseProp.id);
      this.recharge();      
      this.dialog=false;
      this.displayLoader(false);
    },
  },
});
