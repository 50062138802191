




















































import Module from "@/types/user/module";
import { ModuleService } from "@/services/models/user/module";
import Vue from "vue";
import UserModule from "@/types/user/userModule";
import myFormInterface from "@/types/myFormInterface";
export default (Vue as myFormInterface).extend({
  name: "UserModules",
  data() {
    return {
      modules: [] as Module[],
      loadingModule: false as boolean,
      usersModules: [] as UserModule[],
      module_id: 0 as number,
      headers: [
        {
          text: "Módulo",
          sortable: false,
          value: "module.name",
        },
        {
          text: "Permisos",
          sortable: false,
          value: "permission",
        },
        {
          text: "Eliminar",
          sortable: false,
          value: "delete",
        },
      ],
    };
  },
  async mounted() {
    this.loadingModule = true;
    this.modules = await ModuleService.getAll();
    this.loadingModule = false;
  },
  methods: {
    deleteModule(userModule: UserModule) {
      this.usersModules = this.usersModules.filter(
        (userModuleValue) => userModuleValue.module_id != userModule.module_id
      );
    },
    addModule() {
      if (!this.module_id) {
        this.displayToast({
          type: "warning",
          message: "Por favor seleccionar un módulo",
        });
        return;
      }
      const isModuleAdded = this.usersModules.find(
        (userModule) => userModule.module_id == this.module_id
      );
      if (isModuleAdded) {
        this.displayToast({
          type: "warning",
          message: "El usuario ya tiene asociado este módulo",
        });
        return;
      }
      const moduleSelected = this.modules.find(
        (module) => module.id == this.module_id
      );
      this.usersModules.push({
        id: 0,
        name: "test",
        module_id: this.module_id,
        user_id: 0,
        module: moduleSelected,
      });
      this.$emit("loadUsersModules", this.usersModules);
      this.$refs.myForm.reset();
    },
  },
});
