import DocumentDetailChange from '@/types/documents/documentDetailChanges'
import { BaseService } from '../../base'
export class DocumentDetailChangesService extends BaseService<DocumentDetailChange>() {
    static get entity (): string {
        return 'document_detail_change'
    }
    static get responseName (): string {
        return 'document_detail_change'
    }
    static async getByDocumentDetail(document_detail_id:number): Promise<DocumentDetailChange[]> {
        return this.submit<DocumentDetailChange[]>({
            method: 'get',
            path: `find_by_document_detail/${document_detail_id}`
        });
    }
}