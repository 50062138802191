



















import { generatePDFSingleTicket } from "@/reports/reportSingleTicket";
import Vue from "vue";
export default Vue.extend({
  props: {
    ticketId: {
      type: Number,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      dateStart: null,
      dateEnd: null,
    };
  },
  methods: {
    async generateReport() {
      this.isLoading = true;
      try {
        await generatePDFSingleTicket({
          ticketId: this.ticketId,
        });
      } catch (error) {
        this.displayToast({
          type: 'error',
          message: 'Ha ocurrido un error al generar el reporte'
        });
      }
      this.isLoading = false;
    },
  },
});
