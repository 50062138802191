
























































import InputSelectLocation from "@/components/document/ShowDocument/panelDetail/InputSelectLocation.vue";
import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import ModalCreateUser from "@/components/manteiners/maintainerUsers/createUser/ModalCreateUser.vue";
import { GroupService } from "@/services/models/group/group";
import { TicketTypeService } from "@/services/models/ticket/ticketType";
import myFormInterface from "@/types/myFormInterface";
import Ticket from "@/types/tickets/tickets";
import TicketType from "@/types/tickets/tickets_type";
import LocationInterface from "@/types/user/location";
import Vue from "vue";
export default (Vue as myFormInterface).extend({
  name: "FormTicket",
  components: { InputSelectUsers, ModalCreateUser, InputSelectLocation },
  props: {
    selectUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      types: [] as Array<TicketType>,
      group: [] as Array<GroupService>,
      ticket: {
        user_id: 0,
        location_id: 0,
        subject: "",
        title: "",
        ticket_type_id: 1,
        state: "solicitado",
        group_id: 1,
        created_by_id: this.getCurrentUserLoggedIn().id,

      } as Ticket,
    };
  },
  mounted() {
    this.loadData();
    if (!this.selectUser) {
      this.ticket.user_id = this.getCurrentUserLoggedIn().id;
      this.ticket.location_id = this.getUserLocation().location_id;
    }
  },
  methods: {
    changeSelect() {
      this.ticket.user_id = this.$refs.selectedUser.usersSelects?.[0].id;
      this.$refs.selectedLocation.selectLocation(this.$refs.selectedUser.usersSelects[0].users_locations?.[0].location as LocationInterface);
      this.changeLocation();
    },
    changeLocation() {
      if (this.$refs.selectedLocation.selectedLocations.length) {
        this.ticket.location_id = this.$refs.selectedLocation.selectedLocations?.[0].id;
      } else {
        this.ticket.location_id = 0;
      }
    },
    async loadData() {
      this.displayLoader(true);
      this.group = await GroupService.getByStablishmentId(
        this.getCurrentUserLoggedIn().stablishment_id
      );
      this.types = await TicketTypeService.getByGroupId(this.ticket.group_id);
      this.displayLoader(false);
    },
    async ChangeGroup() {
      this.displayLoader(true);
      this.types = await TicketTypeService.getByGroupId(this.ticket.group_id);
      this.displayLoader(false);
    },
    validateForm() {
      if (this.ticket.location_id == 0 || this.ticket.user_id == 0) {
        return false;
      }
      return this.$refs.formCreate.validate();
    },
  },
});
