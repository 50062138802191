import { Commit } from "vuex";

interface stateInterface {
	showLoader: boolean,	
}
const moduleLoader = {
	namespaced: true,
	state: {
		showLoader: false,		
	} as stateInterface,
	getters: {
		getShowLoader(state: stateInterface): boolean {
			return state.showLoader;
		}
	},
	mutations: {
		setLoader(state: stateInterface,payLoad:boolean) : void {
			state.showLoader=payLoad;
		},
	},
    actions:{
        getState({commit}: {commit: Commit}, state:boolean): void{
            commit('setLoader', state);            
        }
    }
}

export default moduleLoader;