












































import { CdpService } from "@/services/models/purchaseRequest/cdp";
import { PurchaseBuyerHistoryService } from "@/services/models/purchaseRequest/purchaseBuyerHistory";
import { PurchaseBuyerStateService } from "@/services/models/purchaseRequest/purchaseBuyerState";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    CdpProps: {
      required: true,
      type: Object,
    },
    PurchaseBuyerProps: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      loadingOverlay: false as boolean,
      observations: null as string | null,
    };
  },
  methods: {
    ...mapActions({
      setPending: "purchaseBuyer/getPurchaseBuyerPending",
      setReady: "purchaseBuyer/getPurchaseBuyerReady",
    }),
    async loadData() {
      this.setPending({
        userId: this.getCurrentUserLoggedIn().id,
        state: "pendiente",
        step: "financialManager",
      });
      this.setReady({
        userId: this.getCurrentUserLoggedIn().id,
        state: "finalizado",
        step: "financialManager",
      });
    },
    async rejectFirm() {
      this.loadingOverlay = true;
      try {
        //Modificar estado del CDP
        await CdpService.update(this.CdpProps.id, {
          ...this.CdpProps,
          state: "rechazado",
        });
        await this.VerifyAfterUpdateBuyerState();
        
        //Agregar history
        await PurchaseHistoryService.create({
          id: 0,
          purchase_request_id: this.PurchaseBuyerProps.purchase_request.id,
          state: "rechazo de Cdp",
          user_origin_id: this.getCurrentUserLoggedIn().id,
          user_destination_id: this.getCurrentUserLoggedIn().id,
        });
        await PurchaseBuyerHistoryService.create({
          id: 0,
          state: "rechazo de Cdp",
          user_origin_id: 0,
          user_destination_id: 0,
          purchase_buyer_id: this.PurchaseBuyerProps.id,
        });
        this.displayToast({
          type: "success",
          message: "La firma se ha rechazado con éxito",
        });
        this.loadData();
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Lo sentimos ha ocurrido un error",
        });
      }
      this.loadingOverlay = false;
    },
    async VerifyAfterUpdateBuyerState() {
      let validateStateCreate = true;
      const CDPS = await CdpService.getPurchaseBuyerId(
        this.PurchaseBuyerProps.id
      );
      CDPS.forEach((cdp) => {
        if (cdp.state == "creado") {
          validateStateCreate = false;
        }
      });
      if (validateStateCreate) {
        const PURCHASEBUYERSTATE =
          await PurchaseBuyerStateService.getByPurchaseBuyerIdUserId(
            this.PurchaseBuyerProps.id,
            this.getCurrentUserLoggedIn().id
          );
        if (PURCHASEBUYERSTATE.length) {
          await PurchaseBuyerStateService.update(PURCHASEBUYERSTATE[0].id, {
            ...PURCHASEBUYERSTATE[0],
            state: "finalizado",
          });
        }
      }
    },
  },
});
