




































import CardArticles from "@/components/purchaseRequest/cards/CardArticles.vue";
import CardPurchaseRequest from "@/components/purchaseRequest/cards/CardPurchaseRequest.vue";
import CardPurchaseRequestFiles from "@/components/purchaseRequest/cards/CardPurchaseRequestFiles.vue";
import Vue from "vue";
export default Vue.extend({
  components: {
    CardPurchaseRequest,
    CardArticles,
    CardPurchaseRequestFiles,
  },
  props: {
    purchaseProp: {
      type: Object,
    },
  },
  data() {
    return {
      tab: null,
    };
  },
});
