



























































import Vue from "vue";

import { DocumentTypeService } from "@/services/models/document/document_type";
import { DocumentService } from "@/services/models/document/document";
import { DocumentStateService } from "@/services/models/document/documentState";
import {RemitentService} from '@/services/models/document/remitent';

import DocumentType from "@/types/documents/documentType";
import myFormInterface from "@/types/myFormInterface";

import { mapActions } from "vuex";
import Document from "@/types/documents/document";
import Remitent from "@/types/documents/remitent";
import { DocumentStateLocationService } from "@/services/models/document/documentStateLocation";

export default (Vue as myFormInterface).extend({
  data() {
    return {
      dialog: false as boolean,
      listDocumentType: [] as DocumentType[],
      listRemitents:[] as Remitent[],
      isLoading: false as boolean,
      document: {} as Document,
    };
  },
  methods: {
    ...mapActions({
      setDocument: "documents/getMyDocument",
    }),
    clearData() {
      this.cleanForm(this, "formCreate");
    },
    async loadData() {
      this.isLoading = true;
      this.listDocumentType = await DocumentTypeService.getAll();
      this.listRemitents= await RemitentService.getAll();
      this.isLoading = false;
    },
    async saveDocument() {      
      if (!this.$refs.formCreate.validate()) {
        this.displayToast({
          type: "error",
          message: "Favor de llenar todos los campos requeridos",
        });
        return;
      }
      try {
        this.isLoading = true;
        let data: Document = {
          ...this.document,
          user_id: this.getCurrentUserLoggedIn().id,          
          location_id: this.getCurrentUserLoggedIn().users_locations?.[0].location_id || 0,
        };
        let response = await DocumentService.create(data);
        if (this.getProfile() == "opartes") {
          await DocumentStateLocationService.create({
            id: 0,
            location_id: this.getUserLocation().id,
            document_id:response.id,
            state:'creado',
            state_read:'leido'
          })
        }
        await DocumentStateService.create({
          id: 0,
          user_id:this.getCurrentUserLoggedIn().id,
          document_id:response.id,
          state:'creado',
          state_read:'leido'
        });
        this.setDocument({ id: this.getCurrentUserLoggedIn().id});
        this.dialog = false;
      } catch (error) {
        this.displayToast({
          type: 'error',
          message: 'Los sentimos, ha ocurrido un error'
        });
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.clearData();
        this.loadData();
      }
    },
  },
});
