










import { PurchaseRequestDetailService } from "@/services/models/purchaseRequest/purchaseRequestDetail";
import PurchaseRequestDetail from "@/types/purchaseRequest/purchaseRequestDetail";
import Vue from "vue";
export default Vue.extend({
  props:{
    purchaseProp:{
      type:Object
    }
  },
  data() {
    return {
      getItems: [] as Array<PurchaseRequestDetail>,
      isLoading: false,
      headers: [
        {
          text: "Articulo",
          sortable: false,
          value: "article.name",
        },
        {
          text: "Observación",
          sortable: false,
          value: "observation",
        },
        {
          text: "Cantidad",
          sortable: false,
          value: "cant",
        },
        {
          text: "Precio Apróx",
          sortable: false,
          value: "price",
        },
      ],
    };
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.getItems = await PurchaseRequestDetailService.getByPurchaseId(this.purchaseProp.id);
      this.isLoading = false;
    },
  },
  mounted() {
    this.loadData();
  },
});
