import { RoleService } from "@/services/models/user/role";
import Role from "@/types/user/role";
import { Commit } from "vuex";

interface stateInterface {
    roles: Role[],
    loading: boolean,
}
const moduleUser = {
    namespaced: true,
    state: {
        roles: [],
        loading: false,
    } as stateInterface,
    getters: {
        getRoles(state: stateInterface): Role[] {
            return state.roles;
        },
        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        loadRoles(state: stateInterface, roles: Role[]): void {
            state.roles = roles;
        },
        changeLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getRoles({commit}: {commit: Commit}): Promise<void> {
            commit("changeLoading", true);
            const role = await RoleService.getAll();                        
            commit("loadRoles", role);
            commit("changeLoading", false);
        }        
    }
}

export default moduleUser;