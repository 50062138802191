interface stateInterface {
    show: boolean,
}
const moduleToast = {
    namespaced: true,
    state: {
        show: false,
    } as stateInterface,
    getters: {
        getRelogin(state: stateInterface): boolean {
            return state.show;
        }
    },
    mutations: {
        setRelogin(state: stateInterface):void {
            state.show = true;
        },
        resetRelogin(state: stateInterface) {
            state.show = false;
        }
    }
}

export default moduleToast;