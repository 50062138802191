










































































import { StablishmentService } from "@/services/models/stablishment";
import myFormInterface from "@/types/myFormInterface";
import UserLocation from "@/types/user/userLocation";
import { PDFDocument } from "pdf-lib";
import { base64ArrayBuffer } from "@/others/toBase64";
import { callSha256, setQrCode } from "./functionsToFirm";
import Vue from "vue";
import { UserService } from "@/services/models/user/user";
import { mapActions } from "vuex";
import RejectFirm from "./RejectFirm.vue";
import VueHtml2pdf from "vue-html2pdf";
/* eslint-disable */
// @ts-ignore
import html2pdf from "html2pdf.js";
/* eslint-enable */
import SuccessPage from "@/components/document/firmDocument/SuccessPage.vue";
import SelectProfile from "@/components/document/firmDocument/SelectProfile.vue";
import Loader from "@/components/document/firmDocument/Loader.vue";
import Otp from "@/components/document/firmDocument/Otp.vue";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
import { PurchaseBuyerStateService } from "@/services/models/purchaseRequest/purchaseBuyerState";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
export default (Vue as myFormInterface).extend({
  components: {
    RejectFirm,
    VueHtml2pdf,
    SuccessPage,
    SelectProfile,
    Loader,
    Otp,
  },
  props: {
    CdpProps: {
      required: true,
      type: Object,
    },
    PurchaseBuyerProps: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      invalidStep: 0 as number,
      step: 1 as number,
      otp: null as number | null,
      loading: false as boolean,
      userLocationSelected: {} as UserLocation,
      progress: 0 as number,
      errorMessage: "" as string,
      loadingOverlay: false as boolean,
      contentTemplate: "" as string,
    };
  },
  methods: {
    ...mapActions({
      setPending: "purchaseBuyer/getPurchaseBuyerPending",
      setReady: "purchaseBuyer/getPurchaseBuyerReady",
      setCdp: "cdp/getCdp",
    }),
    async loadData() {
      this.setPending({
        userId: this.getCurrentUserLoggedIn().id,
        state: "pendiente",
        step: "financialManager",
      });
      this.setReady({
        userId: this.getCurrentUserLoggedIn().id,
        state: "finalizado",
        step: "financialManager",
      });
      this.setCdp(this.PurchaseBuyerProps.id);
    },
    changeStep(step: number) {
      this.step = step;
    },
    validateStep(isValid: boolean, step: number): void {
      if (!isValid) {
        this.invalidStep = step;
      }
    },
    dataURLtoFile(dataurl: any, filename: any) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    async addFirmToPdf(file: string): Promise<string> {
      let pngImageBytes: string = await this.$refs.qr?.children[1].currentSrc;
      const stablishmentId = this.getCurrentUserLoggedIn().stablishment_id;
      const stablishment = await StablishmentService.getById(stablishmentId);
      const pdfDoc = await PDFDocument.load(file);
      const pngImage = await pdfDoc.embedPng(pngImageBytes);
      const pngDims = pngImage.scale(0.1);
      const pages = pdfDoc.getPages();
      const numberOfPages = pdfDoc.getPageCount();
      const lastPage = pages[numberOfPages - 1];
      const { width, height } = lastPage.getSize();
      let user = this.getCurrentUserLoggedIn();
      let name = user.name;
      let apepat = user.pather_lastname;
      let apemat = user.mother_lastname;
      lastPage.drawText(stablishment.name, {
        x: width / 2 - 65,
        y: height / 14 - 21,
        size: 9,
      });

      lastPage.drawText(
        `${this.userLocationSelected.position?.name} ${this.userLocationSelected.location?.name}`,
        {
          x: width / 2 - 65,
          y: height / 14 - 6,
          size: 9,
        }
      );

      lastPage.drawText(name + " " + apepat + " " + apemat, {
        x: width / 2 - 65,
        y: height / 14 + 9,
        size: 10,
      });

      lastPage.drawText("Este documento ha sido firmado electrónicamente por", {
        x: width / 2 - 65,
        y: height / 14 + 24,
        size: 10,
      });
      lastPage.drawImage(pngImage, {
        x: width - width / 7,
        y: height / 14 + -20,
        width: pngDims.width * 2,
        height: pngDims.height * 2,
      });
      const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: false });
      return pdfBytes;
    },
    async generateQr(): Promise<void> {
      const user = await UserService.getById(this.getCurrentUserLoggedIn().id);
      // let user = await getUser(this.$http, this.getCurrentUserLoggedIn().id);
      let qrContent = `Firmado por Servicio de Salud Magallanes, ${user.name} ${user.pather_lastname} ${user.mother_lastname} rut: ${user.run}`;
      /* eslint-disable */
      setQrCode(this.$refs.qr, qrContent);
      // new QRCode(this.$refs.qr, qrContent);
      /* eslint-enable */
    },
    async prepareData(): Promise<{
      base64WithFirm: string;
      token: string;
      checksum: string;
    }> {
      const CDP = await CdpService.getById(this.CdpProps.id);

      this.progress = 20;
      let base64File = "";
      if (CDP.cdp_file) {
        const DOWNLOADEDFILE = await CdpService.downloadFile(CDP.id);
        const blob = new Blob([DOWNLOADEDFILE.data], {
          type: "application/pdf",
        });

        const arrayBufferFile = await blob.arrayBuffer();
        base64File = base64ArrayBuffer(arrayBufferFile);
      }
      //TODO Comente esto porque no se q vegas hace(Y)
      // else {
      //   if (documentDetail.file_generated) {
      //     this.contentTemplate = documentDetail.file_generated;
      //   }
      //   let pdfContent = this.$refs.html2Pdf.$refs.pdfContent;
      //   let options = this.$refs.html2Pdf.setOptions();
      //   let result = await html2pdf().set(options).from(pdfContent).outputPdf();
      //   base64File = btoa(result);
      // }
      console.log(base64File);
      /* eslint-disable */
      const base64WithFirm = await this.addFirmToPdf(base64File);
      this.progress = 30;
      let token = (
        await UserService.getTokenKey(
          this.getCurrentUserLoggedIn().run.split("-")[0]
        )
      ).data;
      this.progress = 40;
      let checksum: string = callSha256(
        Uint8Array.from(atob(base64WithFirm), (c) => c.charCodeAt(0))
      );
      /* eslint-enable */
      return {
        base64WithFirm,
        token,
        checksum,
      };
    },
    async firmFile(
      token: string,
      base64: string,
      checksum: string,
      otp: string
    ) {
      let resp = (
        await CdpService.getFileFirmed({
          token,
          content: base64,
          checksum,
          otp,
        })
      ).data;
      let isValid = false;
      if (resp.files) {
        // console.log(resp.files[0].status);
        if (resp.files[0].status == "OK") {
          isValid = true;
        }
      }
      let fileResponse: string;
      if (isValid) {
        fileResponse = resp.files[0].content;
      } else {
        let errorMessage;
        if (resp.files) {
          errorMessage = resp.files[0].status ? resp.files[0].status : "";
        } else {
          errorMessage = resp.error ? " - " + resp.error : "";
        }
        this.errorMessage = errorMessage;
        throw new Error(`Falla del API ${errorMessage}`);
      }
      return fileResponse;
    },
    async generateFirmedPdf(otp: string) {
      try {
        this.errorMessage = "";
        this.loading = true;
        this.progress = 0;
        await this.generateQr();
        this.progress = 10;
        let { base64WithFirm, token, checksum } = await this.prepareData();
        if (!token || !otp) {
          return;
        }
        let file = await this.firmFile(token, base64WithFirm, checksum, otp);
        await CdpService.updateImage({
          base64: file,
          id: this.CdpProps.id,
        });
        this.progress = 70;
        this.progress = 80;
        await this.VerifyAfterUpdateBuyerState();
        await CdpService.update(this.CdpProps.id, {
          ...this.CdpProps,
          state: "firmado",
          cdp_file_firmed_user_id: this.getCurrentUserLoggedIn().id,
        });
        this.progress = 90;
        await PurchaseHistoryService.create({
          id: 0,
          purchase_request_id: this.PurchaseBuyerProps.purchase_request.id,
          state: `CDP ID ${this.CdpProps.id}, del registro ${this.CdpProps.purchase_buyer_id}, Firmado`,
          user_origin_id: this.getCurrentUserLoggedIn().id,
          user_destination_id: this.getCurrentUserLoggedIn().id,
        });
        this.progress = 100;
        this.step = 3;
        //Esto es por que no podía recargar el documentDetail sin que desapareciera el modal y no se alcanzaba a mostrar la pantalla de éxito
        setTimeout(() => {
          this.loadData();
        }, 2000);
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.displayToast({
          type: "error",
          message: "Lo sentimos, ha ocurrido un error. Favor volver a intentar",
        });
      }
    },
    async VerifyAfterUpdateBuyerState() {
      let validateStateCreate = true;
      const CDPS = await CdpService.getPurchaseBuyerId(
        this.PurchaseBuyerProps.id
      );
      CDPS.forEach((cdp) => {
        if (cdp.state == "creado") {
          validateStateCreate = false;
        }
      });
      if (validateStateCreate) {
        const PURCHASEBUYERSTATE =
          await PurchaseBuyerStateService.getByPurchaseBuyerIdUserId(
            this.PurchaseBuyerProps.id,
            this.getCurrentUserLoggedIn().id
          );
        if (PURCHASEBUYERSTATE.length) {
          await PurchaseBuyerStateService.update(PURCHASEBUYERSTATE[0].id, {
            ...PURCHASEBUYERSTATE[0],
            state: "finalizado",
          });
        }
      }
    },
  },
});
