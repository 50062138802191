
















































import DocumentDetail from "@/types/documents/documentDetail";
import History from "@/types/documents/history";
import DocumentDetailInvalid from "@/types/documents/documentDetailInvalid";
import myFormInterface from "@/types/myFormInterface";

import Vue from "vue";
import { HistoryService } from "@/services/models/document/history";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
import { DocumentDetailInvalidService } from "@/services/models/document/documentDetailInvalid";
import { mapActions } from "vuex";

export default (Vue as myFormInterface).extend({
  props: {
    detailProp: {
      required: true,
      type: Object,
    },
    documentProp: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      description: "" as string,
    };
  },
  methods: {
    ...mapActions({
      getDocumentsDetail: "documentDetail/getByDocumentId",
    }),
    async invalidDetail() {
      if (this.$refs.formInvalid.validate()) {
        try {
          this.isLoading = true;
          let dataDocumentDetail: DocumentDetail = {
            ...this.detailProp,
            state: "invalido",
          };
          let dataHistory: History = {
            document_detail_id: this.detailProp.id,
            user_origin_id: this.getCurrentUserLoggedIn().id,
            user_destination_id: this.getCurrentUserLoggedIn().id,
            location_origin_id: this.getUserLocation().location_id,
            location_destination_id: 1,
            description: this.description,
            state: "invalidado",
          };
          let dataDocumentDetailInvalid: DocumentDetailInvalid = {
            description: this.description,
            user_id: this.getCurrentUserLoggedIn().id,
            document_detail_id: this.detailProp.id,
          };
          await this.postHistory(dataHistory);
          await this.putDocumentDetail(dataDocumentDetail);
          await this.invalidDocumentDetail(dataDocumentDetailInvalid);
          this.getDocumentsDetail(this.documentProp.id);
          this.dialog = false;
        } catch (error) {
          console.log(error);
          throw error;
        } finally {
          this.isLoading = false;
        }
      }
    },
    async postHistory(dataHistory: History): Promise<History> {
      return await HistoryService.create(dataHistory);
    },
    async putDocumentDetail(
      dataDocumentDetail: DocumentDetail
    ): Promise<DocumentDetail> {
      return await DocumentDetailService.update(
        this.detailProp.id,
        dataDocumentDetail
      );
    },
    async invalidDocumentDetail(
      dataDocumentDetailInvalid: DocumentDetailInvalid
    ): Promise<DocumentDetailInvalid> {
      return await DocumentDetailInvalidService.create(
        dataDocumentDetailInvalid
      );
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.cleanForm(this, "formInvalid");
      }
    },
  },
});
