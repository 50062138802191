import { BaseService } from '../base'
import Stablishment from '@/types/user/stablishment';

export class StablishmentService extends BaseService<Stablishment>() {
    static get entity(): string {
        return 'stablishment'
    }

    static get responseName(): string {
        return 'stablishment'
    }

}
