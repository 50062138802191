






















































import { dataByStateStep } from "@/services/models/purchaseRequest/purchaseRequest";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import GroupButtons from "../buttonsPurchaseRequest/GroupButtons.vue";
import DetailTablePurchaseRequest from "../DetailTablePurchaseRequest.vue";
export default Vue.extend({
  components: {
    DetailTablePurchaseRequest,
    GroupButtons,
  },
  data() {
    return {
      page: 1,
      dataStateStep: {
        user_id: this.getCurrentUserLoggedIn().id,
        state: "pendiente",
        step: "supplyManager",
      } as dataByStateStep,
      headers: [
        {
          text: "Id",
          sortable: false,
          value: "id",
        },
        {
          text: "Estado",
          sortable: false,
          value: "state",
        },
        {
          text: "Creación",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Modificación",
          sortable: false,
          value: "updated_at",
        },
        {
          text: "Acciones",
          sortable: false,
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPending: "supplyManager/getPending",
      getLoading: "supplyManager/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setPending: "supplyManager/getPending",
      setReady: "supplyManager/getReady",
    }),
    changePage() {
      this.$store.commit("supplyManager/setPagePending", this.page);
      this.setPending({ ...this.dataStateStep });
    },
    recharge() {
      this.setPending({
        user_id: this.getCurrentUserLoggedIn().id,
        state: "pendiente",
        step: "supplyManager",
      });
      this.setReady({
        user_id: this.getCurrentUserLoggedIn().id,
        state: "enviado",
        step: "supplyManager",
      });
    },
  },
  mounted() {
    this.changePage();
  },
});
