import { HistoryService } from "@/services/models/document/history";
import History from "@/types/documents/history";
import { Commit } from "vuex";

interface stateInterface {
    history: History[],
    historyByDetailId:History[],
    loading: boolean,
}
const moduleHistory = {
    namespaced: true,
    state: {
        history: [],
        historyByDetailId:[],
        loading: false,
    } as stateInterface,
    getters: {
        getHistories(state: stateInterface): History[] {
            return state.history;
        },
        getDocumentDetailId(state: stateInterface): History[] {
            return state.historyByDetailId;
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setHistory(state: stateInterface, history: History[]): void {
            state.history = history;
        },
        setDocumentDetailId(state: stateInterface, historyByDetailId: History[]): void {
            state.historyByDetailId = historyByDetailId;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getHistories({commit}: {commit: Commit}): Promise<void> {
            commit('setLoading', true);
            const HISTORY = await HistoryService.getAll();                        
            commit("setHistory", HISTORY);
            commit('setLoading', false);
        },
        async getDocumentDetailId({commit}: {commit: Commit}, id:number): Promise<void> {
            commit('setLoading', true);
            const HISTORY = await HistoryService.getByDocumentDetailId(id);                        
            commit("setDocumentDetailId", HISTORY);
            commit('setLoading', false);
        }
    }
}

export default moduleHistory;