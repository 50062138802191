


































































import TableBuyerPending from "@/components/purchaseRequest/tables/tablesBuyer/TableBuyerPending.vue";
import TableBuyerReady from "@/components/purchaseRequest/tables/tablesBuyer/TableBuyerReady.vue";
import TableBuyerInProcess from "@/components/purchaseRequest/tables/tablesBuyer/TableBuyerInProcess.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import TableBuyerCdpFirm from "@/components/purchaseRequest/tables/tablesBuyer/TableBuyerCdpFirm.vue";
import Vue from "vue";
export default Vue.extend({
  components: {
    TemplateAdministrator,
    TableBuyerPending,
    TableBuyerReady,
    TableBuyerInProcess,
    TableBuyerCdpFirm
  },
  data() {
    return {
      tab: null,
    };
  },
});
