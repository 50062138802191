




































import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import { BuyerService } from "@/services/models/purchaseRequest/buyer";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import { PurchaseRequestStateService } from "@/services/models/purchaseRequest/purchaseRequestState";
import myFormInterface from "@/types/myFormInterface";
import Buyer from "@/types/purchaseRequest/buyer";
import PurchaseHistory from "@/types/purchaseRequest/purchaseHistory";
import User from "@/types/user/user";
import Vue from "vue";
import { mapActions } from "vuex";
import TableAssignBuyer from "./TableAssignBuyer.vue";
export default (Vue as myFormInterface).extend({
  components: { InputSelectUsers, TableAssignBuyer },
  props: {
    purchaseProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setBuyer: "buyer/getBuyerAssign",
      setPending: "unityManager/getPending",
      setReady: "unityManager/getReady",
    }),
    loadData() {
      this.setBuyer(this.purchaseProp.id);
      this.setPending({
        user_id: this.getCurrentUserLoggedIn().id,
        state: "pendiente",
        step: "unityManager",
      });
      this.setReady({
        user_id: this.getCurrentUserLoggedIn().id,
        state: "enviado",
        step: "unityManager",
      });
    },
    async save() {
      this.displayLoader(true);
      try {
        let users: User[] = this.$refs.selectedUser.usersSelects;
        for (let i = 0; i < users.length; i++) {
          let data: Buyer = {
            id: 0,
            user_id: this.getCurrentUserLoggedIn().id,
            purchase_request_id: this.purchaseProp.id,
            user_buyer_id: users[i].id,
            state: "pendiente",
          };
          await BuyerService.create(data);
          let dataHistory: PurchaseHistory = {
            id: 0,
            purchase_request_id: this.purchaseProp.id,
            state: "Comprador Asignado",
            user_origin_id: this.getCurrentUserLoggedIn().id,
            user_destination_id: users[i].id,
          };
          await PurchaseHistoryService.create(dataHistory);
        }

        await PurchaseRequestStateService.update(this.purchaseProp.purchases_requests_states[0].id, {
          ...this.purchaseProp.purchases_requests_states[0],
          state: "enviado",
        });
        this.displayToast({
          type: "success",
          message: "Se asignaron los compradores con éxito",
        });
        this.loadData();
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
