












































import ChipUserWithMenu from "@/components/general/ChipUserWithMenu.vue";
import { DocumentDetailCheckedService } from "@/services/models/document/documentDetailChecked";
import DocumentDetailChecked from "@/types/documents/documentDetailChecked";
import Vue from "vue";
export default Vue.extend({
  components: {
    ChipUserWithMenu,
  },
  props: {
    detailProp: {
      type: Object,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Usuario",
          sortable: false,
          align: "start",
          value: "user",
        },
        {
          text: "Enviado",
          sortable: false,
          align: "start",
          value: "created_at",
        },
        {
          text: "Actualización",
          sortable: false,
          align: "start",
          value: "updated_at",
        },
      ],
      menu: undefined,
      checkData: [] as DocumentDetailChecked[],
      isLoading: false as boolean,
    };
  },
  watch: {
    menu() {
      if (this.menu == true) {
        this.loadData();
      }
    },
  },
  methods: {
    iconForUser(item: DocumentDetailChecked) {
      let response = {
        icon: "",
        color: "",
      };
      if (item.state == "por visar") {
        response.icon = "fa-clock";
        response.color = "light-blue darken-3";
      }
      if (item.state == "visado") {
        response.icon = "fa-check-circle";
        response.color = "green darken-3";
      }
      if (item.state == "no pertinente") {
        response.icon = "fa-times-circle";
        response.color = "red";
      }
      return response;
    },
    compare(a: DocumentDetailChecked, b: DocumentDetailChecked) {
      if (a.state < b.state) {
        return 1;
      }
      if (a.state > b.state) {
        return -1;
      }
      return 0;
    },
    async loadData() {
      this.isLoading = true;
      this.checkData = await DocumentDetailCheckedService.getByDocumentDetailId(
        this.detailProp.id
      );
      this.checkData = this.checkData.sort(this.compare);
      this.isLoading = false;
    },
  },
});
