import { dataByStateStep, PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";
import ResponsePaginated from "@/types/responsePaginated";
import { Commit } from "vuex";

interface stateInterface {
    pending: ResponsePaginated<PurchaseRequest[]>,
    ready: ResponsePaginated<PurchaseRequest[]>,
    loading: boolean,
}
const moduleAuthorizer = {
    namespaced: true,
    state: {
        pending: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        ready: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },

        loading: false,
    } as stateInterface,
    getters: {
        getPending(state: stateInterface): ResponsePaginated<PurchaseRequest[]> {
            return state.pending;
        },
        getReady(state: stateInterface): ResponsePaginated<PurchaseRequest[]> {
            return state.ready
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setPending(state: stateInterface, pending: ResponsePaginated<PurchaseRequest[]>): void {
            state.pending = pending;
        },
        setReady(state: stateInterface, ready: ResponsePaginated<PurchaseRequest[]>): void {
            state.ready = ready;
        },

        setPagePending(state: stateInterface, page: number): void {
            state.pending.current_page = page;
        },
        setPageReady(state: stateInterface, page: number): void {
            state.ready.current_page = page;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getPending({ commit, state }: { commit: Commit, state: stateInterface }, payload: dataByStateStep): Promise<void> {
            commit('setLoading', true);
            const pending = await PurchaseRequestService.getByStateStep(payload, state.pending.current_page);
            commit("setPending", pending);
            commit('setLoading', false);
        },
        async getReady({ commit, state }: { commit: Commit, state: stateInterface }, payload: dataByStateStep): Promise<void> {
            commit('setLoading', true);
            const ready = await PurchaseRequestService.getByStateStep(payload, state.ready.current_page);
            commit("setReady", ready);
            commit('setLoading', false);
        }
    }
}

export default moduleAuthorizer;