export const templateMemo =
{
    title: "Memo",
    description:
        "Plantilla predefinida de un Memo",
    content: `        
    <p><img src="../Logo-SSM.jpg" width="100" height="100" /></p>
    <p class="western" style="line-height: 1" align="justify">
      <span style="font-family: Arial, sans-serif"
        ><span style="font-size: xx-small"
          ><span lang="es-ES-u-co-trad"
            >Servicio de Salud Magallanes</span
          ></span
        ></span
      >
    </p>
    \n
    <p class="western" style="line-height: 1" align="justify">
      <span style="font-family: Times New Roman, serif"
        ><span style="font-size: small"
          ><span style="font-family: Arial, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-ES-u-co-trad"
                >Subdirecci&oacute;n Administrativa</span
              ></span
            ></span
          ></span
        ></span
      >
    </p>
    \n
    <p lang="es-ES" style="line-height: 1" align="justify">
      <span style="font-family: Times New Roman, serif"
        ><span style="font-size: small"
          ><span style="font-family: Arial, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-ES-u-co-trad"
                >Departamento Tecnolog&iacute;as de Informaci&oacute;n y
                Comunicaciones
              </span></span
            ></span
          ></span
        ></span
      >
    </p>
    \n
    <p lang="es-ES" style="line-height: 1" align="justify">
      <span style="font-family: Times New Roman, serif"
        ><span style="font-size: small"
          ><span style="font-family: Verdana, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-CL">AVF/</span></span
            ></span
          ><span style="font-family: Verdana, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-CL">RTB/kam</span></span
            ></span
          ></span
        ></span
      >
    </p>
    \n
    <table
      style="
        border-collapse: collapse;
        width: 100%;
        height: 110px;
        border-style: hidden;
      "
      border="1"
    >
      \n
      <tbody>
        \n
        <tr style="height: 95px" style="border-style: none">
          \n
          <td style="width: 48.057%; height: 81px; border-style: hidden">
            &nbsp;
          </td>
          \n
          <td style="width: 48.057%; height: 81px; border-style: hidden">
            \n
            <p
              class="western"
              style="text-align: left; line-height: 1"
              align="justify"
            >
              <span style="font-family: Arial, sans-serif"
                ><span style="font-size: small"
                  ><span lang="pt-BR"
                    ><strong>MEMOR&Aacute;NDUM N</strong></span
                  ></span
                ></span
              ><span style="font-family: Arial, sans-serif"
                ><span style="font-size: small"
                  ><span lang="pt-BR"
                    ><u><strong>&deg;  </strong></u></span
                  ></span
                ></span
              ><span style="font-family: Arial, sans-serif"
                ><span style="font-size: small"
                  ><span lang="pt-BR"><strong>/</strong></span></span
                ></span
              >
            </p>
            \n
            <p
              class="western"
              style="text-align: left; line-height: 1"
              align="justify"
            >
              <span style="font-family: Arial, sans-serif"
                ><span style="font-size: small"
                  ><span lang="pt-BR"><strong> Ant.: </strong></span></span
                ></span
              ><span style="font-family: Arial, sans-serif"
                ><span style="font-size: small"
                  ><span lang="pt-BR">No Hay</span></span
                ></span
              >
            </p>
            \n
            <p
              class="western"
              style="text-align: left; line-height: 1"
              align="justify"
            >
              <span style="font-family: Arial, sans-serif">
                <span style="font-size: small">
                  <span lang="pt-BR">
                    <strong> Mat.: </strong>
                  </span>
                </span>
              </span>             
            </p>
            \n
            <p
              class="western"
              style="text-align: left; line-height: 1"
              align="justify"
            >
              <span style="font-family: Arial, sans-serif"
                ><span style="font-size: small"
                  ><span lang="pt-BR"><strong> </strong></span></span></span
              ><span style="font-family: Arial, sans-serif"
                ><span style="font-size: small"
                  ><strong>Punta Arenas, 24 de </strong></span
                ></span
              ><span style="color: #000000"
                ><span style="font-family: Arial, sans-serif"
                  ><span style="font-size: small"
                    ><span lang="es-CL"><strong>Septiembre</strong></span></span
                  ></span
                ></span
              ><span style="font-family: Arial, sans-serif"
                ><span style="font-size: small"
                  ><strong> 2021</strong></span
                ></span
              >
            </p>
            \n
          </td>
          \n
        </tr>
        \n
        <tr style="height: 138px" style="border-style: none">
          \n
          <td style="width: 48.057%; height: 29px; border-style: hidden">
            \n
            <p
              lang="es-ES-u-co-trad"
              style="line-height: 1; text-align: left"
              align="justify"
            >
              <span style="font-family: Arial, sans-serif; font-size: 8pt"
                ><strong>DE : D. </strong></span
              >
            </p>
            \n
            <p
              lang="es-ES"
              style="line-height: 1; text-align: left"
              align="justify"
            >
              <span style="font-family: 'Times New Roman', serif; font-size: 8pt">
                <span style="font-family: Arial, sans-serif">
                  <span lang="es-ES-u-co-trad">
                    <strong>A : D. </strong>
                  </span>
                </span>
              </span>
            </p>            
            \n
          </td>
          \n
          <td style="width: 48.057%; height: 29px; border-style: hidden">
            \n
            <p class="western" style="text-align: left" align="justify">
              <span style="font-family: Arial, sans-serif"
                ><span style="font-size: small"
                  ><span lang="pt-BR"><strong>&nbsp;</strong></span></span
                ></span
              >
            </p>
            \n
          </td>
          \n
        </tr>
        \n
      </tbody>
      \n
    </table>
    \n
    <p class="western" style="text-align: justify" align="justify">
      <span style="font-family: Arial, sans-serif"
        ><span style="font-size: small"
          ><span lang="es-ES"
            >Junto con saludar, 
          </span></span
        ></span
      >
    </p>
    `,

}