import Buyer from '@/types/purchaseRequest/buyer'
import { BaseService } from './../../base'
export class BuyerService extends BaseService<Buyer>() {
    static get entity (): string {
        return 'buyer'
    }
    static get responseName (): string {
        return 'buyer'
    }
    static async getPurchaseRequestIdUserBuyerId(purchaseRequestId:number,userBuyerId:number): Promise<Buyer[]> {
        return this.submit<Buyer[]>({
            method: 'get',
            path: `find_by_purchase_request_id_user_buyer_id/${purchaseRequestId}/${userBuyerId}`
        });
    }
    static async getFindPurchaseRequestId(purchaseRequestId:number): Promise<Buyer[]> {
        return this.submit<Buyer[]>({
            method: 'get',
            path: `find_by_purchase_request_id/${purchaseRequestId}`
        });
    }
    static async getFindByUserBuyerIdState(userId:number,state:string,page:number): Promise<Buyer[]> {
        return this.submit<Buyer[]>({
            method: 'get',
            path: `find_by_user_buyer_id/${userId}/${state}?page=${page}`
        });
    }
}