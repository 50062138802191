







import TicketReportsContainer from "@/components/ticket/reports/TicketReportsContainer.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: { TemplateAdministrator, TicketReportsContainer },
});
