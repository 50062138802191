














































import { UserService } from "@/services/models/user/user";
import myFormInterface from "@/types/myFormInterface";
import Vue from "vue";
export default (Vue as myFormInterface).extend({
  data() {
    return {
      dialog: false,
      isLoading: false,
      user: {
        current_password: "" as string,
        password: "" as string,
        password_confirmation: "" as string,
      },
    };
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        if (this.user.password == this.user.password_confirmation) {
          try {
            this.isLoading = true;
            await UserService.getChangePasswd(
              JSON.parse(localStorage.getItem("user") || "{}").id,
              this.user            
            );
            this.cleanForm(this, "form");            
            this.displayToast({
                type: 'success',
                message: 'Contraseña modificada con éxito'
            });
            this.dialog=false;
          } catch (error) {
            this.displayToast({
              type: "error",
              message: "Hubo un error, favor de verificar los datos",
            });
            console.log(error);
          } finally {
            this.isLoading = false;
          }
        } else {
          this.displayToast({
            type: "error",
            message: "Ambas contraseñas no coinciden, favor de reintentar",
          });
        }
      }
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.cleanForm(this, "form");        
      }
    },
  },
});
