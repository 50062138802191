







import TableContact from "@/components/contacts/TableContact.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: { TemplateAdministrator, TableContact },
});
