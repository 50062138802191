import TicketType from '@/types/tickets/tickets_type'
import { BaseService } from './../../base'
export class TicketTypeService extends BaseService<TicketType>() {
    static get entity (): string {
        return 'ticket_type'
    }
    static get responseName (): string {
        return 'ticket_type'
    }
    static async getByGroupId(id:number): Promise<TicketType[]> {
        return this.submit<TicketType[]>({
            method: 'get',
            path: `find_by_group_id/${id}`
        });
    }
}