import PurchaseBuyerState from '@/types/purchaseRequest/purchaseBuyerState'
import { BaseService } from './../../base'
export class PurchaseBuyerStateService extends BaseService<PurchaseBuyerState>() {
    static get entity (): string {
        return 'purchase_buyer_state'
    }
    static get responseName (): string {
        return 'purchase_buyer_state'
    }
    static async getByPurchaseBuyerIdUserId(purchaseBuyerId:number,userId:number): Promise<PurchaseBuyerState[]> {
        return this.submit<PurchaseBuyerState[]>({
            method: 'get',
            path: `find_by_purchase_buyer_id_user_id/${purchaseBuyerId}/${userId}`
        });
    }
}