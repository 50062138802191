import { DocumentService } from "@/services/models/document/document";
import Document from "@/types/documents/document";
import ResponsePaginated from "@/types/responsePaginated";
import { Commit, Dispatch } from "vuex";
import store from "./../../store"

interface stateInterface {
    Documents: Document[],
    myDocument: ResponsePaginated<Document[]>,
    requestDocument: ResponsePaginated<Document[]>,

    byCheckDocument: ResponsePaginated<Document[]>,
    CheckDocument: ResponsePaginated<Document[]>,
    noRelevantDocument: ResponsePaginated<Document[]>,

    byFirmDocument: ResponsePaginated<Document[]>,
    firmDocument: ResponsePaginated<Document[]>,
    rejectDocument: ResponsePaginated<Document[]>,

    searchDocument: ResponsePaginated<Document[]>,

    folderDocument: ResponsePaginated<Document[]>,

    loading: boolean,
    requestedDocumentsNotRead: number
}
const moduleDocument = {
    namespaced: true,
    state: {
        Documents: [],
        myDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        requestDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },

        byCheckDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        CheckDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        noRelevantDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },

        byFirmDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        firmDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        rejectDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },

        searchDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },

        folderDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },

        loading: false,
        requestedDocumentsNotRead: 0
    } as stateInterface,
    getters: {
        getDocuments(state: stateInterface): Document[] {
            return state.Documents;
        },
        getMyDocument(state: stateInterface): ResponsePaginated<Document[]> {
            return state.myDocument;
        },
        getRequestDocument(state: stateInterface): ResponsePaginated<Document[]> {
            return state.requestDocument;
        },


        //Visaciones
        getByCheckedDocument(state: stateInterface): ResponsePaginated<Document[]> {
            return state.byCheckDocument;
        },
        getCheckedDocument(state: stateInterface): ResponsePaginated<Document[]> {
            return state.CheckDocument;
        },
        getNotRelevantDocument(state: stateInterface): ResponsePaginated<Document[]> {
            return state.noRelevantDocument;
        },

        //Firm
        getByFirmedDocument(state: stateInterface): ResponsePaginated<Document[]> {
            return state.byFirmDocument;
        },
        getFirmedDocument(state: stateInterface): ResponsePaginated<Document[]> {
            return state.firmDocument;
        },
        getRejectDocument(state: stateInterface): ResponsePaginated<Document[]> {
            return state.rejectDocument;
        },

        //searchDocument
        getSearchDocument(state: stateInterface): ResponsePaginated<Document[]> {
            return state.searchDocument;
        },

        //TableFolder
        getFolderDocument(state: stateInterface): ResponsePaginated<Document[]> {
            return state.folderDocument;
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        },
        getRequestedDocumentsNotRead(state: stateInterface): number {
            return state.requestedDocumentsNotRead;
        }
    },
    mutations: {
        setDocument(state: stateInterface, Documents: Document[]): void {
            state.Documents = Documents;
        },
        setMyDocument(state: stateInterface, myDocument: ResponsePaginated<Document[]>): void {
            state.myDocument = myDocument;
        },
        setRequestDocument(state: stateInterface, requestDocument: ResponsePaginated<Document[]>): void {
            state.requestDocument = requestDocument;
        },

        //Visaciones
        setByCheckedDocument(state: stateInterface, byCheckDocument: ResponsePaginated<Document[]>): void {
            state.byCheckDocument = byCheckDocument;
        },
        setCheckedDocument(state: stateInterface, CheckDocument: ResponsePaginated<Document[]>): void {
            state.CheckDocument = CheckDocument;
        },
        setNotRelevantDocument(state: stateInterface, noRelevantDocument: ResponsePaginated<Document[]>): void {
            state.noRelevantDocument = noRelevantDocument;
        },

        //Firma
        setByFirmedDocument(state: stateInterface, byFirmDocument: ResponsePaginated<Document[]>): void {
            state.byFirmDocument = byFirmDocument;
        },
        setFirmedDocument(state: stateInterface, firmDocument: ResponsePaginated<Document[]>): void {
            state.firmDocument = firmDocument;
        },
        setRejectDocument(state: stateInterface, rejectDocument: ResponsePaginated<Document[]>): void {
            state.rejectDocument = rejectDocument;
        },

        //searchDocument
        setSearchDocument(state: stateInterface, searchDocument: ResponsePaginated<Document[]>): void {
            state.searchDocument = searchDocument;
        },

        //TableFolder
        setFolderDocument(state: stateInterface, folderDocument: ResponsePaginated<Document[]>): void {
            state.folderDocument = folderDocument;
        },

        //Paginaciones
        setPageMyDocument(state: stateInterface, page: number): void {
            state.myDocument.current_page = page;
        },
        setPageRequestDocument(state: stateInterface, page: number): void {
            state.requestDocument.current_page = page;
        },

        //Paginacion de las Visaciones
        setPageByCheckedDocument(state: stateInterface, page: number): void {
            state.byCheckDocument.current_page = page;
        },
        setPageCheckedDocument(state: stateInterface, page: number): void {
            state.CheckDocument.current_page = page;
        },
        setPageNotRelevantDocument(state: stateInterface, page: number): void {
            state.noRelevantDocument.current_page = page;
        },

        //Paginacion de las Firmas
        setPageByFirmedDocument(state: stateInterface, page: number): void {
            state.byFirmDocument.current_page = page;
        },
        setPageFirmedDocument(state: stateInterface, page: number): void {
            state.firmDocument.current_page = page;
        },
        setPageRejectDocument(state: stateInterface, page: number): void {
            state.rejectDocument.current_page = page;
        },

        //Paginación de la busqueda avanzada
        setPageSearchDocument(state: stateInterface, page: number): void {
            state.searchDocument.current_page = page;
        },

        //Paginación de la Tabla Folder
        setPageFolderDocument(state: stateInterface, page: number): void {
            state.folderDocument.current_page = page;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        },
        setRequestedDocumentsNotRead(state: stateInterface, quantity: number): void {
            state.requestedDocumentsNotRead = quantity;
        }
    },
    actions: {
        async getDocuments({ commit }: { commit: Commit }): Promise<void> {
            commit('setLoading', true);
            const document = await DocumentService.getAll();
            commit("setDocument", document);
            commit('setLoading', false);
        },
        async getMyDocument({ commit, state }: { commit: Commit, state: stateInterface }, data: dataGetRequestDocument): Promise<void> {
            commit('setLoading', true);
            const profileLoggedIn = store.getters['userSession/getProfile'];
            let documents: ResponsePaginated<Document[]>;
            if (profileLoggedIn == "opartes") {
                documents = await DocumentService.getByUserLocationId({
                    ...data,
                    location_id: store.getters['userSession/getCurrentLocation'].location_id
                }, state.myDocument.current_page);
            } else {
                const user = window.localStorage.getItem("user");
                let user_id = 0;
                if (user) {
                    user_id = JSON.parse(user).id;
                }
                documents = await DocumentService.getUserId({
                    ...data,
                    id: user_id
                }, state.myDocument.current_page);
            }
            commit("setMyDocument", documents);
            commit('setLoading', false);
        },
        async getRequestDocument({ commit, state, dispatch }: { commit: Commit, state: stateInterface, dispatch: Dispatch }, data: dataGetRequestDocument): Promise<void> {
            commit('setLoading', true);
            const profileLoggedIn = store.getters['userSession/getProfile'];
            let documents: ResponsePaginated<Document[]>;
            if (profileLoggedIn == "opartes") {
                documents = await DocumentService.getRequestedDocumentsByLocationId({
                    ...data,
                    location_id: store.getters['userSession/getCurrentLocation'].location_id
                }, state.requestDocument.current_page);
            } else {
                const user = window.localStorage.getItem("user");
                let user_id = 0;
                if (user) {
                    user_id = JSON.parse(user).id;
                }
                documents = await DocumentService.getRequestId({
                    ...data,
                    user_id: user_id
                }, state.requestDocument.current_page);
            }
            dispatch('getRequestedDocumentsNotRead');
            commit("setRequestDocument", documents);
            commit('setLoading', false);
        },

        //Visaciones    
        async getByCheckedDocument({ commit, state }: { commit: Commit, state: stateInterface }, id: number): Promise<void> {
            commit('setLoading', true);
            const document = await DocumentService.getByCheckedState(id, 'por visar', state.byCheckDocument.current_page);
            commit("setByCheckedDocument", document);
            commit('setLoading', false);
        },
        async getCheckedDocument({ commit, state }: { commit: Commit, state: stateInterface }, id: number): Promise<void> {
            commit('setLoading', true);
            const document = await DocumentService.getByCheckedState(id, 'visado', state.CheckDocument.current_page);
            commit("setCheckedDocument", document);
            commit('setLoading', false);
        },
        async getNotRelevantDocument({ commit, state }: { commit: Commit, state: stateInterface }, id: number): Promise<void> {
            commit('setLoading', true);
            const document = await DocumentService.getByCheckedState(id, 'no pertinente', state.noRelevantDocument.current_page);
            commit("setNotRelevantDocument", document);
            commit('setLoading', false);
        },

        //Firma
        async getByFirmedDocument({ commit, state }: { commit: Commit, state: stateInterface }, id: number): Promise<void> {
            commit('setLoading', true);
            const document = await DocumentService.getByFirmedState(id, 'por firmar', state.byFirmDocument.current_page);
            commit("setByFirmedDocument", document);
            commit('setLoading', false);
        },
        async getFirmedDocument({ commit, state }: { commit: Commit, state: stateInterface }, id: number): Promise<void> {
            commit('setLoading', true);
            const document = await DocumentService.getByFirmedState(id, 'firmado', state.firmDocument.current_page);
            commit("setFirmedDocument", document);
            commit('setLoading', false);
        },
        async getRejectDocument({ commit, state }: { commit: Commit, state: stateInterface }, id: number): Promise<void> {
            commit('setLoading', true);
            const document = await DocumentService.getByFirmedState(id, 'rechazado', state.rejectDocument.current_page);
            commit("setRejectDocument", document);
            commit('setLoading', false);
        },

        //Busqueda Avanzada
        async getSearchDocument({ commit, state }: { commit: Commit, state: stateInterface }, payLoad: { data: Record<string, unknown> }): Promise<void> {
            commit('setLoading', true);
            const profileLoggedIn = store.getters['userSession/getProfile'];
            let documents: ResponsePaginated<Document[]>;
            if (profileLoggedIn == "opartes") {
                const location_id = store.getters['userSession/getCurrentLocation'].location_id
                documents = await DocumentService.getByAllDocumentLocation(location_id, payLoad.data, state.searchDocument.current_page);                
            } else {
                const user = window.localStorage.getItem("user");
                let user_id = 0;
                if (user) {
                    user_id = JSON.parse(user).id;
                }
                documents = await DocumentService.getByAllDocument(user_id, payLoad.data, state.searchDocument.current_page);
            }
            commit("setSearchDocument", documents);
            commit('setLoading', false);
        },

        //Table Folder
        async getFolderDocument({ commit, state }: { commit: Commit, state: stateInterface }, payLoad: { user_id: number, folder_id: number }): Promise<void> {
            commit('setLoading', true);
            const document = await DocumentService.getByFolderId(payLoad, state.folderDocument.current_page);
            commit("setFolderDocument", document);
            commit('setLoading', false);
        },

        //Carga el número de documentos solicitados no leidos
        async getRequestedDocumentsNotRead({ commit, state }: { commit: Commit, state: stateInterface }): Promise<void> {
            const profileLoggedIn = store.getters['userSession/getProfile'];
            let documents: ResponsePaginated<Document[]>;
            if (profileLoggedIn == "opartes") {
                documents = await DocumentService.getRequestedDocumentsByLocationId({
                    location_id: store.getters['userSession/getCurrentLocation'].location_id,
                    state_read: "no leido"
                }, state.requestDocument.current_page);
            } else {
                const user = window.localStorage.getItem("user");
                let user_id = 0;
                if (user) {
                    user_id = JSON.parse(user).id;
                }
                documents = await DocumentService.getRequestId({
                    id: user_id,
                    state_read: "no leido"
                }, state.requestDocument.current_page);
            }
            commit("setRequestedDocumentsNotRead", documents.data.length);
        }
    }
}

export default moduleDocument;

interface dataGetRequestDocument {
    //id del usuario
    id: number,
    location_id: number,
    //el valor del state_read para ver si se ha leído o no el documento
    state_read?: string,
    //el valor del state de document_state
    state?: string
}