import History from '@/types/documents/history'
import { BaseService } from './../../base'
export class HistoryService extends BaseService<History>() {
    static get entity (): string {
        return 'history'
    }
    static get responseName (): string {
        return 'history'
    }
    static async getByDocumentDetailId(document_id:number): Promise<History[]> {
		return this.submit<History[]>({
            method: 'get',
            path: `find_by_document_detail_id/${document_id}`
        });
	}
}