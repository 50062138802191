import store from "./../../store"
interface stateInterface {
    tabName: string,
    loading: boolean
}
const moduleTabsDocuments = {
    namespaced: true,
    state: {
        tabName: 'tab-request',
    } as stateInterface,
    getters: {
        getTabNumber(state: stateInterface): string {
            return state.tabName;
        },
    },
    mutations: {
        setTabNumber(state: stateInterface, tabName: string): void {
            state.tabName = tabName;
        },
    },
    actions: {
        async getLoadTable({ state }: { state: stateInterface }, payload: {
            user_id: number,
            location_id: number,
            folder_id: number
        }): Promise<void> {
            if (state.tabName == "tab-request") {
                store.dispatch('documents/getRequestDocument', {id:payload.user_id, location_id: payload.location_id})
            }
            if (state.tabName == "tab-myDocument") {
                store.dispatch('documents/getMyDocument', {id:payload.user_id, location_id: payload.location_id})
            }

            if (state.tabName == "tab-firm") {
                store.dispatch('documents/getByFirmedDocument', payload.user_id)
                store.dispatch('documents/getFirmedDocument', payload.user_id)
                store.dispatch('documents/getRejectDocument', payload.user_id)
            }
            if (state.tabName == "tab-check") {
                store.dispatch('documents/getByCheckedDocument', payload.user_id)
                store.dispatch('documents/getCheckedDocument', payload.user_id)
                store.dispatch('documents/getNotRelevantDocument', payload.user_id)
            }
            if(state.tabName == "tab-folder"){
                store.dispatch('documents/getFolderDocument',payload)
            }
        }
    }
}

export default moduleTabsDocuments;