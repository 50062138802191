
























import { TicketTypeService } from "@/services/models/ticket/ticketType";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  props: {
    groupProp: {
      type: Object,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Nombre",
          sortable: false,
          value: "name",
        },
        {
          text: "Acciones",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getTicketType: "ticketType/getTicketType",
      getLoading: "ticketType/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setTicketType: "ticketType/getTicketType",
    }),
    async removeTicket(ticketTypeId:number){
      this.displayLoader(true)
      await TicketTypeService.remove(ticketTypeId)
      this.setTicketType(this.groupProp.id);
      this.displayLoader(false)
    }
  },
  mounted() {
    this.setTicketType(this.groupProp.id);
  },
});
