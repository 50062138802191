



































import { DispatchLocationService } from "@/services/models/dispatch/dispatch_location";
import { DocumentTypeService } from "@/services/models/document/document_type";
import myFormInterface from "@/types/myFormInterface";
import Vue from "vue";
export default (Vue as myFormInterface).extend({
  data() {
    return {
      dispatch: {
        number: "" as string,
        description: "" as string,
        dispatch_location_id: 0 as number,
        document_type_id: 0 as number,
        state: "creado",
        stablishment_id: this.getCurrentUserLoggedIn().stablishment_id,
      },
      documentsTypes: [],
      locations: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.displayLoader(true);
      this.documentsTypes = (await DocumentTypeService.getAll()) as [];
      this.locations = (await DispatchLocationService.getAll()) as [];
      this.displayLoader(false);
    },
    returnRef(){
      return this.$refs.formCreate.validate();
    }
  },
});
