












import Vue from "vue";
import CardUser from "./CardUser.vue";
import CardData from "./CardData.vue";
export default Vue.extend({
  components: {
    CardUser,
    CardData,
  },
});
