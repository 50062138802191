







































































import { DocumentDetailService } from "@/services/models/document/documentDetail";
import { StablishmentService } from "@/services/models/stablishment";
import myFormInterface from "@/types/myFormInterface";
import UserLocation from "@/types/user/userLocation";
import { PDFDocument } from "pdf-lib";
import { base64ArrayBuffer } from "@/others/toBase64";
import { callSha256, setQrCode } from "./functionsToFirm";
import Vue from "vue";
import { UserService } from "@/services/models/user/user";
import Otp from "./Otp.vue";
import SelectProfile from "./SelectProfile.vue";
import SuccessPage from "./SuccessPage.vue";
import Loader from "./Loader.vue";
import { DocumentDetailFirmedService } from "@/services/models/document/documentDetailFirmed";
import { HistoryService } from "@/services/models/document/history";
import moment from "moment";
import { DocumentStateService } from "@/services/models/document/documentState";
import { mapActions } from "vuex";
import RejectFirm from "./RejectFirm.vue";
import VueHtml2pdf from "vue-html2pdf";
/* eslint-disable */
// @ts-ignore
import html2pdf from "html2pdf.js"
/* eslint-enable */
export default (Vue as myFormInterface).extend({
  components: { Otp, SelectProfile, SuccessPage, Loader, RejectFirm, VueHtml2pdf },
  props: {
    documentDetailId: {
      required: true,
      type: Number,
    },
    documentId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      invalidStep: 0 as number,
      step: 1 as number,
      otp: null as number | null,
      loading: false as boolean,
      userLocationSelected: {} as UserLocation,
      progress: 0 as number,
      errorMessage: "" as string,
      loadingOverlay: false as boolean,
      contentTemplate: "" as string,
    };
  },
  methods: {
    ...mapActions({
      getDocumentsDetail: "documentDetail/getByDocumentId",
    }),
    changeStep(step: number) {
      this.step = step;
    },
    validateStep(isValid: boolean, step: number): void {
      if (!isValid) {
        this.invalidStep = step;
      }
    },
    dataURLtoFile(dataurl: any, filename: any) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    async addFirmToPdf(file: string): Promise<string> {
      let pngImageBytes: string = await this.$refs.qr?.children[1].currentSrc;
      const stablishmentId = this.getCurrentUserLoggedIn().stablishment_id;
      const stablishment = await StablishmentService.getById(stablishmentId);
      const pdfDoc = await PDFDocument.load(file);
      const pngImage = await pdfDoc.embedPng(pngImageBytes);
      const pngDims = pngImage.scale(0.1);
      const pages = pdfDoc.getPages();
      const numberOfPages = pdfDoc.getPageCount();
      const lastPage = pages[numberOfPages - 1];
      const { width, height } = lastPage.getSize();
      let user = this.getCurrentUserLoggedIn();
      let name = user.name;
      let apepat = user.pather_lastname;
      let apemat = user.mother_lastname;
      lastPage.drawText(stablishment.name, {
        x: width / 2 - 65,
        y: height / 14 - 21,
        size: 9,
      });

      lastPage.drawText(
        `${this.userLocationSelected.position?.name} ${this.userLocationSelected.location?.name}`,
        {
          x: width / 2 - 65,
          y: height / 14 - 6,
          size: 9,
        }
      );

      lastPage.drawText(name + " " + apepat + " " + apemat, {
        x: width / 2 - 65,
        y: height / 14 + 9,
        size: 10,
      });

      lastPage.drawText("Este documento ha sido firmado electrónicamente por", {
        x: width / 2 - 65,
        y: height / 14 + 24,
        size: 10,
      });
      lastPage.drawImage(pngImage, {
        x: width - width / 7,
        y: height / 14 + -20,
        width: pngDims.width * 2,
        height: pngDims.height * 2,
      });
      const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: false });
      return pdfBytes;
    },
    async generateQr(): Promise<void> {
      const user = await UserService.getById(this.getCurrentUserLoggedIn().id);
      // let user = await getUser(this.$http, this.getCurrentUserLoggedIn().id);
      let qrContent = `Firmado por Servicio de Salud Magallanes, ${user.name} ${user.pather_lastname} ${user.mother_lastname} rut: ${user.run}`;
      /* eslint-disable */
      setQrCode(this.$refs.qr, qrContent);
      // new QRCode(this.$refs.qr, qrContent);
      /* eslint-enable */
    },
    async prepareData(): Promise<{
      base64WithFirm: string;
      token: string;
      checksum: string;
    }> {
      const documentDetail = await DocumentDetailService.getById(
        this.documentDetailId
      );

      this.progress = 20;
      let base64File = "";
      if (documentDetail.file) {
        const downloadedFile = await DocumentDetailService.downloadFile(
          documentDetail.id
        );
        const blob = new Blob([downloadedFile.data], {
          type: "application/pdf",
        });
  
        const arrayBufferFile = await blob.arrayBuffer();
        base64File = base64ArrayBuffer(arrayBufferFile);
      } else {
        if (documentDetail.file_generated) {
          this.contentTemplate = documentDetail.file_generated;
        }
        let pdfContent = this.$refs.html2Pdf.$refs.pdfContent;
        let options = this.$refs.html2Pdf.setOptions();
        let result = await html2pdf()
          .set(options)
          .from(pdfContent)
          .outputPdf();
        base64File = btoa(result);
      }
      console.log(base64File);
      /* eslint-disable */
      const base64WithFirm = await this.addFirmToPdf(base64File);
      this.progress = 30;
      let token = (
        await UserService.getTokenKey(
          this.getCurrentUserLoggedIn().run.split("-")[0]
        )
      ).data;
      this.progress = 40;
      let checksum: string = callSha256(
        Uint8Array.from(atob(base64WithFirm), (c) => c.charCodeAt(0))
      );
      /* eslint-enable */
      return {
        base64WithFirm,
        token,
        checksum,
      };
    },
    async firmFile(
      token: string,
      base64: string,
      checksum: string,
      otp: string
    ) {
      let resp = (
        await DocumentDetailService.getFileFirmed({
          token,
          content: base64,
          checksum,
          otp,
        })
      ).data;
      let isValid = false;
      if (resp.files) {
        // console.log(resp.files[0].status);
        if (resp.files[0].status == "OK") {
          isValid = true;
        }
      }
      let fileResponse: string;
      if (isValid) {
        fileResponse = resp.files[0].content;
      } else {
        let errorMessage;
        if (resp.files) {
          errorMessage = resp.files[0].status ? resp.files[0].status : "";
        } else {
          errorMessage = resp.error ? " - " + resp.error : "";
        }
        this.errorMessage = errorMessage;
        throw new Error(`Falla del API ${errorMessage}`);
      }
      return fileResponse;
    },
    async updateDocumentDetailFirmed() {
      try {
        const documentDetailsFirmed =
          await DocumentDetailFirmedService.getByDocumentDetailId(
            this.documentDetailId
          );
        const documentDetailFirmed = documentDetailsFirmed.find(
          (value) => value.user_id == this.getCurrentUserLoggedIn().id
        );
        if (documentDetailFirmed) {
          await DocumentDetailFirmedService.update(documentDetailFirmed.id, {
            ...documentDetailFirmed,
            date_firmed: moment().format("YYYY-MM-DD HH:mm"),
            state: "firmado",
          });
        }
      // eslint-disable-next-line
      } catch (error: any) {
        throw new Error(error);
      }
    },
    async updateDocumentState() {
      try {
        const documentStates = await DocumentStateService.getByDocumentIdUserId(
          this.documentId,
          this.getCurrentUserLoggedIn().id
        );
        if (documentStates.length) {
          await DocumentStateService.update(documentStates[0].id, {
            ...documentStates[0],
            state: "firmado",
          });
        }
      // eslint-disable-next-line
      } catch (error: any) {
        throw new Error(error);
      }
    },
    async generateFirmedPdf(otp: string) {
      try {
        this.errorMessage = "";
        this.loading = true;
        this.progress = 0;
        await this.generateQr();
        this.progress = 10;
        let { base64WithFirm, token, checksum } = await this.prepareData();
        if (!token || !otp) {
          return;
        }
        let file = await this.firmFile(token, base64WithFirm, checksum, otp);
        await DocumentDetailService.updateImage({
          base64: file,
          documentDetailId: this.documentDetailId,
        });
        this.progress = 70;
        await this.updateDocumentDetailFirmed();
        this.progress = 80;
        await this.updateDocumentState();
        this.progress = 90;
        await HistoryService.create({
          user_origin_id: this.getCurrentUserLoggedIn().id,
          document_detail_id: this.documentDetailId,
          state: "firmado",
          location_origin_id: this.getUserLocation().location_id,
          user_destination_id: this.getCurrentUserLoggedIn().id,
          location_destination_id: this.getUserLocation().location_id,
        });
        this.progress = 100;
        this.step = 3;
        //Esto es por que no podía recargar el documentDetail sin que desapareciera el modal y no se alcanzaba a mostrar la pantalla de éxito
        setTimeout(() => {
          this.getDocumentsDetail(this.documentId);
        }, 2000);
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.displayToast({
          type: "error",
          message: "Lo sentimos, ha ocurrido un error. Favor volver a intentar",
        });
      }
    },
  },
});
interface beforeDownload {
  html2pdf: any;
  options: Record<string, unknown>;
  pdfContent: Record<string, unknown>;
}
