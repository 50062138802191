























import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import myFormInterface from "@/types/myFormInterface";
import PurchaseBuyer from "@/types/purchaseRequest/purchaseBuyer";
import Vue from "vue";
export default (Vue as myFormInterface).extend({
  props: {
    purchaseProp: {
      type: Object,
    },
    preData:{
      type:Object
    }
  },
  async mounted(){
    if(this.preData){
      this.displayLoader(true)
      this.data=await PurchaseBuyerService.getById(this.preData.id)
      this.displayLoader(false)
    }
  },
  data() {
    return {
      menuProps: {
        disabled: false,
      },
      data: {        
        id: 0 as number,
        user_id: this.getCurrentUserLoggedIn().id as number,
        purchase_request_id: this.purchaseProp.id as number,
        observation: "" as string,
        n_order: "" as string,
        n_licitation: "" as string,
        state: "creado",
      } as PurchaseBuyer,
    };
  },
  methods: {
    validateForm() {
      if (!this.$refs.formPurchaseBuyer.validate()) {
        return false;
      }
      return true;
    },
  },
});
