




































import { GroupService } from "@/services/models/group/group";
import Group from "@/types/user/groups";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  data() {
    return {
      dialog: false,
      group: {
        id: 0,
        name: "" as string,
        stablishment_id:this.getCurrentUserLoggedIn().stablishment_id
      } as Group,
    };
  },
  methods: {
    ...mapActions({
      setGroup: "group/getGroups",
    }),
    async save() {
      try {
        this.displayLoader(true);
        await GroupService.create(this.group);
        this.setGroup();
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Error al guardar",
        });
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
