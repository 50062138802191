



























































































import Vue from "vue";
import CreateDocument from "./CreateDocument.vue";
import TableMyDocuments from "./TableMyDocuments.vue";
import TableRequest from "./TableRequest.vue";
import FilterColorsDocument from "./FilterColorsDocument.vue";
import SearchDocument from "./searchDocument/SearchDocument.vue";
import TabChecked from "./documentChecked/TabChecked.vue";
import TabFirmed from "./documentFirmed/TabFirmed.vue";
import ButtonRefreshTable from "./ButtonRefreshTable.vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  components: {
    TableMyDocuments,
    TableRequest,
    CreateDocument,
    FilterColorsDocument,
    SearchDocument,
    TabChecked,
    TabFirmed,
    ButtonRefreshTable,
  },
  data() {
    return {
      tab: "tab-request",
    };
  },
  computed: {
    ...mapGetters({
      getByFirm: "documents/getByFirmedDocument",
      getByVisated: "documents/getByCheckedDocument",
      getRequest: "documents/getRequestDocument",
    }),
  },
  mounted() {
    if (this.getProfile() !== "opartes") {
      this.byFirms(this.getCurrentUserLoggedIn().id);
      this.byVisated(this.getCurrentUserLoggedIn().id);
    }
    this.request({id: this.getCurrentUserLoggedIn().id});
    //será necesario esto???
    this.$store.commit("tabsDocument/setTabNumber", this.tab);
  },
  methods: {
    ...mapActions({
      byFirms: "documents/getByFirmedDocument",
      byVisated: "documents/getByCheckedDocument",
      request: "documents/getRequestDocument",
      setMyDocuments: "documents/getMyDocument",
    }),
    changeTab() {
      this.$store.commit("tabsDocument/setTabNumber", this.tab);
    },
  },
});
