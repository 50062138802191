import { dataByStateStep, PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";
import ResponsePaginated from "@/types/responsePaginated";
import { Commit } from "vuex";

interface stateInterface {
    purchaseRequest: ResponsePaginated<PurchaseRequest[]>,
    loading: boolean,
}
const modulePurchaseRequest = {
    namespaced: true,
    state: {
        purchaseRequest: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        loading: false,
    } as stateInterface,
    getters: {
        getPurchaseRequest(state: stateInterface): ResponsePaginated<PurchaseRequest[]> {
            return state.purchaseRequest;
        },
        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setPurchaseRequest(state: stateInterface, purchaseRequest: ResponsePaginated<PurchaseRequest[]>): void {
            state.purchaseRequest = purchaseRequest;
        },
        setPagePurchaseRequest(state: stateInterface, page: number): void {
            state.purchaseRequest.current_page = page;
        },
        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getPurchaseRequest({commit,state}: {commit: Commit,state: stateInterface},user_id:number): Promise<void> {
            commit('setLoading', true);            
            const purchaseRequest = await PurchaseRequestService.getByUserId(user_id,state.purchaseRequest.current_page);
            commit("setPurchaseRequest", purchaseRequest);
            commit('setLoading', false);
        }
    }
}

export default modulePurchaseRequest;