import TicketHistory from '@/types/tickets/tickets_histories'
import { BaseService } from './../../base'
export class TicketHistoryService extends BaseService<TicketHistory>() {
    static get entity (): string {
        return 'ticket_history'
    }
    static get responseName (): string {
        return 'ticket_history'
    }
    static async getByTicketId(ticket_id:number): Promise<TicketHistory[]> {
        return this.submit<TicketHistory[]>({
            method: 'get',
            path: `find_by_ticket_id/${ticket_id}`
        });
    }
}