




















































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import DetailTablePurchaseRequest from "../DetailTablePurchaseRequest.vue";
export default Vue.extend({
  components:{
    DetailTablePurchaseRequest
  },
  data() {
    return {
      page: 1,
      headers: [
         {
          text: "Id Solicitud",
          sortable: false,
          value: "purchase_request_id",
        },
        {
          text:'Id del Registro',
          sortable:false,
          value:'id'
        },
        {
          text: "Persona que lo asigno",
          sortable: false,
          value: "user_id",
        },
        {
          text: "Estado",
          sortable: false,
          value: "state",
        },        
      ],
    };
  },
  computed: {
    ...mapGetters({
      getBuyers: "buyer/getBuyerReady",
      getLoading: "buyer/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setBuyer: "buyer/getBuyerReady",
    }),
    changePage() {
      this.$store.commit("buyer/setPageBuyerReady", this.page);
      this.setBuyer({
        userId: this.getCurrentUserLoggedIn().id,
        state: "finalizado",
      });
    },
  },
  mounted() {
    this.changePage();
  },
});
