import Cdp from '@/types/purchaseRequest/cdp'
import ResponsePaginated from '@/types/responsePaginated';
import { AxiosResponse } from 'axios';
import { BaseService } from './../../base'
export class CdpService extends BaseService<Cdp>() {
    static get entity (): string {
        return 'cdp'
    }
    static get responseName (): string {
        return 'cdp'
    }
    static async getPurchaseBuyerId(purchaseBuyerId:number): Promise<Cdp[]> {
        return this.submit<Cdp[]>({
            method: 'get',
            path: `find_by_purchase_buyer_id/${purchaseBuyerId}`
        });
    }
    /**
     * ES solo para subir el cdp_file
     * @param dataToUpdate 
     * @returns 
     */
    static async uploadImageCdpFile(dataToUpdate: dataToUpdateImage): Promise<Cdp> {
        return this.submit<Promise<Cdp>>({
            method: 'put',
            data: {
                base64: `data:application/pdf;base64,${dataToUpdate.base64}`,
                cdp_name:dataToUpdate.cdp_name
            },
            path: 'upload_image_base64/' + dataToUpdate.id,
            showFullResponse: true
        })
    }

    /**
     * Para subir el cdp firmado
     * @param dataToUpdate 
     * @returns 
     */
    static async updateImage(dataToUpdate: dataToUpdateImage): Promise<Cdp> {
        return this.submit<Promise<Cdp>>({
            method: 'put',
            data: {
                base64: `data:application/pdf;base64,${dataToUpdate.base64}`,
            },
            path: 'update_image/' + dataToUpdate.id,
            showFullResponse: true
        })
    }
    static async downloadFile(fileId: number): Promise<AxiosResponse<Blob>> {
        return this.submit<AxiosResponse<Blob>>({
            method: 'get',
            path: `download_image/${fileId}`,
            showFullResponse: true,
            config: {
                responseType: 'blob',    
                "Content-Type": "application/json"
            }
        })
    }
    static async getFileFirmed(dataToPost: dataToSignDigitalDocument): Promise<AxiosResponse<firmaDigitalResponse>> {
        return this.submit<AxiosResponse<firmaDigitalResponse>>({
            method: 'put',
            data: {
                ...dataToPost,
                api_token_key: "31602029-ffc8-4112-8bf7-16d028fe3635",
            },
            path: 'get_file_firmed',
            showFullResponse: true
        })
    }
    static async getByStateUser(payLoad:{state:string,user:number},page:number): Promise<ResponsePaginated<Cdp[]>> {
        return this.submit<ResponsePaginated<Cdp[]>>({
            method: 'get',
            path: `find_by_state_user_id/${payLoad.state}/${payLoad.user}?page=${page}`
        });
    }
}
interface dataToUpdateImage {
    cdp_name?:string,
    base64: string,
    id: number
}
interface dataToSignDigitalDocument {
    token: string,
    content: string,
    checksum: string,
    otp: string,
    api_token_key?: string
}
interface firmaDigitalResponse {
    files: fileFirmaDigital[],
    metadata: metadataInterface,
    error?: string
}
interface metadataInterface {
    signed_failed: number,
    OTP_expired: boolean,
    files_signed: number,
    files_received: number
}

interface fileFirmaDigital {
    checksum_original: string,
    status: string,
    content: string
}