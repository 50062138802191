









import ButtonCreateDispatch from "@/components/dispatch/createDispatch/ButtonCreateDispatch.vue";
import TableDispatch from "@/components/dispatch/TableDispatch.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: { TemplateAdministrator, TableDispatch, ButtonCreateDispatch },
});
