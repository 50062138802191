import DocumentDetailFile from '@/types/documents/documentDetailFile'
import { BaseService } from './../../base'
export class DocumentDetailFileService extends BaseService<DocumentDetailFile>() {
    static get entity (): string {
        return 'document_detail_file'
    }
    static get responseName (): string {
        return 'document_detail_file'
    }
    static async getByDocumentDetail(detail_id:number): Promise<DocumentDetailFile[]> {
		return this.submit<DocumentDetailFile[]>({
            method: 'get',
            path: `find_by_document_detail_id/${detail_id}`
        });
	}
}