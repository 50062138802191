






































































import Vue from "vue";
import Article from "@/types/purchaseRequest/article";
import { ArticleService } from "@/services/models/purchaseRequest/article";
import myFormInterface from "@/types/myFormInterface";
export default (Vue as myFormInterface).extend({
  data() {
    return {
      articles: [] as Array<Article>,
      purchase_request_detail: {
        article: {} as Article,
        cant: null as number | null,
        price: null as number | null,
        observation: "" as string,
      },
      dataArray: [] as Array<selectArticles>,
      headers: [
        {
          text: "Articulo",
          sortable: false,
          value: "article_name",
        },
        {
          text: "Cantidad",
          sortable: false,
          value: "cant",
        },
        {
          text: "Precio Apróx",
          sortable: false,
          value: "price",
        },
        {
          text: "Acciones",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async loadData() {
      this.displayLoader(true);
      this.articles = await ArticleService.getAll();
      this.displayLoader(false);
    },
    async addArticle() {
      if (!this.$refs.formArticle.validate()) {
        this.displayToast({
          type: "error",
          message: "Favor de llenar todos los campos requeridos",
        });
        return;
      }
      this.dataArray.push({
        article_name: this.purchase_request_detail.article.name,
        article_id: this.purchase_request_detail.article.id,
        cant: this.purchase_request_detail.cant || 0,
        price: this.purchase_request_detail.price || 0,
        observation: this.purchase_request_detail.observation,
      });
      this.$refs.formArticle.reset();
    },
    removeArticle(article: selectArticles) {
      this.dataArray = this.dataArray.filter(
        (data) => data != (article as selectArticles)
      );
    },
    validateForm() {
      if (this.dataArray.length == 0) return false;
      return true;
    },
  },
  mounted() {
    this.loadData();
  },
});
interface selectArticles {
  article_name: string;
  article_id: number;
  cant: number;
  price: number;
  observation: string;
}
