







import TableUsers from '@/components/manteiners/maintainerUsers/TableUsers.vue'
import TemplateAdministrator from '@/templates/TemplateAdministrator.vue'
import Vue from 'vue'


export default Vue.extend({
  components: { 
    TemplateAdministrator,
    TableUsers
  },

})
