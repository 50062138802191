





























import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonCreateGroup from "./ButtonCreateGroup.vue";
import ButtonListUsers from "./addUserToGroup/ButtonListUsers.vue";
import ButtonListType from "./addTicketType/ButtonListType.vue";
export default Vue.extend({
  components: { ButtonCreateGroup, ButtonListUsers, ButtonListType },
  data() {
    return {
      headers: [
        {
          text: "Nombre",
          sortable: false,
          value: "name",
        },
        {
            text:"Acciones",
            sortable:false,
            value:"actions"
        }
      ],
    };
  },
  computed:{
      ...mapGetters({
          getGroups:'group/getGroups',
          getLoading:'group/getLoading'
      })
  },
  methods:{
      ...mapActions({
          setGroups:'group/getGroups'
      })
  },
  mounted(){
      this.setGroups();
  }
});
