import PurchaseRequest from '@/types/purchaseRequest/purchaseRequest'
import { BaseService } from './../../base'
export class PurchaseRequestService extends BaseService<PurchaseRequest>() {
    static get entity (): string {
        return 'purchase_request'
    }
    static get responseName (): string {
        return 'purchase_request'
    }
    /**
     * para filtrar las solicitudes asociadas al usuario segun su estado y etapa
     * @param payload user_id del usuario logeado, state y step del purchase_request_state
     * @returns 
     */
    static async getByStateStep(payload:dataByStateStep,page:number): Promise<PurchaseRequest[]> {
        return this.submit<PurchaseRequest[]>({
            method: 'get',
            path: `find_by_state_step/${payload.user_id}/${payload.state}/${payload.step}?page=${page}`
        });
    }

    static async getByUserId(user_id:number,page:number): Promise<PurchaseRequest[]> {
        return this.submit<PurchaseRequest[]>({
            method: 'get',
            path: `find_by_user/${user_id}?page=${page}`
        });
    }
}
export interface dataByStateStep{
    user_id:number,
    state:string,
    step:string,    
}