








































import TableFinancialManagerPending from "@/components/purchaseRequest/tables/tablesFinancialManager/TableFinancialManagerPending.vue";
import TableFinancialManagerReady from "@/components/purchaseRequest/tables/tablesFinancialManager/TableFinancialManagerReady.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: {
    TemplateAdministrator,
    TableFinancialManagerPending,
    TableFinancialManagerReady,

  },
  data() {
    return {
      tab: null,
    };
  },
});
