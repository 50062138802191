






































import User from "@/types/user/user";
import Vue, { PropType } from "vue";
import ImgAvatar from "../../perfilUserCard/ImgAvatar.vue"
export default Vue.extend({
  components: { ImgAvatar },
  props: {
    user: {
      type: Object as PropType<User>,
    },
  },
});
