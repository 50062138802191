import { GroupService } from "@/services/models/group/group";
import Group from "@/types/user/groups";
import { Commit } from "vuex";

interface stateInterface {
    group: Group[],
    loading: boolean,
}
const moduleGroup = {
    namespaced: true,
    state: {
        group: [],
        loading: false,
    } as stateInterface,
    getters: {
        getGroups(state: stateInterface): Group[] {
            return state.group;
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setGroups(state: stateInterface, group: Group[]): void {
            state.group = group;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getGroups({commit}: {commit: Commit}): Promise<void> {
            commit('setLoading', true);
            const GROUP = await GroupService.getAll();                        
            commit("setGroups", GROUP);
            commit('setLoading', false);
        },
    }
}

export default moduleGroup;