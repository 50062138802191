


























































// import ImgAvatar from "@/components/perfilUserCard/ImgAvatar.vue";
import { LocationService } from "@/services/models/user/location";
import LocationInterface from "@/types/user/location";

import User from "@/types/user/user";
import Vue from "vue";

import { mapGetters } from "vuex";
export default Vue.extend({
  // components: { ImgAvatar },
  props: {
    label: {
      default: false,
    },
    addCurrentUser: {
      type: Boolean,
      default: false,
    },
    multipleSelect: {
      type: Boolean,
    },
    iconProp: {
      type: String,
      default: "fa-user-friends",
    },
    functionChange: {
      type: Function,
    },
  },
  data() {
    return {
      selectedLocations: [] as LocationInterface[],
      menuProps: {
        disabled: false,
      },
      loading: false,
      locations: [] as LocationInterface[],
    };
  },
  // watch: {
  //   selectedLocations() {
  //     if (this.selectedLocations.length >= 1 && !this.multipleSelect) {
  //       // this.menuProps.disabled = true;
  //       this.selectedLocations = [this.selectedLocations[1]];
        
  //     }
  //   },
  // },
  mounted() {
    this.getLocations();
  },
  methods: {
    test() {
      if (this.selectedLocations.length > 1 && !this.multipleSelect) {        
        this.selectedLocations = [this.selectedLocations[1]];
      }
      if (this.functionChange) {
        this.functionChange();
      }
    },
    selectLocation(location: LocationInterface) {
      const locationToChange = this.locations.find(value => value.id == location.id);
      if (locationToChange) {
        this.selectedLocations = [locationToChange];
      }
    },
    async getLocations() {
      this.loading = true;
      this.locations = await LocationService.getByStablishmentId(
        this.getCurrentUserLoggedIn().stablishment_id
      );
      this.loading = false;
    },
    remove(locationToRemove: LocationInterface) {
      this.selectedLocations = this.selectedLocations.filter(
        (location) => location.id != locationToRemove.id
      );
      this.menuProps.disabled = false;
    },
  },
});
