

























































import { UserService } from "@/services/models/user/user";
import User from "@/types/user/user";
import Vue, { PropType } from "vue";
import { mapActions, mapMutations } from "vuex";
import MyFormInterface from "@/types/myFormInterface";
import UserInfo from "./../createUser/UserInfo.vue";
import UserLocation from "@/types/user/userLocation";
import UserModule from "@/types/user/userModule";
import compressImage from "@/others/compressFile";
import { formatRut } from "@/thirdParty/utils";
import UserProfilesEdit from "./UserProfilesEdit.vue";
import UserModulesEdit from "./UserModulesEdit.vue";
export default (Vue as MyFormInterface).extend({
  props: {
    userProp: {
      required: true,
      type: Object as PropType<User>,
    },
  },
  components: { UserInfo, UserProfilesEdit, UserModulesEdit },
  data() {
    return {
      loading: false,
      user: {} as User,
      usersLocations: [] as UserLocation[],
      usersModules: [] as UserModule[],
      step: 1,
      invalidStep: 0 as number,
      avatarFile: "" as string | null,
    };
  },
  async mounted() {
    this.getRoles();
    this.getStablishment();
    const userResponse = await UserService.getById(this.userProp.id);
    if (userResponse.avatar) {
      this.avatarFile = userResponse.avatar;
    }
    this.user = {
      ...userResponse,
      run: formatRut(userResponse.run),
      avatar: null
    };
		this.chargeStablishment(userResponse.stablishment);
  },
  methods: {
		...mapMutations({
      chargeStablishment: "stablishments/changeSatblishmentSelected",
    }),
    ...mapActions({
      getRoles: "roles/getRoles",
      getStablishment: "stablishments/getStablishments",
      getUsers: "users/getUsers",
    }),
    validateStep(isValid: boolean, step: number): void {
      if (!isValid) {
        this.invalidStep = step;
      }
    },
    async createUser() {
      this.loading = true;
      try {
        const areFormsValid = await this.validateMultipleForms();
        if (!areFormsValid) {
          this.loading = false;
          return;
        }
        const user = await UserService.update(this.userProp.id,{
          ...this.user,
          run: this.user.run.split(".").join(""),
        });
        if (this.user.avatar) {
          // eslint-disable-next-line
          const avatarCompressed = await compressImage(this.user.avatar as any);
          const formData = new FormData();
          formData.append("file", avatarCompressed);
          await UserService.updateAvatar(user.id, formData);
        }
        this.displayToast({
          type: "success",
          message: "El usuario se ha modificado correctamente",
        });
        this.$emit("changeDialog", false);
        // this.dialog = false;
        this.getUsers();
      } catch (error) {
        this.displayToast({
          type: "error",
          message:
            "Lo sentimos, ha ocurrido un error. Favor intentar más tarde",
        });
        console.log(error);
      }
      this.loading = false;
    },
  },
  watch: {
    invalidStep() {
      if (this.invalidStep != 0) {
        this.step = this.invalidStep;
      }
    },
  },
});
