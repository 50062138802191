import PurchaseRequestState from '@/types/purchaseRequest/purchaseState'
import { BaseService } from './../../base'
export class PurchaseRequestStateService extends BaseService<PurchaseRequestState>() {
    static get entity (): string {
        return 'purchase_request_state'
    }
    static get responseName (): string {
        return 'purchase_request_state'
    }
    static async getByPurchaseRequestId(purchaseId:number): Promise<PurchaseRequestState[]> {
        return this.submit<PurchaseRequestState[]>({
            method: 'get',
            path: `find_by_purchase_request_id/${purchaseId}`
        });
    }
}