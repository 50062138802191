




































import { DispatchLocationService } from "@/services/models/dispatch/dispatch_location";
import DispatchLocation from "@/types/dispatch/dispatch_location";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    dispatchProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      dispatch_location: {
        name: "" as string,
        stablishment_id: this.getCurrentUserLoggedIn()
          .stablishment_id as number,
      } as DispatchLocation,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      }
    },
  },
  methods: {
    ...mapActions({
      setDispatchLocation: "dispatchLocation/getDispatchLocation",
    }),
    async loadData() {
      this.isLoading = true;
      this.dispatch_location = await DispatchLocationService.getById(
        this.dispatchProp.id
      );
      this.isLoading = false;
    },
    async save() {
      try {
        this.displayLoader(true);
        await DispatchLocationService.update(
          this.dispatchProp.id,
          this.dispatch_location
        );
        this.setDispatchLocation(this.getCurrentUserLoggedIn().stablishment_id);
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Error al guardar",
        });
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
