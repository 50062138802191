






























import { BuyerService } from "@/services/models/purchaseRequest/buyer";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    buyerProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setBuyer: "buyer/getBuyer",
    }),
    async removeBuyer() {
      this.displayLoader(true);
      try {
        await BuyerService.remove(this.buyerProp.id);
        this.setBuyer();
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
