







import TableRequestTickets from "@/components/ticket/TableRequestTickets.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: { TemplateAdministrator, TableRequestTickets, },
});
