







import TablePurchaseRequest from "@/components/purchaseRequest/tables/TablePurchaseRequest.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: { 
      TemplateAdministrator,
    TablePurchaseRequest,   
  },  
});
