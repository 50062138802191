import DocumentDetailChecked from '@/types/documents/documentDetailChecked'
import { BaseService } from './../../base'
export class DocumentDetailCheckedService extends BaseService<DocumentDetailChecked>() {
    static get entity (): string {
        return 'document_detail_checked'
    }
    static get responseName (): string {
        return 'document_detail_checked'
    }
    static async getByDocumentDetailId(document_detail_id:number): Promise<DocumentDetailChecked[]> {
		return this.submit<DocumentDetailChecked[]>({
            method: 'get',
            path: `find_by_document_detail_id/${document_detail_id}`
        });
	}
}