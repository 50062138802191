import DocumentState from '@/types/documents/documentState'
import { BaseService } from '../../base'
export class DocumentStateService extends BaseService<DocumentState>() {
    static get entity () {
        return 'document_state'
    }
    static get responseName () {
        return 'document_state'
    } 
    /**
     * 
     * @param document_id Number
     * @param user_id Number
     * @returns 
     */
    static async getByDocumentIdUserId(document_id:number,user_id:number): Promise<DocumentState[]> {
        return this.submit<DocumentState[]>({
            method: 'get',
            path: `find_by_document_id_user_id/${document_id}/${user_id}`
        });
    }
}