import { Content, Margins, StyleDictionary } from "pdfmake/interfaces";

import logoRedondoSSM from "@/other/base64Logo";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import { TicketService } from '@/services/models/ticket/ticket';
import TicketClose from '@/types/tickets/ticketClose';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePDFSingleTicket = async (reportData: {
	ticketId: number
}): Promise<void> => {
	const pdfMake = await import("pdfmake/build/pdfmake.js");
	if (pdfMake.vfs == undefined) {
		const pdfFonts = await import("pdfmake/build/vfs_fonts.js");
		pdfMake.vfs = pdfFonts.pdfMake.vfs;
	}

	const ticket = await TicketService.getById(reportData.ticketId);

	const title = `Reporte ticket #${ticket.id} (${moment().format("DD-MM-YYYY")})`;

	let responsable: ({ text: string; style: string; colSpan: number; alignment: string; } | { text?: undefined; style?: undefined; colSpan?: undefined; alignment?: undefined; })[] | ({ text: string; style: string; } | { text: string; style?: undefined; })[] = [];
	if (ticket.state != "solicitado" && ticket.tickets_responsables?.length) {
		responsable = [
			{ text: 'Responsable apertura', style: 'tableHeader' },
			{ text: `${ticket.tickets_responsables[0].user?.name} ${ticket.tickets_responsables[0].user?.pather_lastname} ${ticket.tickets_responsables[0].user?.mother_lastname} (${moment(ticket.tickets_responsables[0].created_at).format("DD-MM-YYYY")})` },
		]
	} else {
		responsable = [
			{ text: 'Responsable apertura', style: 'tableHeader' },
			{ text: `Sin responsable` },
		]
	}
	//Autogenerado, ni idea que es esto
	let responsableClosing: ({ text: string; style: string; colSpan: number; alignment: string; } | { text?: undefined; style?: undefined; colSpan?: undefined; alignment?: undefined; })[] | ({ text: string; style: string; } | { text: string; style?: undefined; })[] = [];
	if ((ticket.state == "cerrado" || ticket.state == "cerrado permanentemente") && ticket.tickets_responsables?.length) {
		const lastResponsable = ticket.tickets_responsables[ticket.tickets_responsables.length - 1];
		responsableClosing = [
			{ text: 'Responsable cierre', style: 'tableHeader' },
			{ text: `${lastResponsable.user?.name} ${lastResponsable.user?.pather_lastname} ${lastResponsable.user?.mother_lastname} (${moment(lastResponsable.created_at).format("DD-MM-YYYY")})` },
		]
	} else {
		responsableClosing = [
			{ text: 'Responsable cierre', style: 'tableHeader' },
			{ text: `Sin responsable` },
		]
	}
	const ticketTable =
	{
		margin: [0, 10, 0, 0],
		table: {
			widths: ['*', '*'],
			body: [
				[
					{ text: `Detalle`, style: 'title', colSpan: 2, alignment: 'center' }, {}
				],
				[
					{ text: 'Título', style: 'tableHeader' },
					{ text: `${ticket.title}` },
				],
				[
					{ text: 'Descripción', style: 'tableHeader' },
					{ text: `${ticket.subject}` },
				],
				[
					{ text: 'Fecha de solicitud', style: 'tableHeader' },
					{ text: `${moment(ticket.created_at).format("DD-MM-YYYY")}` },
				],
				[
					{ text: 'Tipo de ticket', style: 'tableHeader' },
					{ text: `${ticket.ticket_type?.name}` },
				],
				[
					{ text: 'Generado por', style: 'tableHeader' },
					{ text: `${ticket.created_by?.name} ${ticket.created_by?.pather_lastname} ${ticket.created_by?.mother_lastname}` },
				],
				[
					{ text: 'Estado', style: 'tableHeader' },
					{ text: `${ticket.state}` },
				],
				[
					{ text: 'Grupo', style: 'tableHeader' },
					{ text: `${ticket.group?.name}` },
				],
				responsable,
				responsableClosing,
			],
		}
	};

	const tableHistories: (string | undefined)[][] = [];

	if (ticket.tickets_histories) {
		ticket.tickets_histories.forEach(ticketHistory => {
			tableHistories.push(
				[`${ticketHistory.user?.name} ${ticketHistory.user?.pather_lastname} ${ticketHistory.user?.mother_lastname}`, moment(ticketHistory.created_at).format("DD-MM-YYYY HH:mm"), ticketHistory.state]
			)
		});
	}

	const messages: (string | undefined)[][] = [];

	if (ticket.tickets_messages) {
		ticket.tickets_messages.forEach(ticketMessage => {
			messages.push(
				[`${ticketMessage.user?.name} ${ticketMessage.user?.pather_lastname} ${ticketMessage.user?.mother_lastname}`, moment(ticketMessage.created_at).format("DD-MM-YYYY HH:mm"), ticketMessage.message]
			)
		});
	}
	let tableMessages;
	if (messages.length) {
		tableMessages = {
			margin: [0, 10, 0, 0],
			table: {
				widths: ['*', '*', '*'],
				body: [
					[{text: 'Mensajes',  style: 'title', alignment: 'center', colSpan: 3},{},{}],
					[{text: 'Responsable',  style: 'subTitle', alignment: 'center'},{text: 'Fecha',  style: 'subTitle', alignment: 'center'},{text: 'Mensaje',  style: 'subTitle', alignment: 'center'}],
					...messages,
				]
			}
		}
	}

	let closedTicket!: TicketClose;
	if (ticket.tickets_closed) {
		closedTicket = ticket.tickets_closed[0];
	}
	let closedTicketTable;
	if (closedTicket) {
		closedTicketTable =
		{
			margin: [0, 10, 0, 0],
			table: {
				widths: ['*', '*'],
				body: [
					[
						{ text: `Detalle cierre del ticket`, style: 'title', colSpan: 2, alignment: 'center' }, {}
					],
					[
						{ text: 'Responsable', style: 'tableHeader' },
						{ text: `${closedTicket.user?.name} ${closedTicket.user?.pather_lastname} ${closedTicket.user?.mother_lastname}` },
					],
					[
						{ text: 'Fecha', style: 'tableHeader' },
						{ text: `${moment(closedTicket.created_at).format("DD-MM-YYYY HH:mm")}` },
					],
					[
						{ text: 'Observación', style: 'tableHeader' },
						{ text: `${closedTicket.observation}` },
					],
				],
			}
		};
	}


	const docDefinition = {
		pageMargins: [40, 30, 40, 60] as Margins,
		content: [
			{
				columns: [
					{
						image: logoRedondoSSM,
						width: 70,
					},
					[
						{
							text: title,
							style: 'titleHeader',
							margin: [0, 10, 40, 0]
						},
					],
				]
			},
			ticketTable,
			closedTicketTable,
			{
				margin: [0, 10, 0, 0],
				table: {
					widths: ['*', '*', '*'],
					body: [
						[{text: 'Acciones',  style: 'title', alignment: 'center', colSpan: 3},{},{}],
						[{text: 'Responsable',  style: 'subTitle', alignment: 'center'},{text: 'Fecha',  style: 'subTitle', alignment: 'center'},{text: 'Acción',  style: 'subTitle', alignment: 'center'}],
						...tableHistories,
					]
				}
			},
			tableMessages
		] as Content,
		styles: {
			title: {
				bold: true,
				fontSize: 15,
				color: 'white',
				fillColor: '#EE3A3A'
			},
			subTitle: {
				bold: true,
				fontSize: 14,
				color: 'black',
				fillColor: '#d9d9d9'
			},
			tableHeader: {
				bold: true,
				fontSize: 11,
				color: 'black',
				fillColor: '#e4e4e4'
			},
			tableExample: {
				alignment: "justify",
				margin: [0, -150, 0, 15],
			},
			toTheRight: {
				alignment: "right",
			},
			header: {
				fontSize: 17,
				alignment: "left",
				bold: true,
			},
			titleHeader: {
				fontSize: 18,
				bold: true,
				alignment: 'center'
			},
			subtitleHeader: {
				fontSize: 9,
				alignment: 'center'
			}
		} as StyleDictionary,
	};
	// pdfMake.createPdf(docDefinition).download(`Reporte ticket #${ticket.id}.pdf`);
	pdfMake.createPdf(docDefinition).open();
}
