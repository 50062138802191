















































import Vue from "vue";
import TicketMessage from "@/types/tickets/ticket_message";
import myFormInterface from "@/types/myFormInterface";
import { TicketMessageService } from "@/services/models/ticket/ticketMessage";
import { mapActions } from "vuex";

export default (Vue as myFormInterface).extend({
  props: {
    itemProp: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      ticket_message: {
        file: null,
      } as TicketMessage,
    };
  },
  methods: {
    ...mapActions({
      setMessage: "ticketMessage/getTicketMessage",
    }),
    async save() {
      this.displayLoader(true);
      try {
        let formData = new FormData();
        formData.append("message", this.ticket_message.message || '');
        formData.append("user_id", `${this.getCurrentUserLoggedIn().id}`);
        formData.append("ticket_id", this.itemProp.id);
        formData.append("file", this.ticket_message.file as Blob);
        await TicketMessageService.create(formData, {
          "Content-Type": "multipart/form-data",
        });
        this.ticket_message.file = null;
        this.setMessage(this.itemProp.id);
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Error al enviar el mensaje, favor de reintentar",
        });
      } finally {
        this.displayLoader(false);
      }
    },
    onButtonClick() {
      this.isLoading = true;
      window.addEventListener(
        "focus",
        () => {
          this.isLoading = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e: { target: { files: (string | Blob | null)[] } }) {
      this.ticket_message.file = e.target.files[0];
      // do something
    },
  },
});
