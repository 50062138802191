





























import { UserService } from "@/services/models/user/user";
import UserLocation from "@/types/user/userLocation";
import Vue from "vue";
export default Vue.extend({
  props: {
    changeStep: {
      required: false,
      type: Function,
    },
    changeUserLocationSelected: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      userLocations: [] as UserLocation[],
      loadingProfiles: false as boolean,
      userLocationSelected: {} as UserLocation,
    };
  },
  mounted() {
    this.loadingProfiles = true;
    this.loadUserLocations();
    this.loadingProfiles = false;
  },
  methods: {
    verifyUserLocation() {
      if (!this.userLocationSelected.id) {
        this.displayToast({
          type: "warning",
          message: "Por favor seleccione un perfil antes de continuar",
        });
        return;
      }
      this.changeStep(2);
      this.changeUserLocationSelected(this.userLocationSelected);
    },
    async loadUserLocations() {
      const user = await UserService.getById(this.getCurrentUserLoggedIn().id);
      let locations: UserLocation[] = [];
      if (user.users_locations) {
        locations = user.users_locations.map((location) => ({
          ...location,
          completeName: `${location.position ? location.position.name : ""} ${
            location.location ? location.location.name : ""
          }`,
        }));
      }
      this.userLocations = locations;
    },
  },
});
