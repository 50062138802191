


















import Vue from "vue";
import CreateUserContent from "./CreateUserContent.vue";
export default Vue.extend({
  components: { CreateUserContent },
  data() {
    return {
      dialog: false,
    };
  }
});
