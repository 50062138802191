





























import { TicketCloseService } from "@/services/models/ticket/ticketClose";
import TicketClose from "@/types/tickets/ticketClose";
import Vue from "vue";
export default Vue.extend({
    props: {
    itemProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      ticketClose:[] as Array<TicketClose>
    };
  },
  methods:{
      async loadData(){         
          this.ticketClose=await TicketCloseService.getByTicketId(this.itemProp.id)          
      }      
  },
  watch: {
   dialog() {
     if (this.dialog) {
         this.displayLoader(true)
         this.loadData();
         this.displayLoader(false)
    }
   }
  },
});
