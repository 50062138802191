


















































import { mapActions } from "vuex";
import Vue from "vue";
import myFormInterface from "@/types/myFormInterface";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
import VisorEditable from "./VisorEditable.vue";
import DocumentDetailChange from "@/types/documents/documentDetailChanges";
import { DocumentDetailChangesService } from "@/services/models/document/documentDetailChanges";
import ListChanges from "./ListChanges.vue";
import DocumentDetail from "@/types/documents/documentDetail";

export default (Vue as myFormInterface).extend({
  name: "app",
  props: {
    detailProp: {
      type: Object,
    },
  },
  components: {
    VisorEditable,
    ListChanges,
  },
  data() {
    return {
      show: false,
      dialog: false as boolean,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.displayLoader(true);
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        });
      } else {
        this.displayLoader(false);
      }
    },    
  },
  methods: {
    ...mapActions({
      getLoader: "loader/getState",
      getDocumentsDetail: "documentDetail/getByDocumentId",
    }),

    //Guardar pdf
    async saveDocumentDetail() {
      this.displayLoader(true);
      try {
        let dataPut = {
          ...(await DocumentDetailService.getById(this.detailProp.id)),
          file_generated: this.$refs.visorEditable.content,
        };
        let response = await DocumentDetailService.update(
          this.detailProp.id,
          dataPut
        );
        let dataChange: DocumentDetailChange = {
          id: 0,
          user_id: this.getCurrentUserLoggedIn().id,
          document_detail_id: response.id,
          file_generated: this.$refs.visorEditable.content,
        };
        await DocumentDetailChangesService.create(dataChange);
        this.getDocumentsDetail(this.detailProp.document_id);
        this.displayLoader(false);
        this.displayToast({
          type: "success",
          message: "Documento Generado",
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
        this.dialog = false;
      }
    },
    async savePdf(blob: Blob) {
      let formData: FormData = new FormData();
      formData.append("document_id", "13");
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("user") || "{}").id
      );
      formData.append("state", "disponible");
      formData.append("file", blob);

      await DocumentDetailService.create(formData, {
        "Content-Type": "multipart/form-data",
      });
    },

    dataURLtoFile(dataurl: any, filename: any) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    //No Tocar >:c
    async beforeDownload({ html2pdf, options, pdfContent }: beforeDownload) {
      let convertBaseToPdf = this.dataURLtoFile;
      let save = this.savePdf;
      this.getLoader(true);
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .outputPdf()
        .then(function (pdf: any) {
          // Genera base64
          console.log(btoa(pdf));
          let file = convertBaseToPdf(
            `data:text/plain;base64,${btoa(pdf)}`,
            "hello.pdf"
          );
          console.log(file);
          save(file);
        });
      this.getLoader(false);
    },

    selectedPreviousTemplate(item:DocumentDetail){
      if(item){
        this.$refs.visorEditable.content=item.file_generated as string
      }else{
        this.$refs.visorEditable.content=this.detailProp.file_generated
      }
    }
  },
});
interface beforeDownload {
  html2pdf: any;
  options: Record<string, unknown>;
  pdfContent: Record<string, unknown>;
}
