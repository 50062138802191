import { GroupService } from '@/services/models/group/group';
import { Content, Margins, StyleDictionary } from "pdfmake/interfaces";
// import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
// import { PurchaseRequestStateService } from '@/services/models/purchaseRequest/purchaseRequestState'
// import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";

import logoRedondoSSM from "@/other/base64Logo";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import Ticket from "@/types/tickets/tickets";
import UserGroup from "@/types/user/userGroup";
import User from "@/types/user/user";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// function numberWithCommas(x: number) {
// 	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
// }
export const generatePDFTickets = async (reportData: {
	tickets: Ticket[],
	dateStart: string,
	dateEnd: string,
	groupId?: number,
	user: User,
}) => {
	const pdfMake = await import("pdfmake/build/pdfmake.js");
	if (pdfMake.vfs == undefined) {
		const pdfFonts = await import("pdfmake/build/vfs_fonts.js");
		pdfMake.vfs = pdfFonts.pdfMake.vfs;
	}

	let title: string;
	console.log(reportData);
	
	if (reportData.groupId) {
		const group = await GroupService.getById(reportData.groupId);
		title = `Reporte de tickets ${group.name} ${moment(reportData.dateStart).format("DD-MM-YYYY")} / ${moment(reportData.dateEnd).format("DD-MM-YYYY")}`;
	} else {
		title = `Reporte de tickets ${reportData.user.name} ${reportData.user.pather_lastname} ${reportData.user.mother_lastname} ${moment(reportData.dateStart).format("DD-MM-YYYY")} / ${moment(reportData.dateEnd).format("DD-MM-YYYY")}`;
	}

	//Autogenerado, ni idea que es esto
	const tableTickets: { margin: number[]; table: { widths: string[]; body: (({ text: string; style: string; colSpan: number; alignment: string; } | { text?: undefined; style?: undefined; colSpan?: undefined; alignment?: undefined; })[] | ({ text: string; style: string; } | { text: string; style?: undefined; })[])[]; }; }[] = [];
	reportData.tickets.forEach(ticket => {
		//Autogenerado, ni idea que es esto
		let responsable: ({ text: string; style: string; colSpan: number; alignment: string; } | { text?: undefined; style?: undefined; colSpan?: undefined; alignment?: undefined; })[] | ({ text: string; style: string; } | { text: string; style?: undefined; })[] = [];
		if (ticket.state != "solicitado" && ticket.tickets_responsables?.length) {
			responsable = [
				{ text: 'Responsable apertura', style: 'tableHeader' },
				{ text: `${ticket.tickets_responsables[0].user?.name} ${ticket.tickets_responsables[0].user?.pather_lastname} ${ticket.tickets_responsables[0].user?.mother_lastname} (${moment(ticket.tickets_responsables[0].created_at).format("DD-MM-YYYY")})` },
			]
		} else {
			responsable = [
				{ text: 'Responsable apertura', style: 'tableHeader' },
				{ text: `Sin responsable` },
			]
		}
		//Autogenerado, ni idea que es esto
		let responsableClosing: ({ text: string; style: string; colSpan: number; alignment: string; } | { text?: undefined; style?: undefined; colSpan?: undefined; alignment?: undefined; })[] | ({ text: string; style: string; } | { text: string; style?: undefined; })[] = [];
		if ((ticket.state == "cerrado" || ticket.state == "cerrado permanentemente") && ticket.tickets_responsables?.length) {
			const lastResponsable = ticket.tickets_responsables[ticket.tickets_responsables.length - 1];
			responsableClosing = [
				{ text: 'Responsable cierre', style: 'tableHeader' },
				{ text: `${lastResponsable.user?.name} ${lastResponsable.user?.pather_lastname} ${lastResponsable.user?.mother_lastname} (${moment(lastResponsable.created_at).format("DD-MM-YYYY")})` },
			]
		} else {
			responsableClosing = [
				{ text: 'Responsable cierre', style: 'tableHeader' },
				{ text: `Sin responsable` },
			]
		}
		tableTickets.push(
			{
				margin: [0, 10, 0, 0],
				table: {
					widths: ['*', '*'],
					body: [
						[
							{ text: `Ticket #${ticket.id}`, style: 'title', colSpan: 2, alignment: 'center' }, {}
						],
						[
							{ text: 'Estado', style: 'tableHeader' },
							{ text: `${ticket.state}` },
						],
						[
							{ text: 'Solicitante', style: 'tableHeader' },
							{ text: `${ticket.user?.name} ${ticket.user?.pather_lastname} ${ticket.user?.mother_lastname}` },
						],
						[
							{ text: 'Fecha de solicitud', style: 'tableHeader' },
							{ text: `${moment(ticket.created_at).format("DD-MM-YYYY")}` },
						],
						[
							{ text: 'Título', style: 'tableHeader' },
							{ text: `${ticket.title}` },
						],
						[
							{ text: 'Descripción', style: 'tableHeader' },
							{ text: `${ticket.subject}` },
						],
						responsable,
						responsableClosing,
					],
				}
			}
		)
		
	});
	console.log(tableTickets);

	const docDefinition = {
		pageMargins: [40, 30, 40, 60] as Margins,
		content: [
			{
				columns: [
					{
						image: logoRedondoSSM,
						width: 70,
					},
					[
						{
							text: title,
							style: 'titleHeader',
							margin: [0, 10, 40, 0]
						},
					],
				]
			},
			...tableTickets,
		] as Content,
		styles: {
			title: {
				bold: true,
				fontSize: 15,
				color: 'white',
				fillColor: '#EE3A3A'
			},
			tableHeader: {
				bold: true,
				fontSize: 11,
				color: 'black',
				fillColor: '#e4e4e4'
			},
			tableExample: {
				alignment: "justify",
				margin: [0, -150, 0, 15],
			},
			toTheRight: {
				alignment: "right",
			},
			header: {
				fontSize: 17,
				alignment: "left",
				bold: true,
			},
			titleHeader: {
				fontSize: 18,
				bold: true,
				alignment: 'center'
			},
			subtitleHeader: {
				fontSize: 9,
				alignment: 'center'
			}
		} as StyleDictionary,
	};
	// pdfMake.createPdf(docDefinition).download("Reporte.pdf");
	pdfMake.createPdf(docDefinition).open();
}
