import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import PurchaseBuyer from "@/types/purchaseRequest/purchaseBuyer";
import ResponsePaginated from "@/types/responsePaginated";
import { Commit } from "vuex";

interface stateInterface {
    purchaseBuyer: PurchaseBuyer[],

    purchaseBuyerPending: ResponsePaginated<PurchaseBuyer[]>,
    purchaseBuyerReady: ResponsePaginated<PurchaseBuyer[]>,

    loading: boolean,
}
const modulePurchaseBuyer = {
    namespaced: true,
    state: {
        purchaseBuyer: [],
        purchaseBuyerPending: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        purchaseBuyerReady: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        loading: false,
    } as stateInterface,
    getters: {
        getPurchaseBuyer(state: stateInterface): PurchaseBuyer[] {
            return state.purchaseBuyer;
        },

        getPurchaseBuyerPending(state: stateInterface): ResponsePaginated<PurchaseBuyer[]> {
            return state.purchaseBuyerPending
        },
        getPurchaseBuyerReady(state: stateInterface): ResponsePaginated<PurchaseBuyer[]> {
            return state.purchaseBuyerReady
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setPurchaseBuyer(state: stateInterface, purchaseBuyer: PurchaseBuyer[]): void {
            state.purchaseBuyer = purchaseBuyer;
        },

        setPurchaseBuyerPending(state: stateInterface, purchaseBuyerPending: ResponsePaginated<PurchaseBuyer[]>): void {
            state.purchaseBuyerPending = purchaseBuyerPending
        },
        setPurchaseBuyerReady(state: stateInterface, purchaseBuyerReady: ResponsePaginated<PurchaseBuyer[]>): void {
            state.purchaseBuyerReady = purchaseBuyerReady
        },

        //Paginación
        setPagePurchaseBuyerPending(state: stateInterface, page: number): void {
            state.purchaseBuyerPending.current_page = page;
        },
        setPagePurchaseBuyerReady(state: stateInterface, page: number): void {
            state.purchaseBuyerReady.current_page = page;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getPurchaseBuyer({ commit }: { commit: Commit }, id: number): Promise<void> {
            commit('setLoading', true);
            const PURCHASEBUYER = await PurchaseBuyerService.getByPurchaseId(id);
            commit("setPurchaseBuyer", PURCHASEBUYER);
            commit('setLoading', false);
        },
        async getPurchaseBuyerPending({ commit, state }: { commit: Commit, state: stateInterface }, payload: DataFindByPurchaseBuyerState): Promise<void> {
            commit('setLoading', true);
            const PURCHASEBUYER = await PurchaseBuyerService.getByPurchaseBuyerState(payload, state.purchaseBuyerPending.current_page)
            commit('setPurchaseBuyerPending', PURCHASEBUYER);
            commit('setLoading', false);
        },
        async getPurchaseBuyerReady({ commit, state }: { commit: Commit, state: stateInterface }, payload: DataFindByPurchaseBuyerState): Promise<void> {
            commit('setLoading', true);
            const PURCHASEBUYER = await PurchaseBuyerService.getByPurchaseBuyerState(payload, state.purchaseBuyerReady.current_page)
            commit('setPurchaseBuyerReady', PURCHASEBUYER);
            commit('setLoading', false);
        },
    }
}

export interface DataFindByPurchaseBuyerState{
    userId: number, 
    state: string, 
    step:string
}
export default modulePurchaseBuyer;