








































import TableBudgetManagerPending from "@/components/purchaseRequest/tables/tableBudgetManager/TableBudgetManagerPending.vue";
import TableBudgetManagerReady from "@/components/purchaseRequest/tables/tableBudgetManager/TableBudgetManagerReady.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: {
    TemplateAdministrator,
    TableBudgetManagerPending,
    TableBudgetManagerReady
  },
  data() {
    return {
      tab: null,
    };
  },
});
