

































import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import PurchaseHistory from "@/types/purchaseRequest/purchaseHistory";
import Vue from "vue";
export default Vue.extend({
    props:{
        purchaseProp:{
            type:Object
        }
    },
  data() {
    return {
      getLoading: false as boolean,
      getItems: [] as Array<PurchaseHistory>,
      headers: [        
        {
          text: "Fecha",
          sortable: false,
          value: "date",
        },
        {
          text: "Funcionario Origen",
          sortable: false,
          value: "origin",
        },
        {
          text: "Funcionario Destinatario",
          sortable: false,
          value: "destination",
        },
        {
          text: "Estado",
          sortable: false,
          value: "state",
        },
      ],
    };
  },
  mounted(){
      this.loadData();
  },
  methods:{
      async loadData(){
          this.getLoading=true;
          this.getItems=await PurchaseHistoryService.getByPurchaseId(this.purchaseProp.id);
          this.getLoading=false;
      }
  }
});
