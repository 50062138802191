import Dispatch from '@/types/dispatch/dispatch'
import ResponsePaginated from '@/types/responsePaginated';
import { BaseService } from './../../base'
export class DispatchService extends BaseService<Dispatch>() {
    static get entity (): string {
        return 'dispatch'
    }
    static get responseName (): string {
        return 'dispatch'
    }
    /**
     * 
     * @param stablishment_id Id establecimiento del usuario logeado
     * @returns 
     */
    static async getStablishmentId(stablishment_id:number,page:number): Promise<ResponsePaginated<Dispatch[]>> {
        return this.submit<ResponsePaginated<Dispatch[]>>({
            method: 'get',
            path: `find_by_stablishment_id/${stablishment_id}?page=${page}`
        });
    }
}