



































import Vue from "vue";
import FirmDocumentContent from "./FirmDocumentContent.vue";
export default Vue.extend({
  components: { FirmDocumentContent },
  props: {
    documentDetailId: {
      required: true,
      type: Number,
    },
    documentId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
});
