



















































import Toast from "@/components/customComponents/Toast.vue";
import CloseSession from "@/components/general/CloseSession.vue";
import SideMenuAdministrator from "@/views/administrator/SideMenu.vue";
import SideMenuAdministrative from "@/views/administrative/SideMenu.vue";
import SideMenuOpartes from "@/views/opartes/SideMenu.vue";
import Vue from "vue";
import Relogin from "@/components/general/Relogin.vue";
import { mapActions, mapGetters } from "vuex";
import User from "@/types/user/user";
import Loader from "@/components/customComponents/Loader.vue";
import Confirm from "@/components/customComponents/Confirm.vue";
export default Vue.extend({
  components: {
    CloseSession,
    SideMenuAdministrator,
    SideMenuAdministrative,
    SideMenuOpartes,
    Toast,
    Relogin,
    Loader,
    Confirm,
  },
  data() {
    return {
      currentUser: {} as User,
      drawer: true,
      global: process.env.VUE_APP_GLOBAL_VERSION,
      perfil: localStorage.getItem("perfil"),
    };
  },
  computed: {
    ...mapGetters({
      user: "users/getUser",
      loading: "users/getLoading",
    }),
  },
  mounted() {
    this.currentUser = this.getCurrentUserLoggedIn();
    this.getUser(this.currentUser.id);
  },
  methods: {
    ...mapActions({
      getUser: "users/getUser",
    }),
  },
});
