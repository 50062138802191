import Permission from '@/types/user/permission'
import { BaseService } from './../../base'
export class PermissionService extends BaseService<Permission>() {
    static get entity (): string {
        return 'permission'
    }
    static get responseName (): string {
        return 'permission'
    }

    static async getByModuleId(moduleId: number): Promise<Permission[]> {
        return this.submit<Permission[]>({
            method: 'get',
            path: `find_by_module_id/${moduleId}`
        });
    }
}