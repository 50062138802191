























import { listTemplates } from "@/others/templateDocuments/listTemplates";
import Editor from "@tinymce/tinymce-vue";
import VueHtml2pdf from "vue-html2pdf";
import store from "@/store";
import Vue from "vue";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
export default Vue.extend({
  props: {
    contentProp: {
      type: Object,
    },
  },
  components: {
    Editor,
    VueHtml2pdf,
  },
  async mounted() {
    if(this.contentProp){
      console.log(this.$refs.editorTiny);
      let data=await DocumentDetailService.getById(this.contentProp.id);
      this.content=data.file_generated as string
    }   
  },
  data() {
    return {
      content: "" as string,
      configurationTiny: {
        init_instance_callback: function () {
          store.commit("loader/setLoader", false);
        },
        height: 600 as number,
        menubar: true as boolean,
        language: "es" as string,
        readonly: false as boolean,
        plugins: [
          "lists print preview anchor",
          "searchreplace visualblocks",
          "table paste",
          "template",
          "pagebreak",
        ],
        toolbar:
          "undo redo | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | fontsizeselect \
           bullist numlist outdent indent | pagebreak | template" as string,
        templates: [...listTemplates],
      },
    };
  },
});
