import DocumentDetailFirmed from '@/types/documents/documentDetailFirmed'
import { BaseService } from './../../base'
export class DocumentDetailFirmedService extends BaseService<DocumentDetailFirmed>() {
    static get entity (): string {
        return 'document_detail_firmed'
    }
    static get responseName (): string {
        return 'document_detail_firmed'
    }
    static async getByDocumentDetailId(document_detail_id:number): Promise<DocumentDetailFirmed[]> {
		return this.submit<DocumentDetailFirmed[]>({
            method: 'get',
            path: `find_by_document_detail_id/${document_detail_id}`
        });
	}
}