














































































































































































































































































































import Budget from "@/types/purchaseRequest/budget";
import Provider from "@/types/purchaseRequest/provider";
import PurchaseMethod from "@/types/purchaseRequest/purchaseMethod";
import Causal from "@/types/purchaseRequest/causal";
import Project from "@/types/purchaseRequest/project";
import SupplementaryAccount from "@/types/purchaseRequest/suplementaryAccount";

import { ProviderService } from "@/services/models/purchaseRequest/provider";
import { BudgetService } from "@/services/models/purchaseRequest/budget";
import { PurchaseMethodService } from "@/services/models/purchaseRequest/purchaseMethod";
import { SupplementaryAccountService } from "@/services/models/purchaseRequest/supplementarieAccount";
import { CausalService } from "@/services/models/purchaseRequest/causal";
import { ProjectService } from "@/services/models/purchaseRequest/project";
import { HeaderService } from "@/services/models/purchaseRequest/header";

import Vue from "vue";
import moment from "moment";
import Header from "@/types/purchaseRequest/header";
import PurchaseRequestDetail from "@/types/purchaseRequest/purchaseRequestDetail";
import { CdpService } from "@/services/models/purchaseRequest/cdp";
export default Vue.extend({
  props: {
    purchaseProp: {
      type: Object,
    },
    cdpProps: {
      type: Object,
    },
  },
  data() {
    return {
      data: {
        id: 0 as number,
        description: "" as string,
        n_request: null as unknown as number,
        pay_month: "" as string,
        authorized_amount: null as unknown as number,
        budget_amount: null as unknown as number,
        purchase_plan: null as unknown as string,
        detail: "" as string,
        date: moment().format("DD-MM-YYYY"),
        title: "" as string,
        accrued_month: "" as string,
        purchase_buyer_id: 0 as number,
        number_id: null as unknown as number,
        user_id: this.getCurrentUserLoggedIn().id,

        cdps_articles: [] as Array<PurchaseRequestDetail>,
        header_id: 0 as number,
        causal_id: null as unknown as number,
        provider_id: null as unknown as number,
        budget_id: null as unknown as number,
        project_id: null as unknown as number,
        suplementary_account_id: null as unknown as number,
        purchase_method_id: null as unknown as number,
      } as cdpData,
      headerSelected: {
        id: 0 as number,
        name: "" as string,
        description: "" as string,
      } as headerInterface,
      menuProps: {
        disabled: false,
      },
      providers: [] as Array<Provider>,
      budgets: [] as Array<Budget>,
      purchasesMethod: [] as Array<PurchaseMethod>,
      supplementariesAccounts: [] as Array<SupplementaryAccount>,
      causals: [] as Array<Causal>,
      projects: [] as Array<Project>,
      headers: [] as Array<Header>,
    };
  },
  methods: {
    changeHeader() {
      this.data.header_id = this.headerSelected.id;
      this.data.title = this.headerSelected.name;
      this.data.description = this.headerSelected.description;
    },
    remove(item: PurchaseRequestDetail) {
      this.data.cdps_articles = this.data.cdps_articles.filter(
        (data: PurchaseRequestDetail) => data.id != item.id
      );
      this.menuProps.disabled = false;
    },
    async loadData() {
      this.displayLoader(true);
      try {
        let promises = [];
        promises.push(
          new Promise((resolve, reject) => {
            ProviderService.getAll()
              .then((response) => {
                this.providers = response;
                console.log(this.providers);
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
        promises.push(
          new Promise((resolve, reject) => {
            HeaderService.getAll()
              .then((response) => {
                this.headers = response;
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
        promises.push(
          new Promise((resolve, reject) => {
            BudgetService.getAll()
              .then((response) => {
                this.budgets = response;
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
        promises.push(
          new Promise((resolve, reject) => {
            PurchaseMethodService.getAll()
              .then((response) => {
                this.purchasesMethod = response;
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
        promises.push(
          new Promise((resolve, reject) => {
            SupplementaryAccountService.getAll()
              .then((response) => {
                this.supplementariesAccounts = response;
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
        promises.push(
          new Promise((resolve, reject) => {
            CausalService.getAll()
              .then((response) => {
                this.causals = response;
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
        promises.push(
          new Promise((resolve, reject) => {
            ProjectService.getAll()
              .then((response) => {
                this.projects = response;
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
        );
        Promise.all(promises)
          .then(async () => {
            if (this.cdpProps) {
              this.displayLoader(true);
              let result = await CdpService.getById(this.cdpProps.id);              
              
              this.data = result as unknown as cdpData;
              this.headerSelected = this.data.header as headerInterface;
              this.data.description = this.data.header?.description as string;
              result.cdps_articles.forEach(element => this.data.cdps_articles.push(element.purchase_request_detail as PurchaseRequestDetail));              
            }
            this.displayLoader(false);
          })
          .catch((reason) => {
            console.log(reason);
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    this.loadData();
  },
});
interface cdpData {
  id: number;
  description: string;
  n_request: number;
  pay_month: string;
  authorized_amount: number;
  budget_amount: number;
  purchase_plan: string;
  detail: string;
  date: string;
  title: string;
  accrued_month: string;
  purchase_buyer_id: number;
  number_id: number;
  user_id: number;

  cdps_articles: Array<PurchaseRequestDetail>;
  header_id: number;
  causal_id: number;
  provider_id: number;
  budget_id: number;
  project_id: number;
  suplementary_account_id: number;
  purchase_method_id: number;  
  header?:headerInterface
}
interface headerInterface{
  id: number; name: string; description: string 
}
