import { BuyerService } from "@/services/models/purchaseRequest/buyer";
import Buyer from "@/types/purchaseRequest/buyer";
import ResponsePaginated from "@/types/responsePaginated";
import { Commit } from "vuex";

interface stateInterface {
    buyerAssign: Buyer[],
    buyerPending: ResponsePaginated<Buyer[]>,
    buyerReady: ResponsePaginated<Buyer[]>,
    buyerInProcess:ResponsePaginated<Buyer[]>,
    loading: boolean,
}
const moduleBuyer = {
    namespaced: true,
    state: {
        buyerAssign: [],
        buyerPending: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        buyerReady: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        buyerInProcess: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },

        loading: false,
    } as stateInterface,
    getters: {
        getBuyerAssign(state: stateInterface): Buyer[] {
            return state.buyerAssign;
        },

        getBuyerPending(state: stateInterface): ResponsePaginated<Buyer[]> {
            return state.buyerPending
        },
        getBuyerReady(state: stateInterface): ResponsePaginated<Buyer[]> {
            return state.buyerReady
        },
        getBuyerInProcess(state: stateInterface): ResponsePaginated<Buyer[]> {
            return state.buyerInProcess
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setBuyerAssign(state: stateInterface, buyerAssign: Buyer[]): void {
            state.buyerAssign = buyerAssign;
        },

        setBuyerPending(state: stateInterface, buyerPending: ResponsePaginated<Buyer[]>): void {
            state.buyerPending = buyerPending
        },
        setBuyerReady(state: stateInterface, buyerReady: ResponsePaginated<Buyer[]>): void {
            state.buyerReady = buyerReady
        },
        setBuyerInProcess(state: stateInterface, buyerInProcess: ResponsePaginated<Buyer[]>): void {
            state.buyerInProcess = buyerInProcess
        },

        setPageBuyerPending(state: stateInterface, page: number): void {
            state.buyerPending.current_page = page;
        },
        setPageBuyerReady(state: stateInterface, page: number): void {
            state.buyerReady.current_page = page;
        },
        setPageBuyerInProcess(state: stateInterface, page: number): void {
            state.buyerInProcess.current_page = page;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getBuyerAssign({ commit }: { commit: Commit }, purchaseRequestId: number): Promise<void> {
            commit('setLoading', true);
            const BUYER = await BuyerService.getFindPurchaseRequestId(purchaseRequestId);
            commit("setBuyerAssign", BUYER);
            commit('setLoading', false);
        },
        async getBuyerPending({ commit, state }: { commit: Commit, state: stateInterface }, payload: { userId: number, state: string }): Promise<void> {
            commit('setLoading', true);
            const BUYER = await BuyerService.getFindByUserBuyerIdState(payload.userId, payload.state, state.buyerPending.current_page)
            commit('setBuyerPending', BUYER);
            commit('setLoading', false);
        },
        async getBuyerReady({ commit, state }: { commit: Commit, state: stateInterface }, payload: { userId: number, state: string }): Promise<void> {
            commit('setLoading', true);
            const BUYER = await BuyerService.getFindByUserBuyerIdState(payload.userId, payload.state, state.buyerReady.current_page)
            commit('setBuyerReady', BUYER);
            commit('setLoading', false);
        },
        async getBuyerInProcess({ commit, state }: { commit: Commit, state: stateInterface }, payload: { userId: number, state: string }): Promise<void> {
            commit('setLoading', true);
            const BUYER = await BuyerService.getFindByUserBuyerIdState(payload.userId, payload.state, state.buyerReady.current_page)
            commit('setBuyerInProcess', BUYER);
            commit('setLoading', false);
        }
    }
}

export default moduleBuyer;