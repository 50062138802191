
















































import Vue from "vue";
import ModalFirmDocument from "../../firmDocument/ModalFirmDocument.vue";
import ButtonChecked from "./ButtonChecked.vue";
import ButtonInvalidDetail from "./ButtonInvalidDetail.vue";
import ButtonSeeFile from "./ButtonSeeFile.vue";
import DialogDetailFiles from "./DetailFiles/DialogDetailFiles.vue";
import PanelCheckeds from "./PanelCheckeds.vue";
import PanelFirms from "./PanelFirms.vue";
import ButtonListReaders from "./tableReaders/ButtonListReaders.vue";
import EditTemplateDocument from "../createTemplateDocument/EditTemplateDocument.vue";
import DocumentDetail from "@/types/documents/documentDetail";
export default Vue.extend({
  components: {
    ButtonChecked,
    ButtonInvalidDetail,
    ButtonSeeFile,
    ModalFirmDocument,
    DialogDetailFiles,
    ButtonListReaders,
    PanelCheckeds,
    PanelFirms,
    EditTemplateDocument,
  },
  props: {
    documentDetail: {
      type: Object,
    },
    documentProp: {
      type: Object,
    },
    open: {
      type: Number,
    },
  },
  data: () => ({
    direction: "left",
    fab: false,
    fling: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: "slide-y-reverse-transition",
  }),
  methods: {
    canCheck(documentDetail: DocumentDetail) {
      let canFirm = false;
      if (documentDetail.documents_details_checked) {
        canFirm = !!documentDetail.documents_details_checked.find(
          (detail) =>
            detail.state == "por visar" &&
            detail.user_id == this.getCurrentUserLoggedIn().id
        );
      }
      return canFirm;
    },
    canFirm(documentDetail: DocumentDetail) {
      let canFirm = false;
      if (documentDetail.documents_details_firmed) {
        canFirm = !!documentDetail.documents_details_firmed.find(
          (detail) =>
            detail.state == "por firmar" &&
            detail.user_id == this.getCurrentUserLoggedIn().id
        );
      }
      return canFirm;
    },
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    },
  },
});
