
































import Vue from "vue";
import ButtonAssignBuyer from "./buttonAssignBuyer/ButtonAssignBuyer.vue";
import ButtonCloseBuyer from "./ButtonCloseBuyer.vue";
import ButtonListCdp from "./buttonListCdp/ButtonListCdp.vue";
import ButtonListPurchaseBuyers from "./buttonListPurchaseBuyer/ButtonListPurchaseBuyers.vue";
import ButtonPrintReportPurchaseRequest from "./ButtonPrintReportPurchaseRequest.vue";
import DeletePurchaseRequest from "./DeletePurchaseRequest.vue";
import SendPurchaseRequest from "./sendPurchaseRequest/SendPurchaseRequest.vue";
import ButtonSendToBudget from './ButtonSendToBudget.vue';
export default Vue.extend({
  components: {
    SendPurchaseRequest,
    ButtonPrintReportPurchaseRequest,
    DeletePurchaseRequest,
    ButtonAssignBuyer,
    ButtonListPurchaseBuyers,
    ButtonCloseBuyer,
    ButtonListCdp,
    ButtonSendToBudget
  },
  props: {
    purchaseProp: {
      type: Object,
    },
    buyerProp:{
      type:Object
    },
    purchaseBuyerProps:{
      type:Object
    },
    
    dataSend: {
      type: Object,
    },
    dataSendToFinancialManager: {
      type: Object,
    },
    recharge:{
      type:Function
    },
    labelToButtonSendBudget:{
      type:String,      
      default:'Enviar Registro'
    },

    showDelete: {
      default: false,
    },
    showSendPurchaseRequest:{
      default:false
    },
    showButtonAssignBuyer: {
      default: false,
    },
    showButtonPurchaseBuyer:{
      default:false
    },
    showSendPurchaseBuyer:{
      default:false
    },
    showSendPurchaseToFinancialManager:{
      default:false
    },
    showCloseBuyer:{
      default:false
    },
    showListCdp:{
      default:false
    }
  },
});
