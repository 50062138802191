import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../views/Login.vue'
import {routes as Administrator} from './administrator'
import {routes as Administrative} from './administrative'
import {routes as Opartes} from './opartes'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  }, 
  ...Administrator,
  ...Administrative,
  ...Opartes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
