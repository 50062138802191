




























































import { DispatchFileService } from "@/services/models/dispatch/dispatch_file";
import DispatchFile from "@/types/dispatch/dispatch_file";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      dialog: false,
      isLoading: false,
      items: [] as Array<DispatchFile>,
      headers: [
        {
          text: "Nombre",
          sortable: false,
          align: "start",
          value: "name",
        },
        { text: "Acciones", sortable: false, align: "start", value: "action" },
      ],
    };
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.items = await DispatchFileService.getAll();
      this.isLoading = false;
    },
    async removeFile(item:DispatchFile) {
      this.isLoading = true;
      await DispatchFileService.remove(item.id);
      this.loadData();
      this.isLoading = false;
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.loadData();
      }
    },
  },
});
