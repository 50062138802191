import TicketResponsable from '@/types/tickets/tickets_responsables'
import { BaseService } from './../../base'
export class TicketResponsableService extends BaseService<TicketResponsable>() {
    static get entity (): string {
        return 'ticket_responsable'
    }
    static get responseName (): string {
        return 'ticket_responsable'
    }
    static async getByTicketId(ticket_id:number): Promise<TicketResponsable[]> {
        return this.submit<TicketResponsable[]>({
            method: 'get',
            path: `find_by_ticket_id/${ticket_id}`
        });
    }
}