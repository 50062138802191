

























import InputMessages from "./InputMessages.vue";
import Messages from "./Messages.vue";
import Vue from "vue";

export default Vue.extend({
props:{
    itemProp:{
        type:Object
    }
},
  components: {
    InputMessages,
    Messages
  },
  data() {
    return {
      dialog: false,
    };
  },
});
