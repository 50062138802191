






































































import { GroupService } from "@/services/models/group/group";
import { UserGroupService } from "@/services/models/group/userGroup";
import { TicketService } from "@/services/models/ticket/ticket";
import { TicketHistoryService } from "@/services/models/ticket/ticketHistory";
import { TicketResponsableService } from "@/services/models/ticket/ticketResponsable";
import myFormInterface from "@/types/myFormInterface";
import Ticket from "@/types/tickets/tickets";
import TicketHistory from "@/types/tickets/tickets_histories";
import TicketResponsable from "@/types/tickets/tickets_responsables";
import Group from "@/types/user/groups";
import UserGroup from "@/types/user/userGroup";
import Vue, { PropType } from "vue";
import { mapActions } from "vuex";
export default (Vue as myFormInterface).extend({
  props: {
    itemProp: {
      type: Object as PropType<Ticket>,
      required: true
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      userSelected: 0,
      listUser: [] as Array<UserGroup>,
      listGroup: [] as Array<Group>,
      observation: "" as string,
      transferOptions: ["Usuario", "Grupo"],
      transferBy: "Usuario",
      groupSelected: 0
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      }
    },
  },
  methods: {
    ...mapActions({
      setTickets: "ticket/getTicketFilter",
    }),
    async loadData() {
      this.isLoading = true;
      this.listUser = await UserGroupService.getByGroupId(
        this.getUserGroup().group_id
      );
      this.listGroup = await GroupService.getByStablishmentId(this.getCurrentUserLoggedIn().stablishment_id);
      if (this.listGroup.length !== 0) {
        this.groupSelected = this.listGroup[0].id;
      }
      this.isLoading = false;
    },
    async save() {
      try {
        this.displayLoader(true);
        if (this.transferBy == "Usuario") {
          let dataPost: TicketResponsable = {
            user_id: this.userSelected,
            ticket_id: this.itemProp.id,
            observation: this.observation,
          };
          await TicketResponsableService.create(dataPost);
        } else if (this.transferBy == "Grupo") {
          await TicketService.update(this.itemProp.id, {
            ...this.itemProp,
            group_id: this.groupSelected,
            state: "solicitado",
          });
        }
        let dataPostHistory: TicketHistory = {
          user_id: this.getCurrentUserLoggedIn().id,
          ticket_id: this.itemProp.id,
          state: "Solicitud transferida",
        };
        await TicketHistoryService.create(dataPostHistory);
        this.setTickets();
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
