




























import { DocumentDetailFileService } from "@/services/models/document/documentDetailFile";
import DocumentDetail from "@/types/documents/documentDetail";
import DocumentDetailFile from "@/types/documents/documentDetailFile";
import Vue, { PropType } from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    documentProp: {
      type: Object as PropType<DocumentDetail>,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      documentDetailFile: {} as DocumentDetailFile,
      files: [] as Blob[],
    };
  },
  methods: {
    ...mapActions({
      getDetailFile: "documentDetailFile/getDetailFile",
      getDocumentsDetail: "documentDetail/getByDocumentId",
    }),
    async save() {
      try {
        this.isLoading = true;
        if (this.files.length === 0) {
          this.displayToast({
            type: "warning",
            message: "Favor de seleccionar algun archivo",
          });
          return;
        }
        //BUG (Machine) cuando falla al subir documentos (Api), queda pegado en 'Uno de los documentos no es formato PDF', al cerrar el modal y volverlo abrir se arregla
        if (this.comprobateTypeFiles()) {
          for (let i = 0; i < this.files.length; i++) {
            let formData = new FormData();
            formData.append("file", this.files[i]);
            formData.append("state", "creado");
            formData.append(
              "document_detail_id",
              this.documentProp.id.toString()
            );
            formData.append(
              "user_id",
              this.getCurrentUserLoggedIn().id.toString()
            );

            await DocumentDetailFileService.create(formData, {
              "Content-Type": "multipart/form-data",
            });
          }
          this.getDetailFile(this.documentProp.id);
          this.files = [];
        } else {
          this.displayToast({
            type: 'error',
            message: 'Uno de los documentos no es formato PDF'
          });
        }
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    comprobateTypeFiles(): boolean {
      let isValid = true;
      for (let i = 0; i < this.files.length; i++) {
        console.log(this.files[i].type);
        if (
          this.files[i].type !== "application/pdf" &&
          this.files[i].type !== "application/PDF"
        ) {
          isValid = false;
        }
      }
      return isValid;
    },
  },
});
