




















import ChipUserWithMenu from "@/components/general/ChipUserWithMenu.vue";
import { DocumentDetailReadService } from "@/services/models/document/documentDetailRead";
import DocumentDetailRead from "@/types/documents/documentDetailRead";
import Vue from "vue";
export default Vue.extend({
  components: { ChipUserWithMenu },
  props: {
    detailProp: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [{ text: "Usuario" }, { text: "Ultima Actualización" }],
      list: [] as DocumentDetailRead[],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.list = await DocumentDetailReadService.getDocumentDetailId(
        this.detailProp.id
      );
      this.isLoading = false;
    },
  },
});
