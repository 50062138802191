import { DataFindByPurchaseBuyerState } from '@/store/purchaseRequest/modulePurchaseBuyer';
import PurchaseBuyer from '@/types/purchaseRequest/purchaseBuyer'
import { BaseService } from './../../base'
export class PurchaseBuyerService extends BaseService<PurchaseBuyer>() {
    static get entity (): string {
        return 'purchase_buyer'
    }
    static get responseName (): string {
        return 'purchase_buyer'
    }
    static async getByPurchaseId(purchaseId:number): Promise<PurchaseBuyer[]> {
        return this.submit<PurchaseBuyer[]>({
            method: 'get',
            path: `find_by_purchase_request_id/${purchaseId}`
        });
    }
    static async getByPurchaseBuyerState(payLoad:DataFindByPurchaseBuyerState,page:number): Promise<PurchaseBuyer> {
        return this.submit<PurchaseBuyer>({
            method: 'get',
            path: `find_by_purchase_buyer_states/${payLoad.userId}/${payLoad.state}/${payLoad.step}?page=${page}`
        });
    }
}