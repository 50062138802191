import { TicketMessageService } from "@/services/models/ticket/ticketMessage";
import TicketMessage from "@/types/tickets/ticket_message";
import { Commit } from "vuex";

interface stateInterface {
    message: TicketMessage[],
    loading: boolean,
}
const moduleTicketMessage = {
    namespaced: true,
    state: {
        message: [] as Array<TicketMessage>,
        loading: false,
    } as stateInterface,
    getters: {
        getTicketMessage(state: stateInterface): TicketMessage[] {
            return state.message;
        },

        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        setTicketMessage(state: stateInterface, message: TicketMessage[]): void {
            state.message = message;
        },

        setLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        }
    },
    actions: {
        async getTicketMessage({commit}: {commit: Commit},id:number): Promise<void> {
            commit('setLoading', true);
            const TicketMessage = await TicketMessageService.getByTicketId(id);                        
            commit("setTicketMessage", TicketMessage);
            commit('setLoading', false);
        },
    }
}

export default moduleTicketMessage;