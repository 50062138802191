































































import Vue from "vue";
import { DocumentTypeService } from "@/services/models/document/document_type";
import DocumentType from "@/types/documents/documentType";
import { DocumentService } from "@/services/models/document/document";
import { mapActions } from "vuex";
import myFormInterface from "@/types/myFormInterface";
import Document from "@/types/documents/document";
import Remitent from "@/types/documents/remitent";
import { RemitentService } from "@/services/models/document/remitent";
export default (Vue as myFormInterface).extend({
  props: {
    documentProp: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      dialog: false as boolean,
      items: [] as DocumentType[],
      listRemitents:[] as Remitent[],
      isLoading: false as boolean,
      document: {} as Document,
    };
  },
  methods: {
    ...mapActions({
      setDocument: "documents/getDocuments",
    }),
    clearData() {
      this.cleanForm(this, "formCreate");
    },
    async loadData() {
      this.isLoading = true;
      this.items = await DocumentTypeService.getAll();
       this.listRemitents= await RemitentService.getAll();
      this.document = await DocumentService.getById(this.documentProp.id);
      this.isLoading = false;
    },
    async saveDocument() {
      if (!this.$refs.formCreate.validate()) {
        this.displayToast({
          type: "error",
          message: "Favor de llenar todos los campos requeridos",
        });
        return;
      }
      try {
        this.isLoading = true;
        let data = {
          ...this.document,
          user_id: this.getCurrentUserLoggedIn().id,
          location_id: this.getCurrentUserLoggedIn().users_locations?.[0].location_id || 0,
        };
        let response = await DocumentService.update(this.documentProp.id, data);
        console.log(response);
        this.setDocument();
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      }
    },
  },
});
