





















   import Vue from "vue";
import TableListCdp from "./TableListCdp.vue";
   export default Vue.extend({
  components: { TableListCdp },
       props:{
           purchaseBuyerProps:{
               type:Object
           }
       },
     data() {
       return {
         dialog: false,
       };
     }
   });
