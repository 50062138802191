





























































import FormPurchase from "./FormPurchase.vue";
import FormArticles from "./FormArticles.vue";
import FormFiles from "@/components/general/FormFiles.vue";

import Vue from "vue";
import { mapActions } from "vuex";

import myFormInterface from "@/types/myFormInterface";
import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";
import PurchaseRequestState from "@/types/purchaseRequest/purchaseState";
import PurchaseHistory from "@/types/purchaseRequest/purchaseHistory";
import PurchaseRequestDetail from "@/types/purchaseRequest/purchaseRequestDetail";

import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
import { PurchaseRequestStateService } from "@/services/models/purchaseRequest/purchaseRequestState";
import { PurchaseRequestDetailService } from "@/services/models/purchaseRequest/purchaseRequestDetail";
import { PurchaseRequestFileService } from "@/services/models/purchaseRequest/purchaseRequestFile";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";

export default (Vue as myFormInterface).extend({
  components: {
    FormPurchase,
    FormArticles,
    FormFiles,
  },
  data() {
    return {
      step: 1,
      invalidStep: 0 as number,
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      recharge: "purchaseRequest/getPurchaseRequest",
    }),
    validateStep(isValid: boolean, step: number): void {
      if (!isValid) {
        this.invalidStep = step;
      }
    },
    async save() {
      this.displayLoader(true);
      const formPurchase = this.$refs.formPurchase;
      const formArticles = this.$refs.formArticles;

      // Se valida que los formularios este con todos los campos correspondientes
      if (!formPurchase.validateForm())
        return this.validateStep(false, 1), this.displayLoader(false);
      if (!formArticles.validateForm())
        return this.validateStep(false, 2), this.displayLoader(false);

      try {
        //Insert en la tabla purchase_request
        let dataPurchaseRequest: PurchaseRequest = {
          ...(formPurchase.data as PurchaseRequest),
        };
        let responsePurchaseRequest = await PurchaseRequestService.create(
          dataPurchaseRequest
        );

        /** Estas tienen consultas en For, por ende deben estar con promesas */
        //Insert en table purchase_request_detail
        await this.postPurchaseRequestDetail(responsePurchaseRequest);

        //Insert en table purchase_request_file
        await this.postPurchaseRequestFile(responsePurchaseRequest);
        /** //////////////////////////////////////////////////////////////// */

        //Insert en tabla purchase_request_state
        await this.postPurchaseRequestState(responsePurchaseRequest);

        //Insert en tabla purchase_request_history
        await this.postPurchaseHistory(responsePurchaseRequest);

        this.recharge(this.getCurrentUserLoggedIn().id);
        this.dialog=false;
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Error al guardar, favor de reintentar",
        });
      } finally {
        this.displayLoader(false);
      }
    },

    async postPurchaseRequestDetail(purchaseRequest: PurchaseRequest) {
      const formArticles = this.$refs.formArticles;
      if (formArticles && formArticles.dataArray.length != 0) {
        let promise = [];
        for (let i = 0; i < formArticles.dataArray.length; i++) {
          promise.push(
            new Promise((resolve, reject) => {
              let detail: PurchaseRequestDetail = formArticles.dataArray[i];
              let dataPurchaseRequestDetail: PurchaseRequestDetail = {
                id: 0,
                purchase_request_id: purchaseRequest.id,
                article_id: detail.article_id,
                cant: detail.cant,
                price: detail.price,
                observation: detail.observation,
              };
              PurchaseRequestDetailService.create(dataPurchaseRequestDetail)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            })
          );
        }
        Promise.all(promise)
          .then(() => {
            return;
          })
          .catch((reason) => {
            console.log(reason);
          });
      }
    },

    async postPurchaseRequestFile(purchaseRequest: PurchaseRequest) {
      const formFiles = this.$refs.formFiles;
      if (formFiles && formFiles.files.length != 0) {
        let promise = [];
        for (let i = 0; i < formFiles.files.length; i++) {
          promise.push(
            new Promise((resolve, reject) => {
              let formData = new FormData();
              formData.append("purchase_request_id", `${purchaseRequest.id}`);
              formData.append("file", formFiles.files[i]);
              //BUG Hay que sacar el campo observacion de esta tabla
              formData.append("observation", "Sin observación");
              PurchaseRequestFileService.create(formData, {
                "Content-Type": "multipart/form-data",
              })
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            })
          );
        }
        Promise.all(promise)
          .then(() => {
            return;
          })
          .catch((reason) => {
            console.log(reason);
          });
      }
    },

    async postPurchaseRequestState(purchaseRequest: PurchaseRequest) {
      let dataPurchaseState: PurchaseRequestState = {
        id: 0,
        purchase_request_id: purchaseRequest.id,
        state: "creado",
        step: "solicitante",
        user_id: this.getCurrentUserLoggedIn().id,
        user_location_id: this.getUserLocation().location_id,
      };
      await PurchaseRequestStateService.create(dataPurchaseState);
    },

    async postPurchaseHistory(purchaseRequest: PurchaseRequest) {
      let dataPurchaseHistory: PurchaseHistory = {
        id: 0,
        purchase_request_id: purchaseRequest.id,
        state: "solicitud creada",
        user_origin_id: this.getCurrentUserLoggedIn().id,
        user_destination_id: this.getCurrentUserLoggedIn().id,
      };
      await PurchaseHistoryService.create(dataPurchaseHistory);
    },
  },
  watch: {
    invalidStep() {
      if (this.invalidStep != 0) {
        this.step = this.invalidStep;
      }
    },
  },
});
