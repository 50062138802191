import Group from '@/types/user/groups'
import { BaseService } from '../../base'
export class GroupService extends BaseService<Group>() {
    static get entity (): string {
        return 'group'
    }
    static get responseName (): string {
        return 'group'
    }
    static async getByStablishmentId(stablishment_id:number): Promise<Group[]> {
        return this.submit<Group[]>({
            method: 'get',
            path: `find_by_stablishment_id/${stablishment_id}`
        });
    }
}