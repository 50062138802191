import DocumentStateLocation from '@/types/documents/documentStateLocation'
import { BaseService } from './../../base'
export class DocumentStateLocationService extends BaseService<DocumentStateLocation>() {
    static get entity (): string {
        return 'document_state_location'
    }
    static get responseName (): string {
        return 'document_state_location'
    }
    static async getByDocumentIdLocationId(payload:{document_id:number,location_id:number}): Promise<DocumentStateLocation[]> {
        return this.submit<DocumentStateLocation[]>({
            method: 'get',
            path: `find_by_document_id_location_id/${payload.document_id}/${payload.location_id}`,            
        });
    }
}