












import { PurchaseRequestFileService } from "@/services/models/purchaseRequest/purchaseRequestFile";
import PurchaseRequestFile from "@/types/purchaseRequest/purchaseRequestFile";
import Vue from "vue";
export default Vue.extend({
  props: {
    purchaseProp: {
      type: Object,
    },
  },
  data() {
    return {
      getLoading: false as boolean,
      getItems: [] as Array<PurchaseRequestFile>,
      headers: [
        {
          text: "Nombre",
          sortable: false,
          value: "file_name",
        },
        {
          text: "Archivo",
          sortable: false,
          value: "file",
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.getLoading = true;
      this.getItems = await PurchaseRequestFileService.getByPurchaseId(this.purchaseProp.id);
      this.getLoading = false;
    },
  },
});
