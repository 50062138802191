export const templateOficio =
{
    title: "Oficio",
    description:
        "Plantilla predefinida de un Oficio",
    content: `
    <p><img src="../Logo-SSM.jpg" width="100" height="100" /></p>
    <p class="western" style="line-height: 1" align="justify">
      <span style="font-family: Arial, sans-serif"
        ><span style="font-size: xx-small"
          ><span lang="es-ES-u-co-trad"
            >Servicio de Salud Magallanes</span
          ></span
        ></span
      >
    </p>
    \n
    <p class="western" style="line-height: 1" align="justify">
      <span style="font-family: Times New Roman, serif"
        ><span style="font-size: small"
          ><span style="font-family: Arial, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-ES-u-co-trad"
                >Subdirecci&oacute;n Administrativa</span
              ></span
            ></span
          ></span
        ></span
      >
    </p>
    \n
    <p lang="es-ES" style="line-height: 1" align="justify">
      <span style="font-family: Times New Roman, serif"
        ><span style="font-size: small"
          ><span style="font-family: Arial, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-ES-u-co-trad"
                >Departamento Tecnolog&iacute;as de Informaci&oacute;n y
                Comunicaciones
              </span></span
            ></span
          ></span
        ></span
      >
    </p>
    \n
    <p lang="es-ES" style="line-height: 1" align="justify">
      <span style="font-family: Times New Roman, serif"
        ><span style="font-size: small"
          ><span style="font-family: Verdana, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-CL">AVF/</span></span
            ></span
          ><span style="font-family: Verdana, sans-serif"
            ><span style="font-size: xx-small"
              ><span lang="es-CL">RTB/kam</span></span
            ></span
          ></span
        ></span
      >
    </p>
    \n
    <table
    style="border-collapse: collapse; width: 100%; border-style: hidden"
    border="1"
  >
    \n
    <tbody>
      \n
      <tr style="border-style: none">
        \n
        <td style="width: 48.2477%; border-style: hidden">&nbsp;</td>
        \n
        <td style="width: 48.2477%; border-style: hidden">
          \n
          <p lang="es-ES" style="line-height: 1" align="justify">
            <span style="font-size: small"
              ><span style="font-family: Arial, sans-serif"
                ><span lang="en-US"
                  ><strong>ORD.: N&ordm;</strong></span
                ></span
              ><span style="font-family: Arial, sans-serif"
                ><span lang="en-US"> 2576</span></span
              ><span style="font-family: Arial, sans-serif"
                ><span lang="en-US"><u> </u></span></span
              ><span style="font-family: Arial, sans-serif"
                ><span lang="en-US">/</span></span
              ></span
            >
          </p>
          \n
          <p lang="es-ES" style="line-height: 1" align="justify">
            <span style="font-size: small"
              ><span style="font-family: Arial, sans-serif"
                ><span lang="en-US"><strong>ANT.:</strong></span></span
              ><span style="font-family: Arial, sans-serif"
                ><span lang="en-US"> No Hay</span></span
              ></span
            >
          </p>
          \n
          <p lang="es-ES" style="line-height: 1" align="justify">
            <span style="font-size: small"
              ><span style="font-family: Arial, sans-serif"
                ><span lang="en-US"><strong> </strong></span></span
              ><span style="font-family: Arial, sans-serif"> </span
            ></span>
          </p>
          \n
          <p lang="es-ES" style="line-height: 1" align="justify">
            <span style="font-size: small"
              ><span style="font-family: Arial, sans-serif"
                ><strong>MAT.: </strong></span
              ><span style="color: #000000"
                ><span style="font-family: Arial, sans-serif"
                  ><span lang="es-ES"
                    ><strong
                      >Gesti&oacute;n financiera presupuestaria cierre
                      2021</strong
                    ></span
                  ></span
                ></span
              ></span
            >
          </p>
          \n
          <p lang="es-ES" style="line-height: 1" align="justify">
            <span style="font-size: small"
              ><span style="font-family: Arial, sans-serif"
                ><span lang="es-ES-u-co-trad"
                  ><strong>PUNTA ARENAS, </strong></span
                ></span
              ><span style="color: #000000"
                ><span style="font-family: Arial, sans-serif"
                  ><span lang="es-ES-u-co-trad"
                    ><strong>28</strong></span
                  ></span
                ></span
              ><span style="font-family: Arial, sans-serif"
                ><span lang="es-ES-u-co-trad"
                  ><strong> de </strong></span
                ></span
              ><span style="color: #000000"
                ><span style="font-family: Arial, sans-serif"
                  ><span lang="es-ES-u-co-trad"
                    ><strong>septiembre</strong></span
                  ></span
                ></span
              ><span style="font-family: Arial, sans-serif"
                ><span lang="es-ES-u-co-trad"
                  ><strong> 2021</strong></span
                ></span
              ></span
            >
          </p>
          \n
        </td>
        \n
      </tr>
      \n
      <tr style="border-style: none">
        \n
        <td style="width: 48.2477%; border-style: hidden">
          \n
          <h3
            class="western"
            lang="es-ES"
            style="line-height: 1"
            align="justify"
          >
            <span style="font-family: Verdana, sans-serif"
              ><span style="font-size: small">DE : </span></span
            ><span style="color: #000000"
              ><span style="font-family: Verdana, sans-serif"
                ><span style="font-size: small"
                  ><span lang="es-ES"
                    ><strong>RICARDO CONTRERAS FA&Uacute;NDEZ</strong></span
                  ></span
                ></span
              ></span
            >
          </h3>
          \n
          <p class="western" style="line-height: 1" align="justify">
            <span style="font-family: Calibri, sans-serif"
              ><span style="font-size: small">
                <span style="font-family: Verdana, sans-serif"
                  ><strong
                    >DIRECTOR(S) SERVICIO SALUD MAGALLANES</strong
                  ></span
                ></span
              ></span
            >
          </p>
          \n
          <h3
            class="western"
            lang="es-ES"
            style="line-height: 1"
            align="justify"
          >
            <span style="font-family: Verdana, sans-serif"
              ><span style="font-size: small"
                >A : DIRECTORES HOSPITALES</span
              ></span
            >
          </h3>
          \n
        </td>
        \n
        <td style="width: 48.2477%; border-style: hidden">&nbsp;</td>
        \n
      </tr>
      \n
    </tbody>
    \n
  </table>
  \n
  <p lang="es-ES-u-co-trad" style="line-height: 1" align="justify">&nbsp;</p>
  \n
  <p class="western" style="line-height: 1" align="justify">
    <span style="font-family: Calibri, sans-serif">
        <span style="font-size: small">
            <span style="font-family: verdana, sans-serif">
                <span style="font-size: small">Junto con saludar, </span>
            </span>          
        </span
        ></span>
  </p>
  \n
  <p class="western" lang="es-ES" style="line-height: 1">&nbsp;</p>
  \n
  <p class="western" lang="es-ES" style="line-height: 1; text-align: center">
    <span style="color: #000000"> </span
    ><span style="color: #000000"
      ><span style="font-family: Verdana, sans-serif"
        ><span style="font-size: small"
          ><span lang="es-ES">RICARDO CONTRERAS FA&Uacute;NDEZ</span></span
        ></span
      ></span
    >
  </p>
  \n
  <p class="western" lang="es-ES" style="line-height: 1; text-align: center">
    <span style="font-family: Verdana, sans-serif"
      ><span style="font-size: small"
        ><strong>DIRECTOR(S) </strong></span
      ></span
    >
  </p>
  \n
  <p class="western" lang="es-ES" style="line-height: 1; text-align: center">
    <span style="font-family: Verdana, sans-serif"
      ><span style="font-size: small"
        ><strong>SERVICIO DE SALUD MAGALLANES</strong></span
      ></span
    >
  </p>
  \n
  <p class="western" lang="es-ES" style="line-height: 1; text-align: center">
    &nbsp;
  </p>
  \n
  <p class="western" style="line-height: 1" align="left">
    <span style="font-family: Verdana, sans-serif"
      ><span style="font-size: small"
        ><u><strong>Distribuci&oacute;n:</strong></u></span
      ></span
    >
  </p>
  \n
  <ul>
    \n
    <li style="line-height: 1">
      \n
      <p class="western" align="left">
        <span style="font-family: Verdana, sans-serif"
          ><span style="font-size: xx-small"
            >Subdirector de Gesti&oacute;n Asistencial - DSSM</span
          ></span
        >
      </p>
      \n
    </li>
    \n
  </ul>
    `,

}