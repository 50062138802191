import UserGroup from '@/types/user/userGroup'
import { BaseService } from './../../base'
export class UserGroupService extends BaseService<UserGroup>() {
    static get entity (): string {
        return 'user_group'
    }
    static get responseName (): string {
        return 'user_group'
    }
    static async getByGroupId(group_id:number): Promise<UserGroup[]> {
        return this.submit<UserGroup[]>({
            method: 'get',
            path: `find_by_group_id/${group_id}`
        });
    }
}