


















































import Vue from "vue";

import ChangePassword from "./ChangePassword.vue";

import { mapGetters } from "vuex";
import DataCurrentUser from "./DataCurrentUser.vue";
import Avatar from "./Avatar.vue";

export default Vue.extend({
  components: {
    ChangePassword,
    DataCurrentUser,
    Avatar,
  },
  data() {
    return {
      items: [],
      // items: [["fa-archive", "Mis Documentos"]],
    };
  },
  computed: {
    ...mapGetters({
      user: "users/getUser",
      loading: "users/getLoading",
    }),
  },
});
