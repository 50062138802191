import LocationInterface from "@/types/user/location";
import UserModule from "@/types/user/userModule";
import UserModulePermission from "@/types/user/userModulePermission";

interface stateInterface {
	profile: string;
	currentLocation: LocationInterface,
	modules: UserModule[],
	permissions: UserModulePermission[],
}
const moduleUserSession = {
	namespaced: true,
	state: {
		profile: window.localStorage.getItem("perfil"),
		currentLocation: {
			id: 0,
			location_type_id: 0,
			name: "Sin locación asignada",
			stablishment_id: 0,
		},
		modules: [],
		permissions: [],
	} as stateInterface,
	getters: {
		getProfile(state: stateInterface): string {
			return state.profile;
		},
		getCurrentLocation(state: stateInterface): LocationInterface {
			return state.currentLocation;
		},
		getModules(state: stateInterface) {
			return state.modules.map(module => module.module_id);
		},
		getPermissions(state: stateInterface) {
			return state.permissions.map(permission => permission.permission_id);
		}
	},
	mutations: {
		setProfile(state: stateInterface, profile: string): void {
			state.profile = profile;
		},
		setCurrentLocation(state: stateInterface, location: LocationInterface): void {
			state.currentLocation = location;
		},
		setUserModules(state: stateInterface): void {
			let modules: UserModule[] = [];
			const user = JSON.parse(window.localStorage.getItem("user") || "{}");
			const userModules = user.users_modules;
			console.log(user);
			
			if (userModules) {
				modules = userModules;
			}
			state.modules = modules;
		},
		setUserPermissions(state: stateInterface): void {
			let permissions: UserModulePermission[] = [];
			const modules = state.modules;
			modules.forEach(module => {
				let userModulePermissions: UserModulePermission[] = [];
				if (module.users_modules_permissions) {
					userModulePermissions = module.users_modules_permissions;
				}
				permissions = [...permissions, ...userModulePermissions];
			});
			state.permissions = permissions;
		}
	}
}

export default moduleUserSession;