







import CardPerfil from "@/components/perfilUserCard/CardPerfil.vue";
import TemplateAdministrator from "@/templates/TemplateAdministrator.vue";
import Vue from "vue";
export default Vue.extend({
  components: { TemplateAdministrator, CardPerfil },
});
