

















































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonDelete from "./ButtonDelete.vue";
import ButtonShowFiles from "./ButtonShowFiles.vue";

export default Vue.extend({
  components: { ButtonShowFiles, ButtonDelete },
  data() {
    return {
      page: 1,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false,
        },
        { text: "Número", sortable: false, align: "start", value: "number" },
        {
          text: "Descripción",
          sortable: false,
          align: "start",
          value: "description",
        },
        {
          text: "Tipo de Documentación",
          sortable: false,
          align: "start",
          value: "document_type.name",
        },
        {
          text: "Destino",
          sortable: false,
          align: "start",
          value: "dispatch_location.name",
        },
        {
          text: "Fecha",
          sortable: false,
          align: "start",
          value: "created_at",
        },
        { text: "Acciones", sortable: false, align: "start", value: "action" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getDocuments: "dispatch/getDispatch",
      getLoading: "dispatch/getLoading",
    }),
  },
  mounted() {
    this.setDocument(this.getCurrentUserLoggedIn().stablishment_id);
  },
  methods: {
    changePage() {
      this.$store.commit("dispatch/setPageDispatch", this.page);
      this.setDocument(this.getCurrentUserLoggedIn().stablishment_id);
    },
    ...mapActions({
      setDocument: "dispatch/getDispatch",
    }),
  },
});
