


























import { BuyerService } from "@/services/models/purchaseRequest/buyer";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    buyerProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setBuyerInProcess: "buyer/getBuyerInProcess",
      setBuyerReady: "buyer/getBuyerReady",
    }),
    async save() {
      this.displayLoader(true);
      await BuyerService.update(this.buyerProp.id, {
        ...this.buyerProp,
        state: "finalizado",
      });
      this.setBuyerInProcess({
        userId: this.getCurrentUserLoggedIn().id,
        state: "en proceso",
      });
      this.setBuyerReady({
        userId: this.getCurrentUserLoggedIn().id,
        state: "finalizado",
      });
      this.displayLoader(false);
    },
  },
});
