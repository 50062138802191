


























































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonCreatePurchaseRequest from "../createPurchaseRequest/ButtonCreatePurchaseRequest.vue";
import GroupButtons from "./buttonsPurchaseRequest/GroupButtons.vue";
import DetailTablePurchaseRequest from "./DetailTablePurchaseRequest.vue";
export default Vue.extend({
  components: {
    ButtonCreatePurchaseRequest,
    DetailTablePurchaseRequest,
    GroupButtons,
  },
  data() {
    return {
      page: 1,
      headers: [
        {
          text: "Id",
          sortable: false,
          value: "id",
        },
        {
          text: "Estado",
          sortable: false,
          value: "state",
        },
        {
          text: "Creación",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Modificación",
          sortable: false,
          value: "updated_at",
        },
        {
          text: "Acciones",
          sortable: false,
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getPurchaseRequest: "purchaseRequest/getPurchaseRequest",
      getLoading: "purchaseRequest/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setPurchaseRequest: "purchaseRequest/getPurchaseRequest",
    }),
    color(state: string) {
      if (state == "creado") return "success";
      else return "warning";
    },
    changePage() {
      this.$store.commit("purchaseRequest/setPagePurchaseRequest", this.page);
      this.setPurchaseRequest(this.getCurrentUserLoggedIn().id);
    },
  },
  mounted() {
    this.setPurchaseRequest(this.getCurrentUserLoggedIn().id);
  },
});
