





























import { DispatchService } from "@/services/models/dispatch/dispatch";
import { DispatchFileService } from "@/services/models/dispatch/dispatch_file";
import Dispatch from "@/types/dispatch/dispatch";
import myFormInterface from "@/types/myFormInterface";
import Vue from "vue";
import { mapActions } from "vuex";
import TabCreateDispatch from "./TabCreateDispatch.vue";
export default (Vue as myFormInterface).extend({
  components: { TabCreateDispatch },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setDocument: "dispatch/getDispatch",
    }),
    async save() {
      if (!this.$refs.tabCreate.$refs.formDispatch.returnRef()) {
        this.displayToast({
          type: "error",
          message: "Favor de llenar todos los campos requeridos",
        });
        return;
      }
      try {
        this.displayLoader(true);
        let dataDispatch = this.$refs.tabCreate.$refs.formDispatch.dispatch;
        let dataFiles: Blob[] =
          this.$refs.tabCreate.$refs.formFiles?.files || [];

        let response = await DispatchService.create(dataDispatch as Dispatch);

        for (let i = 0; i < dataFiles.length; i++) {
          let formData = new FormData();
          formData.append("dispatch_id", `${response.id}`);
          formData.append("file", dataFiles[i]);
          await DispatchFileService.create(formData, {
            "Content-Type": "multipart/form-data",
          });
        }
        this.setDocument(this.getCurrentUserLoggedIn().stablishment_id);
        this.displayToast({
          type: "success",
          message: "Se guardo el despacho sin problemas",
        });        
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Error al guardar",
        });
      } finally {
        this.displayLoader(false);
      }
    },
  },
});
