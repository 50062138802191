





















































import { DataFindByPurchaseBuyerState } from "@/store/purchaseRequest/modulePurchaseBuyer";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import DetailTablePurchaseRequest from "../DetailTablePurchaseRequest.vue";
import GroupButtons from './../buttonsPurchaseRequest/GroupButtons.vue'
export default Vue.extend({
  components: {
    DetailTablePurchaseRequest,
    GroupButtons
  },
  data() {
    return {
      page: 1,
      dataStateStep: {
        userId: this.getCurrentUserLoggedIn().id,
        state: "enviado",
        step: "unityManager",
      } as DataFindByPurchaseBuyerState,    
      headers:[
        {
          text:'Id Solicitud',
          sorteable:false,
          value:'purchase_request_id'
        },
        {
          text:'Id Registro de Compra',
          sorteable:false,
          value:'id'
        },
        {
          text:'N° Licitación',
          sorteable:false,
          value:'n_licitation'
        },
        {
          text:'N° Orden',
          sorteable:false,
          value:'n_order'
        },
        {
          text:'Observación',
          sorteable:false,
          value:'observation'
        },
        {
          text:'Comprador',
          sorteable:false,
          value:'user'
        },
        {
          text:'Acciones',
          sorteable:false,
          value:'actions'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      getReady: "purchaseBuyer/getPurchaseBuyerReady",
      getLoading: "purchaseBuyer/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setReady: "purchaseBuyer/getPurchaseBuyerReady",
    }),
    changePage() {
      this.$store.commit("purchaseBuyer/setPagePurchaseBuyerReady", this.page);
      this.setReady({ ...this.dataStateStep });
    },
  },
  mounted() {
    this.changePage();
  },
});
