



















import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
  props: {
    detailProp: {
      type: Object,
    },
  },
  data() {
    return {
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "Nombre", align: "start", value: "file_name" },
        { text: "Fecha", align: "start", value: "created_at" },
        { text: "Ver", align: "start", value: "actions" },
      ],
    };
  },
  mounted() {
    this.getDetailFile(this.detailProp.id);
  },
  computed: {
    ...mapGetters({
      detailFiles: "documentDetailFile/getDetailFile",
      loading: "documentDetailFile/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      getDetailFile: "documentDetailFile/getDetailFile",
    }),
  },
});
