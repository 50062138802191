



























import Vue from "vue";
import MenuAddDocument from "./MenuAddDocument.vue";
export default Vue.extend({
  components: { MenuAddDocument },
  props: {
    documentProp: {
      required: true,
      type: Object,
    },
  },
});
