


























import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";
import PurchaseRequestType from "@/types/purchaseRequest/purchaseRequestType";
import User from "@/types/user/user";
import UserLocation from "@/types/user/userLocation";
import Vue from "vue";
export default Vue.extend({
  props: {
    purchaseProp: {
      type: Object,
    },
  },
  data() {
    return {
      purchase_request: {
        user: {} as User,
        purchase_request_type: {} as PurchaseRequestType,
        description: '' as string,
        estimated_use_date: '' as string,
        state: '' as string,
        user_location: {} as UserLocation
      } as unknown as PurchaseRequest,
    };
  },
  methods: {
    async loadData() {
      this.displayLoader(true);
      this.purchase_request = await PurchaseRequestService.getById(
        this.purchaseProp.id
      );
      this.displayLoader(false);
    },
  },
  mounted() {
    this.loadData();
  },
});
