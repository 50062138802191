import { StablishmentService } from "@/services/models/stablishment";
import Stablishment from "@/types/user/stablishment";
import { Commit } from "vuex";

interface stateInterface {
    stablishments: Stablishment[],
    loading: boolean,
    stablishmentSelected: Stablishment | null
}
const moduleUser = {
    namespaced: true,
    state: {
        stablishments: [],
        loading: false,
        stablishmentSelected: null
    } as stateInterface,
    getters: {
        getStablishments(state: stateInterface): Stablishment[] {
            return state.stablishments;
        },
        getLoading(state: stateInterface): boolean {
            return state.loading;
        },
        getStablishmentSelected(state: stateInterface): Stablishment | null {
            return state.stablishmentSelected;
        }
    },
    mutations: {
        loadRoles(state: stateInterface, stablishments: Stablishment[]): void {
            state.stablishments = stablishments;
        },
        changeLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        },
        changeSatblishmentSelected(state: stateInterface, stablishmentSelected: Stablishment): void {
            state.stablishmentSelected = stablishmentSelected;
        }
    },
    actions: {
        async getStablishments({commit}: {commit: Commit}): Promise<void> {
            commit('changeLoading', true);
            const stablishment = await StablishmentService.getAll();
            commit("loadRoles", stablishment);
            commit('changeLoading', false);
        }
    }
}

export default moduleUser;