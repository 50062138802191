




























































import Vue from "vue";
import myFormInterface from "@/types/myFormInterface";
import { statesTickets } from "@/types/tickets/tickets";
import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import { mapActions } from "vuex";
import { dataFilterTicket } from "@/store/ticket/moduleTicket";
import store from "@/store";

export default (Vue as myFormInterface).extend({
  components: { InputSelectUsers },
  data() {
    return {
      dialog: false,
      states: [
        {
          value: "solicitado",
          text: "solicitado"
        },
        {
          value: "en proceso",
          text: "en proceso"
        },
        {
          value: "cerrado",
          text: "validado"
        },
        {
          value: "cerrado permanentemente",
          text: "validado permanentemente"
        },
        // "solicitado",
        // "cerrado",
        // "en proceso",
        // "cerrado permanentemente",
      ],
      filter:{} as dataFilterTicket
    };
  },
  methods: {
    ...mapActions({
      setTickets: "ticket/getTicketFilter",
    }),
    async saveFilter(){
      store.commit('ticket/setDataFilterTicket',this.filter)
      await this.setTickets()
    }
  },
  watch: {
   dialog() {
     if (this.dialog) {
       this.filter.state=null
    }
   }
  },
});
