import { UserService } from "@/services/models/user/user";
import User from "@/types/user/user";
import { Commit } from "vuex";

interface stateInterface {
    users: User[],
    usersFilterByState:User[],
    user: User,
    loading: boolean,
    stablishmentIdSelected: number
}
const moduleUser = {
    namespaced: true,
    state: {
        users: [],
        usersFilterByState:[],
        user: {} as User,
        loading: false,
        stablishmentIdSelected: 0
    } as stateInterface,
    getters: {
        getUser(state: stateInterface): User {
            return state.user;
        },
        getUsers(state: stateInterface): User[] {
            return state.users;
        },
        getUsersFilterByState(state: stateInterface): User[] {
            return state.usersFilterByState;
        },
        getLoading(state: stateInterface): boolean {
            return state.loading;
        }
    },
    mutations: {
        loadUsers(state: stateInterface, users: User[]): void {
            state.users = users;
        },
        changeLoading(state: stateInterface, loading: boolean): void {
            state.loading = loading;
        },
        setUser(state: stateInterface, user: User): void {
            state.user = user
        },
        setStablishmentIdSelected(state: stateInterface, stablishmentId: number): void {
            state.stablishmentIdSelected = stablishmentId;
        },
        setUsersFilterByState(state:stateInterface,user: User[]):void{
            state.usersFilterByState=user
        },
    },
    actions: {
        async getUsers({ commit, state }: { commit: Commit, state: stateInterface }): Promise<void> {
            commit("changeLoading", true);
            let users: User[];
            if (state.stablishmentIdSelected) {
                console.log('users by stablishment');
                users = await UserService.getByStablishment(state.stablishmentIdSelected);
            } else {
                console.log('all users');
                users = await UserService.getAll();
            }
            console.log(users);
            
            users = users.map(user => ({
                ...user,
                completeName: `${user.name} ${user.pather_lastname} ${user.mother_lastname}`
            }));
            commit("loadUsers", users);
            commit("changeLoading", false);
        },
        async getUser({ commit }: { commit: Commit }, id: number): Promise<void> {
            commit("changeLoading", true);
            const user = await UserService.getById(id);
            commit("setUser", user);
            commit("changeLoading", false);
        },
        async getUsersFilterByState({ commit }: { commit: Commit }, stateUser: string): Promise<void> {
            commit("changeLoading", true);
            const stablishment_id = JSON.parse(window.localStorage.getItem("user") || "{}").stablishment_id;
            const user = await UserService.getBystateAndStablishment(stateUser, stablishment_id);
            commit("setUsersFilterByState", user);
            commit("changeLoading", false);
        }
    }
}

export default moduleUser;