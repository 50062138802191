














































import InputSelectUsers from "@/components/document/ShowDocument/panelDetail/InputSelectUsers.vue";
import { UserGroupService } from "@/services/models/group/userGroup";
import myFormInterface from "@/types/myFormInterface";
import UserGroup from "@/types/user/userGroup";
import Vue from "vue";
import { mapActions } from "vuex";
import TableListUsers from "./TableListUsers.vue";
export default (Vue as myFormInterface).extend({
  components: { TableListUsers, InputSelectUsers },
  props: {
    itemProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      setUserGroup: "userGroup/getUserGroup",
    }),
    async save() {
      this.displayLoader(true);
      const users = this.$refs.selectFunctinary.usersSelects;
      for (let i = 0; i < users.length; i++) {
        let dataPost: UserGroup = {
          id:0,
          user_id: users[i].id,
          group_id: this.itemProp.id,
        };
        await UserGroupService.create(dataPost);
      }
      this.setUserGroup(this.itemProp.id);
      this.displayLoader(false);
    },
  },
});
