























































import { PurchaseBuyerService } from "@/services/models/purchaseRequest/purchaseBuyer";
import PurchaseBuyer from "@/types/purchaseRequest/purchaseBuyer";
import Vue from "vue";
export default Vue.extend({
  props: {
    purchaseBuyerId: {
      type: Number,
      required: true,      
    },
  },
  data() {
    return {
      data: {} as PurchaseBuyer,
      show:false
    };
  },
  methods: {
    async loadData() {
      this.displayLoader(true);
      this.show=false
      this.data = await PurchaseBuyerService.getById(this.purchaseBuyerId);
      this.show=true
      this.displayLoader(false);
    },
  },
  mounted() {
    this.loadData();
  },
});
