export const validateRut = (rut: number, dv: string): boolean => {
	/* eslint-disable */
	let fullRut = rut + "-" + dv;
	fullRut = fullRut.replace("‐", "-");
	console.log(fullRut);
	if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(fullRut)) return false;
	/* eslint-enable */
	// let tmp     = rutCompleto.split('-');
	let digv = dv;
	if (digv == "K") digv = "k";

	return validateDv(rut) == digv;
};

function validateDv(T: number): number | "k" {
	let M = 0,
		S = 1;
	for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
	return S ? S - 1 : "k";
}

export const isNumber = (evt: KeyboardEvent):void => {
	const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
	const keyPressed = evt.key;
	if (!keysAllowed.includes(keyPressed)) {
		evt.preventDefault();
	}
};

export const formatRut = (rut: string): string => {
	rut = cleanRut(rut)
	let result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1)
	for (let i = 4; i < rut.length; i += 3) {
		result = rut.slice(-3 - i, -i) + '.' + result
	}

	return result
}


export function cleanRut(rut: string): string {
	return typeof rut === 'string' ?
		rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase() :
		''
}

interface KeyboardEvent {
	key: string;
	preventDefault: any;
}