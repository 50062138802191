


















































import { DocumentDetailChangesService } from "@/services/models/document/documentDetailChanges";
import DocumentDetailChange from "@/types/documents/documentDetailChanges";
import Vue from "vue";
export default Vue.extend({
  props: {
    detailProp: {
      type: Object,
    },
    selectedPreviousTemplate: {
      type: Function,
    },
  },
  watch: {
    selectedItem() {
      this.selectedPreviousTemplate(this.selectedItem);
    },
  },
  data() {
    return {
      selectedItem: {},
      listChanges: [] as DocumentDetailChange[],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    compare(a: DocumentDetailChange, b: DocumentDetailChange) {
      if ((a.created_at || 0) < (b.created_at || 0)) {
        return 1;
      }
      if ((a.created_at || 0) > (b.created_at || 0)) {
        return -1;
      }
      return 0;
    },
    async loadData() {
      this.listChanges = await DocumentDetailChangesService.getByDocumentDetail(
        this.detailProp.id
      );
      this.listChanges = this.listChanges.sort(this.compare);
    },
  },
});
