



















import { DocumentStateService } from "@/services/models/document/documentState";
import { DocumentDetailReadService } from "@/services/models/document/documentDetailRead";
import DocumentState from "@/types/documents/documentState";
import Vue, { PropType } from "vue";
import DocumentDetailRead from "@/types/documents/documentDetailRead";
import { mapActions } from "vuex";
import { HistoryService } from "@/services/models/document/history";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
import DocumentDetail from "@/types/documents/documentDetail";
import Document from "@/types/documents/document";
import { DocumentStateLocationService } from "@/services/models/document/documentStateLocation";
import DocumentStateLocation from "@/types/documents/documentStateLocation";
export default Vue.extend({
  props: {
    detailProp: {
      required: true,
      type: Object as PropType<DocumentDetail>,
    },
    documentProp: {
      required: true,
      type: Object as PropType<Document>,
    },
  },
  methods: {
    ...mapActions({
      loadTable: "tabsDocument/getLoadTable",
      getDocumentsDetail: "documentDetail/getByDocumentId",
    }),
    async readUser() {
      try {
        let documentDetails = await DocumentDetailService.getByDocumentId(
          this.detailProp.document_id
        );
        let documentDetailsUnread = documentDetails.filter((documentDetail) => {
          if (documentDetail.documents_details_reads) {
            return !documentDetail.documents_details_reads.find(
              (value) => value.user_id == this.getCurrentUserLoggedIn().id
            );
          } else {
            return true;
          }
        });
        if (documentDetailsUnread.length === 1) {
          let documentState;
          if (this.documentProp.documents_states) {
            documentState = this.documentProp.documents_states.find(
              (documentState) =>
                documentState.user_id == this.getCurrentUserLoggedIn().id
            );
          }
          let stateToUpdate = {
            ...documentState,
            state_read: "leido",
          };
          if (stateToUpdate.id) {
            await DocumentStateService.update(
              stateToUpdate.id,
              stateToUpdate as DocumentState
            );
          }
        }
        if (this.detailProp.documents_details_reads) {
          let hasDocumentDetailRead =
            this.detailProp.documents_details_reads.find(
              (detail) => detail.user_id == this.getCurrentUserLoggedIn().id
            );
          if (!hasDocumentDetailRead) {
            await DocumentDetailReadService.create({
              user_id: this.getCurrentUserLoggedIn().id,
              document_detail_id: this.detailProp.id,
              location_id: this.getUserLocation().id,
            } as DocumentDetailRead);
          }
        }
      } catch (error) {
        throw new Error(`Ha ocurrido un error: ${error}`);
      }
    },
    async readOpartes() {
      try {
        let documentDetails = await DocumentDetailService.getByDocumentId(
          this.detailProp.document_id
        );
        let documentDetailsUnread = documentDetails.filter((documentDetail) => {
          if (documentDetail.documents_details_reads) {
            return !documentDetail.documents_details_reads.find(
              (value) => value.location_id == this.getUserLocation().id
            );
          } else {
            return true;
          }
        });
        console.log(documentDetailsUnread);   
        if (documentDetailsUnread.length === 1) {
          let documentStateLocation;
          console.log(this.documentProp);
          if (this.documentProp.documents_states_locations) {
            documentStateLocation =
              this.documentProp.documents_states_locations.find(
                (documentState) =>
                  documentState.location_id == this.getUserLocation().id
              );
          }
          let stateToUpdate = {
            ...documentStateLocation,
            state_read: "leido",
          };
          console.log(stateToUpdate);
          if (stateToUpdate.id) {
            await DocumentStateLocationService.update(
              stateToUpdate.id,
              stateToUpdate as DocumentStateLocation
            );
          }
        }
        if (this.detailProp.documents_details_reads) {
          let hasDocumentDetailRead =
            this.detailProp.documents_details_reads.find(
              (detail) => detail.location_id == this.getUserLocation().id
            );
          if (!hasDocumentDetailRead) {
            await DocumentDetailReadService.create({
              user_id: this.getCurrentUserLoggedIn().id,
              document_detail_id: this.detailProp.id,
              location_id: this.getUserLocation().id,
            } as DocumentDetailRead);
          }
        }
      } catch (error) {
        throw new Error(`Ha ocurrido un error: ${error}`);
      }
    },
    async saveRead() {
      try {
        let dataRefresh;
        if (this.getProfile() == "opartes") {
          await this.readOpartes();
          dataRefresh = {
            location_id: this.getUserLocation().id
          };
        } else {
          await this.readUser();
          dataRefresh = {
            user_id: this.getCurrentUserLoggedIn().id,
            folder_id: this.$route.params.id,
          };
        }
        this.loadTable(dataRefresh);
        this.getDocumentsDetail(this.detailProp.document_id);
      } catch (error) {
        console.log(error);
        this.displayToast({
          type: "error",
          message: "Lo sentimos, ha ocurrido un error al actualizar los datos",
        });
      }
    },
  },
});
