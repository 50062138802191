import { Content, Margins, StyleDictionary } from "pdfmake/interfaces";
import { PurchaseRequestService } from "@/services/models/purchaseRequest/purchaseRequest";
import { PurchaseHistoryService } from '@/services/models/purchaseRequest/purchaseHistory';

import PurchaseRequest from "@/types/purchaseRequest/purchaseRequest";

import logoRedondoSSM from "@/other/base64Logo";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
export const generatePDFPurchaseRequest = async (purchaseRequest: PurchaseRequest) => {
    const pdfMake = await import("pdfmake/build/pdfmake.js");
    if (pdfMake.vfs == undefined) {
        const pdfFonts = await import("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    const dataPurchase = await PurchaseRequestService.getById(purchaseRequest.id);
    const dataHistory = await PurchaseHistoryService.getByPurchaseId(purchaseRequest.id);

    //Se Carga la tabla con los articulos asociados a la solicitud de compra
    const tableArticle = []
    if (dataPurchase.purchases_requests_details) {
        for (let i = 0; i < dataPurchase.purchases_requests_details?.length; i++) {
            tableArticle.push([
                { text: `${dataPurchase.purchases_requests_details[i].article?.name}` },
                { text: `${dataPurchase.purchases_requests_details[i].cant}` },
                { text: `${numberWithCommas(dataPurchase.purchases_requests_details[i].price)}` },
                { text: `${dataPurchase.purchases_requests_details[i].observation}` }
            ])
        }
    }
    const tableHistory = []
    if (dataHistory) {
        for (let i = 0; i < dataHistory?.length; i++) {
            tableHistory.push([
                { text: `${dataHistory[i].created_at}` },
                { text: `${dataHistory[i].user_origin?.name} ${dataHistory[i].user_origin?.pather_lastname}` },
                { text: `${dataHistory[i].user_destination?.name} ${dataHistory[i].user_destination?.pather_lastname}` },
                { text: `${dataHistory[i].state}` },
            ])
        }
    }


    const docDefinition = {
        pageMargins: [40, 30, 40, 60] as Margins,
        footer(currentPage: number, pageCount: number):any {
            return {
                columns: [
                    [
                        {
                            text: `Solicitud de Compra N°${dataPurchase.id}, Fecha del PDF: ${moment().format('YYYY-MM-DD hh:mm')}`,
                            style:  {
                                fontSize: 12,
                                bold: true,
                                alignment: 'center'
                            },
                            margin: [0, 10, 40, 0]
                        },
                        {
                            text: `Página ${currentPage} de ${pageCount}`,
                            style:  {
                                fontSize: 12,
                                bold: true,
                                alignment: 'right'
                            },
                            margin: [0, 10, 40, 0]
                        },
                    ],
                ]
            }
        },
        content: [
            {
                columns: [
                    {
                        image: logoRedondoSSM,
                        width: 70,
                    },
                    [
                        {
                            text: `Solicitud de Compra n°${dataPurchase.id}`,
                            style: 'titleHeader',
                            margin: [0, 10, 40, 0]
                        },
                    ],
                ]
            },
            {
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['*', '*'],
                    body: [
                        //                         // FECHA DE SOLICITUD	2021-10-12
                        // FECHA DE GENERACIÓN DEL REPORTE	2021-11-25
                        // NOMBRE DEL REQUIRENTE	KAREN IVONNE AHERN MUÑOZ
                        // CORREO ELECTRONICO
                        [
                            { text: 'Datos del solicitante', style: 'title', colSpan: 2, alignment: 'center' }, {}
                        ],
                        [
                            { text: 'Solicitante', style: 'tableHeader' },
                            { text: `${dataPurchase.user?.name} ${dataPurchase.user?.pather_lastname} ${dataPurchase.user?.mother_lastname}` },
                        ],
                        [
                            { text: 'Correo', style: 'tableHeader' },
                            { text: `${dataPurchase.user?.email}` },
                        ],
                        [
                            { text: 'Telefono', style: 'tableHeader' },
                            { text: `${dataPurchase.user?.phone || 'Sin telefono asociado'}` },
                        ],
                        [
                            { text: 'Fecha de solicitud', style: 'tableHeader' },
                            { text: `${dataPurchase.created_at}` }
                        ],
                        [
                            { text: 'Fecha de generación del reporte', style: 'tableHeader' },
                            { text: `${moment().format('DD-MM-YYYY hh:mm')}` }
                        ],
                        [
                            { text: 'Descripción general', style: 'title', colSpan: 2, alignment: 'center' }, {}
                        ],
                        [
                            { text: 'Tipo de solicitud', style: 'tableHeader' },
                            { text: `${dataPurchase.purchase_request_type?.name}` }
                        ],
                        [
                            { text: 'Descripción', style: 'tableHeader' },
                            { text: `${dataPurchase.description}` },
                        ],
                        [
                            { text: 'Fecha estimada de uso', style: 'tableHeader' },
                            { text: `${dataPurchase.estimated_use_date}` },
                        ],
                        [
                            { text: 'Estado', style: 'tableHeader' },
                            { text: `${dataPurchase.state.toUpperCase()}` },
                        ],
                        [
                            { text: 'Ubicación', style: 'tableHeader' },
                            { text: `${dataPurchase.user_location?.name}` },
                        ],
                    ]
                },
            },
            {
                margin: [0, 10, 0, 0],
                table: {
                    headerRows: 2,
                    widths: [150, 50, 70, '*'],
                    body: [
                        [
                            { text: 'Artículos/Servicios', style: 'title', colSpan: 4, alignment: 'center' }, {}, {}, {}
                        ],
                        [
                            { text: 'Nombre', style: 'tableHeader' },
                            { text: 'Cantidad', style: 'tableHeader' },
                            { text: 'Precio Apróximado', style: 'tableHeader' },
                            { text: 'Observación', style: 'tableHeader' },
                        ],
                        ...tableArticle
                    ]
                },
                pageBreak: 'after'
            },
            {
                margin: [0, 10, 0, 0],
                table: {
                    headerRows: 2,
                    widths: [80, '*', '*', '*'],
                    body: [
                        [
                            { text: 'Historial', style: 'title', colSpan: 4, alignment: 'center' }, {}, {}, {}
                        ],
                        [
                            { text: 'Fecha', style: 'tableHeader' },
                            { text: 'Usuario Origen', style: 'tableHeader' },
                            { text: 'Usuario Destino', style: 'tableHeader' },
                            { text: 'Observación', style: 'tableHeader' },
                        ],
                        ...tableHistory
                    ]
                },
            }
        ] as Content,
        styles: {
            title: {
                bold: true,
                fontSize: 15,
                color: 'white',
                fillColor: '#EE3A3A'
            },
            tableHeader: {
                bold: true,
                fontSize: 11,
                color: 'black',
                fillColor: '#e4e4e4'
            },
            tableExample: {
                alignment: "justify",
                margin: [0, -150, 0, 15],
            },
            toTheRight: {
                alignment: "right",
            },
            header: {
                fontSize: 17,
                alignment: "left",
                bold: true,
            },
            titleHeader: {
                fontSize: 18,
                bold: true,
                alignment: 'center'
            },
            subtitleHeader: {
                fontSize: 9,
                alignment: 'center'
            }
        } as StyleDictionary,
    };
    pdfMake.createPdf(docDefinition).download("Reporte.pdf");
} 
