









































import { generatePDFTickets } from "@/reports/reportTickets";
import { GroupService } from "@/services/models/group/group";
import { TicketService } from "@/services/models/ticket/ticket";
import Ticket from "@/types/tickets/tickets";
import Group from "@/types/user/groups";
import moment from "moment";
import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      startDate: null,
      endDate: null,
      groups: [] as Group[],
      loadingGroups: false,
      groupId: null as unknown as number,
    };
  },
  mounted() {
    this.getUserGroups();
  },
  methods: {
    async getUserGroups() {
      this.loadingGroups = true;
      const groupsByStablishment = await GroupService.getByStablishmentId(
        this.getCurrentUserLoggedIn().stablishment_id
      );
      const userLoggedIn = this.getCurrentUserLoggedIn();
      if (userLoggedIn.users_groups?.length) {
        this.groups = groupsByStablishment.filter((group) =>
          userLoggedIn.users_groups
            ?.map((user_group) => user_group.group_id)
            .includes(group.id)
        );
        if (this.groups.length) {
          this.groupId = this.groups[0].id;
        }
      }
      this.loadingGroups = false;
    },
    async downloadReport() {
      if (!this.startDate || !this.endDate || !this.groupId) {
        this.displayToast({
          type: "warning",
          message: "Por favor seleccione grupo y rango de fecha",
        });
        return;
      }
      this.displayLoader(true);
      try {
        let minDate = moment(this.startDate).format("YYYY-MM-DD") + " 00:00:00";
        let maxDate = moment(this.endDate).format("YYYY-MM-DD") + " 23:59:59";
        let excelResponse = await TicketService.getExcelReport(
          minDate,
          maxDate,
          this.groupId
        );
        console.log(excelResponse);
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(excelResponse.data);
        a.href = url;
        a.download = `REPORTE_${moment(this.startDate).format(
          "DD-MM-YYYY"
        )}_${moment(this.endDate).format("DD-MM-YYYY")}.xlsx`;
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        this.displayToast({
          type: "error",
          message: "Los sentimos, ha ocurrido un error",
        });
      }
      this.displayLoader(false);
    },
    async downloadPdfReport() {
      if (!this.startDate || !this.endDate || !this.groupId) {
        this.displayToast({
          type: "warning",
          message: "Por favor seleccione grupo y rango de fecha",
        });
        return;
      }
      let start = moment(this.startDate).format("YYYY-MM-DD");
      let end = moment(this.endDate).format("YYYY-MM-DD");
      this.displayLoader(true);
      let tickets: Ticket[];
      tickets = await TicketService.getByDateAndGroup(
        `${start} 00:00:00`,
        `${end} 23:59:59`,
        this.groupId
      );
      generatePDFTickets({
        tickets: tickets,
        dateStart: start,
        dateEnd: end,
        user: this.getCurrentUserLoggedIn(),
        groupId: this.groupId,
      });
      this.displayLoader(false);
    },
  },
});
