












































import { DocumentDetailCheckedService } from "@/services/models/document/documentDetailChecked";
import { DocumentStateService } from "@/services/models/document/documentState";
import { HistoryService } from "@/services/models/document/history";
import DocumentDetailChecked, {
  stateDocumentDetailChecked,
} from "@/types/documents/documentDetailChecked";
import DocumentState, {
  statesDocuments,
} from "@/types/documents/documentState";
import History, { statesDocumentHistory } from "@/types/documents/history";

import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  props: {
    detailProp: {
      type: Object,
    },
    documentProp: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      getHistories: "history/getDocumentDetailId",
      getByCheckedDocument: "documents/getByCheckedDocument",
      getCheckedDocument: "documents/getCheckedDocument",
      getNotRelevantDocument: "documents/getNotRelevantDocument",
      getDocumentsDetail: "documentDetail/getByDocumentId",
    }),
    async saveCheck(
      statesDocuments: statesDocuments &
        stateDocumentDetailChecked &
        statesDocumentHistory
    ) {
      this.isLoading = true;
      try {
        await this.postHistory(statesDocuments);
        await this.postState(statesDocuments);
        await this.putChecked(statesDocuments);
        this.getHistories(this.detailProp.id);
        this.getByCheckedDocument(this.getCurrentUserLoggedIn().id);
        this.getCheckedDocument(this.getCurrentUserLoggedIn().id);
        this.getNotRelevantDocument(this.getCurrentUserLoggedIn().id);
        this.displayToast({
          type: "success",
          message: "Se ha visado el documento con éxito",
        });
        this.getDocumentsDetail(this.detailProp.document_id);
        this.dialog = false;
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async postHistory(state: statesDocumentHistory): Promise<void> {
      let data: History;
      try {
        data = {
          document_detail_id: this.detailProp.id,
          user_origin_id: this.getCurrentUserLoggedIn().id,
          user_destination_id: this.getCurrentUserLoggedIn().id || 0,
          location_origin_id: this.getUserLocation().location_id,
          location_destination_id: 1,
          state: state,
        };
        await HistoryService.create(data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async postState(state: statesDocuments) {
      try {
        //Falta validar si el usuario ya tiene estado, en cuyo caso, se habria que modificar
        let dataStates: DocumentState = this.documentProp.documents_states[0];
        let data: DocumentState = {
          ...dataStates,
          state: state,
        };
        await DocumentStateService.update(data.id || 0, data);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async putChecked(state: stateDocumentDetailChecked) {
      try {
        let listCheckeds =
          await DocumentDetailCheckedService.getByDocumentDetailId(
            this.detailProp.id
          );
        listCheckeds = listCheckeds.filter(
          (data: DocumentDetailChecked) =>
            data.user_id == this.getCurrentUserLoggedIn().id
        );
        let data: DocumentDetailChecked = {
          ...listCheckeds[0],
          state: state,
        };
        await DocumentDetailCheckedService.update(
          listCheckeds[0].id || 0,
          data
        );
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
});
