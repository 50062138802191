
















































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import DetailTableRequestTicket from "./DetailTableRequestTicket.vue";
import ButtonTake from "./ButtonTake.vue";
import ButtonTransfer from "./ButtonTransfer.vue";
import ButtonFinish from "./ButtonFinish.vue";
import ButtonHistory from "./ButtonHistory.vue";
import ModalChatTicket from "./chat/ModalChatTicket.vue";
import Ticket, { statesTickets } from "@/types/tickets/tickets";
import ButtonShowClosedTicket from "./closedTicket/ButtonShowClosedTicket.vue";
import CreateTicket from "./createTicket/CreateTicket.vue";
import ButtonClosedTicket from "./closedTicket/ButtonClosedTicket.vue";
import ModalFilterTickets from "./filter/ModalFilterTickets.vue";
import store from "@/store";
import GenerateSingleReport from "./GenerateSingleReport.vue";
import { CREATE_TICKET, TAKE_TICKET } from "@/others/constants/permissions";
import { statesDocuments } from "@/types/documents/documentState";

export default Vue.extend({
  name: "TableRequestTickets",
  components: {
    DetailTableRequestTicket,
    ButtonTake,
    ButtonTransfer,
    ButtonFinish,
    ButtonHistory,
    ModalChatTicket,
    ButtonShowClosedTicket,
    CreateTicket,
    ButtonClosedTicket,
    ModalFilterTickets,
    GenerateSingleReport,
  },
  data() {
    return {
      createTicketPermission: CREATE_TICKET,
      takeTicketPermission: TAKE_TICKET,
      page: 1,
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false,
          width: 50,
        },
        { text: "Titulo", sortable: false, align: "start", value: "title" },
        {
          text: "Tipo de ticket",
          sortable: false,
          align: "start",
          value: "ticket_type.name",
        },
        {
          text: "Solicitante",
          sortable: false,
          align: "start",
          value: "user",
        },
        {
          text: "Fecha",
          sortable: false,
          align: "start",
          value: "created_at",
        },
        {
          text: "Estado",
          sortable: false,
          align: "start",
          value: "state",
        },
        {
          text: "Acciones",
          sortable: false,
          align: "start",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getTickets: "ticket/getTicketFilter",
      getLoading: "ticket/getLoading",
      permissions: "userSession/getPermissions",
    }),
  },
  mounted() {
    this.changePage();
  },
  methods: {
    ...mapActions({
      setTickets: "ticket/getTicketFilter",
    }),
    getState(state: statesTickets) {
      let stateToShow = "";
      if (state == "solicitado") {
        stateToShow = "solicitado";
      } else if (state == "en proceso") {
        stateToShow = "en proceso"
      } else if (state == "cerrado") {
        stateToShow = "validado";
      } else if (state == "cerrado permanentemente") {
        stateToShow = "validado permanentemente"
      }
      return stateToShow.toUpperCase();
    },
    itemColorBackground(item: Ticket) {
      let lastResponsable = item.tickets_responsables?.length
        ? item.tickets_responsables?.[item.tickets_responsables.length - 1]
            .user_id
        : 0;
      if (lastResponsable == this.getCurrentUserLoggedIn().id)
        return "yellow lighten-4";
    },
    changePage(page?: number): void {
      if (page) {
        this.page = page;
      }
      this.$store.commit("ticket/setPageTicketFilter", this.page);
      this.setTickets();
    },
    refreshTable() {
      this.page = 1;
      store.commit("ticket/setDataFilterTicket", {});
      this.changePage();
    },
    getColor(state: string) {
      if (state == "cerrado") return "success";
      else if (state == "en proceso") return "warning";
      else if (state == "cerrado permanentemente") return "error";
      else return "primary";
    },
  },
});
