








import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
  methods: {
    ...mapActions({
      refresh: "tabsDocument/getLoadTable",
    }),
    refreshTable() {
      let dataRefresh = {
        user_id: this.getCurrentUserLoggedIn().id,
        folder_id: this.$route.params.id,
        ...(this.getProfile() === "opartes" && {
          location_id: this.getUserLocation().location_id,
        }),
      };
      this.refresh(dataRefresh);
    },
  },
});
