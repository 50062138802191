import Vue from 'vue'
import Vuex from 'vuex'
import moduleUser from './user/moduleUsers'
import moduleRole from './user/moduleRole'
import moduleStablishment from './user/moduleStablishment'
import moduleToast from './moduleToast'
import moduleRelogin from './moduleRelogin'
import moduleDocument from './document/moduleDocument'
import moduleDocumentDetail from './document/moduleDocumentDetail'
import moduleHistory from './document/moduleHistory'
import moduleDocumentDetailFile from './document/moduleDetailFile'
import moduleTabsDocuments from './document/moduleTabsDocument'
import moduleLoader from './moduleLoader'
import moduleFolder from './document/moduleFolder'
import moduleDispatch from './dispatch/moduleDispatch'
import moduleUserSession from './moduleUserSession'
import moduleConfirm from './moduleConfirm'
import moduleDispatchLocation from './dispatch/moduleDispatchLocation'
import moduleTickets from './ticket/moduleTicket'
import moduleGroup from './group/moduleGroup'
import moduleUserGroup from './group/moduleUserGroup'
import modulePurchaseRequest from './purchaseRequest/modulePurchaseRequest'
import moduleTicketMessage from './ticket/moduleTicketMessage'
import moduleTicketType from './ticket/ticketType'
import moduleBuyer from './purchaseRequest/moduleBuyer'
import modulePurchaseBuyer from './purchaseRequest/modulePurchaseBuyer'
import moduleSupplyManager from './purchaseRequest/moduleSupplyManager'
import moduleUnityManager from './purchaseRequest/moduleUnityManager'
import moduleAuthorizer from './purchaseRequest/moduleAuthorizer'
import moduleCdp from './purchaseRequest/moduleCdp'

Vue.use(Vuex)

// export type RootState = {}

export default new Vuex.Store({
	modules: {
		users: moduleUser,
		roles:moduleRole,
		stablishments:moduleStablishment,
		documents:moduleDocument,
		documentDetail:moduleDocumentDetail,
		documentDetailFile:moduleDocumentDetailFile,
		history:moduleHistory,
		tabsDocument:moduleTabsDocuments,
		toast:moduleToast,
		loader:moduleLoader,
		relogin:moduleRelogin,
		folders:moduleFolder,
		userSession: moduleUserSession,
		dispatch:moduleDispatch,
		dispatchLocation:moduleDispatchLocation,
		confirm:moduleConfirm,	
		
		//Store asociado a tickets
		ticket:moduleTickets,
		ticketMessage:moduleTicketMessage,
		ticketType:moduleTicketType,
		group:moduleGroup,
		userGroup:moduleUserGroup,

		//Store de purchaseRequest(Solicitudes de compras)
		purchaseRequest:modulePurchaseRequest,
		
		authorizer:moduleAuthorizer,
		supplyManager:moduleSupplyManager,	
		unityManager:moduleUnityManager,	

		buyer:moduleBuyer,
		purchaseBuyer:modulePurchaseBuyer,
		cdp:moduleCdp,
	}
})

